import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ReturnIcon from '../components/icons/IconReturn';
import useDocumentTitle from '../hooks/useDocumentTitle';
import notFound from '../assets/images/not-found-bc.png';

const NotFoundPage = () => {
    useDocumentTitle('Page Not Found');
    return (
        <div className="container">
            <div className="info-page__wrapper">
                <div className="info-page__image">
                    <img
                        className="not-found"
                        src={notFound}
                        alt="woman Illustration"
                    />
                </div>
                <h1 className="page-info__title">Page not found</h1>
                <p className="page-info__subtitle page-info__subtitle--span">
                    The page are looking for could not be found
                    <span>:(</span>
                </p>
                <Link to={'/'}>
                    <Button className="btn blue icon-left">
                        <span className="btn__icon">
                            <ReturnIcon />
                        </span>
                        Back to Dashboard
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
