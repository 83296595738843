import { Button } from 'antd';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { AuthInterface } from '../../../store/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactDetailsValidationSchema } from '../../../utils/validationSchemas';
import ArrowRightLong from '../../icons/IconArrowRightlong';
import PartInputField from '../../parts/PartInputField';
import { NewLoanSteps } from '../../../utils/enums';
import LoanApplicationEntity from '../../../domain/newLoanApplication/LoanApplicationEntity';

interface ContactDetailsInfoProps {
    // eslint-disable-next-line no-unused-vars
    nextStep: (step: NewLoanSteps) => void;
    newLoanEntity: LoanApplicationEntity;
    authState: AuthInterface;
}

const ContactDetailsInfo = ({
    authState,
    nextStep,
    newLoanEntity
}: ContactDetailsInfoProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(contactDetailsValidationSchema),
        shouldFocusError: false
    });

    useEffect(() => {
        reset({
            firstName: authState?.firstName ?? '',
            lastName: authState?.lastName ?? '',
            email: authState?.email ?? '',
            phone: authState?.phone ?? ''
        });
    }, [authState]);

    const onSubmit = (data: any) => {
        const phone = data.phone.replace(/[-+()\s]/g, '');
        const newData = {
            ...data,
            phone
        };
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(newData)
        );

        nextStep(NewLoanSteps.CONTACT_DETAILS_PROPERTY);
    };

    useEffect(() => {
        newLoanEntity.hasFirstName() &&
            setValue('firstName', newLoanEntity.firstName.value);
        newLoanEntity.hasLastName() &&
            setValue('lastName', newLoanEntity.lastName.value);
        newLoanEntity.hasEmail() &&
            setValue('email', newLoanEntity.email.value);
        newLoanEntity.hasPhone() &&
            setValue('phone', newLoanEntity.phone.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    Tell us a little about yourself.
                </h1>
                <p className="login__subtitle">Borrower Details</p>
                <div className="login__box">
                    <PartInputField
                        label="First Name"
                        name="firstName"
                        control={control}
                        errors={errors}
                        isRequired
                    />
                    <PartInputField
                        label="Last Name"
                        name="lastName"
                        control={control}
                        errors={errors}
                        isRequired
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Email"
                        name="email"
                        isRequired
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Phone Number"
                        name="phone"
                        isRequired
                        isPhoneNumberFormat={true}
                    />
                    <Button
                        className="btn blue login__btn"
                        type="primary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Next
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContactDetailsInfo;
