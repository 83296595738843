import Exception from '../../utils/exceptions/Exception';

export default class Collection {
    _data: any;
    /**
     * @param {Object|Array|undefined} data
     */
    constructor(data: any) {
        this._data = data;
    }

    /**
     * ASSOCIATIVE USE ONLY
     *
     * @param {string} parameterName
     * @returns {boolean}
     */
    has(parameterName: any) {
        if (!this._isObject() && this._data !== undefined) {
            throw new Exception(
                `Using Collection in wrong context. Location: Collection.has(${parameterName})`,
                400
            );
        }
        if (this._isObject()) {
            return parameterName in this._data;
        } else {
            return false;
        }
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param value
     * @returns {boolean}
     */
    contains(value: Number | string) {
        if (this._data === undefined) return false;
        else if (this._isArray()) return this._data.indexOf(value) > -1;
        else if (this._isObject()) {
            let found = false;

            this.forEach((item: any) => {
                if (item === value) found = true;
            });

            return found;
        }
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {String|Number} parameterNameOrIndex
     * @return {*}
     */
    get(parameterNameOrIndex: any) {
        if (this._isObject() || this._isArray()) {
            return this._data[parameterNameOrIndex];
        }
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {null|*}
     */
    first() {
        if (this.isEmpty()) return null;
        else if (this._isObject())
            return this._data[Object.keys(this._data)[0]];
        else if (this._isArray() && this._data.length > 0) return this._data[0];
        return null;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {string} key
     * @return {Collection}
     */
    slice(key: Number | string) {
        let newData = undefined;
        if (
            (this._isObject() || this._isArray()) &&
            typeof this.get(key) === 'object'
        )
            newData = this.get(key);

        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {Number | string} key
     * @return {Collection}
     */
    extract(key: Number | string) {
        let newData = undefined;
        if (
            (this._isObject() || this._isArray()) &&
            typeof this.get(key) === 'object'
        )
            newData = this.get(key);

        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {boolean}
     */
    isEmpty() {
        return this.count() === 0;
    }

    /**
     * ASSOCIATIVE USE ONLY
     *
     * @param {String|*} parameterNameOrValue
     * @returns {Collection}
     */
    mandatory(parameterNameOrValue: any) {
        if (this._isObject() && !this.has(parameterNameOrValue))
            throw new Exception(
                `${parameterNameOrValue} is missing from ${JSON.stringify(
                    this._data
                )}`,
                400
            );
        else if (this._isArray() && !this.contains(parameterNameOrValue))
            throw new Exception(
                `${parameterNameOrValue} is missing from ${JSON.stringify(
                    this._data
                )}`,
                400
            );
        else if (this._data === undefined)
            throw new Exception(
                `${parameterNameOrValue} is missing from Collection`,
                400
            );
        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param collection
     * @returns {Collection}
     */
    merge(collection: Collection) {
        if (collection.isEmpty()) return this;

        if (this.isEmpty()) {
            this._data = collection.items;
        }

        if (this._isObject() && collection._isObject())
            Object.assign(this._data, this._data, collection.items);
        else if (this._isArray() && collection._isArray())
            this._data = this._data.concat(
                collection.items.filter(
                    (item: any) => this._data.indexOf(item) < 0
                )
            );
        else
            throw new Exception(
                'Trying to merge Collections with different contexts.',
                400
            );

        return this;
    }

    /**
     * ASSOCIATIVE USE ONLY
     *
     * @param {String|Number} key
     * @param {*} value
     * @returns {Collection}
     */
    set(key: any, value: any) {
        if (!this._isObject() && this._data !== undefined)
            throw new Exception(
                `Using Collection in wrong context. Location: Collection.set()`,
                400
            );

        if (this._data === undefined) this._data = {};

        this._data[key] = value;

        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {*} key
     * @returns {Collection}
     */
    remove(key: any) {
        if (this._isArray() && typeof this._data[key] !== 'undefined') {
            this._data.splice(key, 1);
        } else if (this._isObject() && key in this._data) {
            delete this._data[key];
        }
        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {Function} callback
     * @returns {Collection}
     */
    forEach(callback: Function) {
        if (this._isObject()) {
            for (const key in this._data) {
                callback(this._data[key], key);
            }
        } else if (this._isArray()) {
            this._data.forEach((item: any, index: any) => {
                callback(item, index);
            });
        }
        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param {Function} callback
     * @returns {Collection}
     */
    async asyncForEach(callback: Function) {
        if (this._isObject()) {
            for (const key in this._data) {
                await callback(this._data[key], key);
            }
        } else if (this._isArray()) {
            const length = this._data.length;
            for (let i = 0; i < length; i++) await callback(this._data[i], i);
        }
        return this;
    }

    /**
     * NUMERIC USE ONLY
     *
     * @param value
     * @returns {Collection}
     */
    insert(value: any) {
        if (this._isArray()) this._data.push(value);
        else if (this._data === undefined) this._data = [value];
        else if (this._isObject())
            throw new Exception(
                `Using Collection in wrong context. Location: Collection.insert()`,
                400
            );
        return this;
    }

    /**
     * NUMERIC USE ONLY
     *
     * @param value
     * @returns {Collection}
     */
    unshift(value: any) {
        if (this._isArray()) this._data.unshift(value);
        else if (this._data === undefined) this._data = [value];
        else if (this._isObject())
            throw new Exception(
                `Using Collection in wrong context. Location: Collection.unshift()`,
                400
            );
        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param callback
     * @returns {Collection}
     */
    filter(callback: Function) {
        let newData;
        if (this._isObject()) newData = {};
        else if (this._isArray()) newData = [];
        else if (this._data === undefined) return this;

        this.forEach((item: any, key: any) => {
            if (callback(item, key)) {
                if (this._isObject()) newData[key] = item;
                else if (this._isArray()) newData.push(item);
            }
        });

        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param callback
     * @returns {Collection}
     */
    map(callback: Function) {
        let newData;
        if (this._isObject()) newData = {};
        else if (this._isArray()) newData = [];
        else if (this._data === undefined) return this;

        this.forEach((item: any, key: any) => {
            if (this._isObject()) newData[key] = callback(item, key);
            else if (this._isArray()) newData.push(callback(item, key));
        });

        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param callback
     * @returns {Collection}
     */
    async asyncMap(callback: Function) {
        let newData;
        if (this._isObject()) newData = {};
        else if (this._isArray()) newData = [];
        else if (this._data === undefined) return this;

        await this.asyncForEach(async (item: any, key: any) => {
            if (this._isObject()) newData[key] = await callback(item, key);
            else if (this._isArray()) newData.push(await callback(item, key));
        });

        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param callback
     * @returns {undefined|any}
     */
    find(callback: Function): undefined | any {
        if (this._data === undefined) return undefined;

        let requestedItem;

        this.forEach((item: any, key: any) => {
            if (callback(item, key)) requestedItem = item;
        });

        return requestedItem;
    }

    /*
     * ASSOCIATIVE || NUMERIC USE
     *
     * @param callback
     * @returns {undefined|*}
     */
    findKey(callback: Function) {
        if (this._data === undefined) return undefined;

        let requestedKey;

        this.forEach((item: any, key: any) => {
            if (callback(item, key)) requestedKey = key;
        });

        return requestedKey;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {Collection}
     */
    getValues() {
        const newData: Array<any> = [];
        this.forEach((value: any) => {
            newData.push(value);
        });
        return new Collection(newData);
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {Collection}
     */
    getKeys() {
        const newData: Array<any> = [];
        this.forEach((value: any, key: any) => {
            newData.push(key);
        });
        return new Collection(newData);
    }

    /**
     * @returns {Collection}
     */
    clear() {
        if (this._isObject()) this._data = {};
        else if (this._isArray()) this._data = [];
        else this._data = undefined;
        return this;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {number}
     */
    count() {
        if (this._isObject()) {
            let count = 0;
            this.forEach(() => {
                count++;
            });
            return count;
        } else if (this._isArray()) return this._data.length;
        else return 0;
    }

    get items() {
        return this._data;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns Array
     */
    toArray() {
        const items: Array<any> = [];
        this.forEach((item: any) => {
            items.push(item);
        });
        return items;
    }

    /**
     * ASSOCIATIVE || NUMERIC USE
     *
     * @returns {boolean}
     */
    isAssociative() {
        return this._isObject();
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isObject() {
        return typeof this._data === 'object' && !this._isArray();
    }

    _isArray() {
        return Array.isArray(this._data);
    }
}
