import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermRateType extends BaseValueObject {
    _value: any;
    _name: string;

    static ARM_5_6 = 1;
    static ARM_7_6 = 2;
    static ARM_10_6 = 3;
    static FRM_30_YR = 4;

    static ARM_5_6_NAME = '5/6 ARM';
    static ARM_7_6_NAME = '7/6 ARM';
    static ARM_10_6_NAME = '10/6 ARM';
    static FRM_30_YR_NAME = '30 Yr FRM';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableRateType() {
        return [
            LoanTermRateType.ARM_5_6,
            LoanTermRateType.ARM_7_6,
            LoanTermRateType.ARM_10_6,
            LoanTermRateType.FRM_30_YR
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermRateType.ARM_5_6:
                this._name = LoanTermRateType.ARM_5_6_NAME;
                break;
            case LoanTermRateType.ARM_7_6:
                this._name = LoanTermRateType.ARM_7_6_NAME;
                break;
            case LoanTermRateType.ARM_10_6:
                this._name = LoanTermRateType.ARM_10_6_NAME;
                break;
            case LoanTermRateType.FRM_30_YR:
                this._name = LoanTermRateType.FRM_30_YR_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableRateType().indexOf(this._value) < 0) {
            throw new Exception('Invalid Rate Type', 400);
        }
    }
}
