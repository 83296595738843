import { Input, Space } from 'antd';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import Exclamation from '../icons/IconExclamation';

import EyeIcon from '../icons/IconEye';

interface InputFieldProps {
    name: string;
    label: string;
    control: any;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    capitalizeFirstLetter?: boolean;
    validationMessage?: string;
    defaultValue?: string;
    validateRegexOnChange?: boolean;
    passwordsMatch?: boolean;
    onChangeProps?: any;
    onPressEnter?: any;
    hideErrorMessage?: boolean;
}

const PartInputPassword = ({
    name,
    label,
    control,
    errors,
    isRequired,
    defaultValue = '',
    validateRegexOnChange,
    onChangeProps,
    passwordsMatch,
    onPressEnter,
    hideErrorMessage
}: InputFieldProps) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <div
            className={`form-element form-element--password ${
                name in errors ? 'form-element--error' : ''
            }`}
        >
            <label>
                {label}
                {isRequired && <span>{' *'}</span>}
            </label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) => {
                    const validPassword = false;
                    // if (validateRegexOnChange)
                    // validPassword = PASSWORD_REGULAR_EXPRESSION.test(field.value);
                    return (
                        <Input
                            {...field}
                            onChange={(e: any) => {
                                field.onChange(e);
                                onChangeProps && onChangeProps(e);
                            }}
                            onPressEnter={onPressEnter}
                            type={passwordVisible ? 'text' : 'password'}
                            suffix={
                                <>
                                    {validateRegexOnChange ? (
                                        validPassword ? (
                                            <span className="form-element__icon form-element__icon--valid">
                                                check
                                            </span>
                                        ) : field.value.length ? (
                                            <span className="form-element__icon form-element__icon--invalid">
                                                close
                                            </span>
                                        ) : null
                                    ) : null}
                                    {field.value.length &&
                                    passwordsMatch !== undefined ? (
                                        passwordsMatch ? (
                                            <span className="form-element__icon form-element__icon--valid">
                                                check
                                            </span>
                                        ) : field.value.length ? (
                                            <span className="form-element__icon form-element__icon--invalid">
                                                close
                                            </span>
                                        ) : null
                                    ) : null}
                                    {passwordVisible ? (
                                        <span
                                            className="password-icon"
                                            onClick={() =>
                                                setPasswordVisible(false)
                                            }
                                        >
                                            <EyeIcon />
                                        </span>
                                    ) : (
                                        <span
                                            className="password-icon"
                                            onClick={() =>
                                                setPasswordVisible(true)
                                            }
                                        >
                                            <EyeIcon />
                                        </span>
                                    )}
                                </>
                            }
                        />
                    );
                }}
            />
            {!hideErrorMessage && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <p className="form-element__error-msg">
                            <span className="form-element__error-msg-icon">
                                {message && <Exclamation />}
                            </span>
                            <span>{message}</span>
                        </p>
                    )}
                />
            )}
            <Space />
        </div>
    );
};

export default PartInputPassword;
