import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import LoanFactory from './LoanFactory';
import LoanGateway from './LoanGateway';

export default class LoanRepository {
    _gateway: LoanGateway;
    _factory: LoanFactory;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getLoan(id: TypePositiveInteger) {
        const data = await this._gateway.get(id);
        return this._factory.reconstitute(new Collection(data));
    }
}
