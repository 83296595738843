import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermInterestAccrualMethod extends BaseValueObject {
    _value: any;
    _name: string;
    public static FULL_BOAT = 1;
    public static AS_DISBURSED = 2;

    public static FULL_BOAT_NAME = 'Full Boat';
    public static AS_DISBURSED_NAME = 'As Disbursed';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableInterestAccrualMethod() {
        return [
            LoanTermInterestAccrualMethod.FULL_BOAT,
            LoanTermInterestAccrualMethod.AS_DISBURSED
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermInterestAccrualMethod.FULL_BOAT:
                this._name = LoanTermInterestAccrualMethod.FULL_BOAT_NAME;
                break;
            case LoanTermInterestAccrualMethod.AS_DISBURSED:
                this._name = LoanTermInterestAccrualMethod.AS_DISBURSED_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableInterestAccrualMethod().indexOf(this._value) < 0) {
            throw new Exception('Invalid Interest Accrual Method', 400);
        }
    }
}
