import { Select } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import ArrowDown from '../icons/IconArrowDown';
import NotificationController from '../../domain/notification/NotificationController';
import InvalidToastMessage from '../../domain/notification/notificationTemplate/InvalidToastMessage';
import {
    DEAL_STATUS_LOST,
    DEAL_STATUS_COMMITTEE,
    DEAL_STATUS_DUE_DILIGENCE,
    DEAL_STATUS_FUNDED,
    DEAL_STATUS_LEGAL,
    DEAL_STATUS_NAME_LOST,
    DEAL_STATUS_NAME_COMMITTEE,
    DEAL_STATUS_NAME_DUE_DILIGENCE,
    DEAL_STATUS_NAME_FUNDED,
    DEAL_STATUS_NAME_LEGAL,
    DEAL_STATUS_NAME_ON_HOLD,
    DEAL_STATUS_NAME_PENDING,
    DEAL_STATUS_NAME_UNDERWRITING,
    DEAL_STATUS_ON_HOLD,
    DEAL_STATUS_PENDING,
    DEAL_STATUS_UNDERWRITING,
    phase1LoanStatusValues,
    phase2LoanStatusValues,
    phase3LoanStatusValues,
    phase4LoanStatusValues,
    DEAL_STATUS_QUOTES_ORIGINATION,
    DEAL_STATUS_NAME_QUOTES_ORIGINATION
} from '../../domain/loan/valueObject/LoanStatusType';
import LoanService from '../../domain/loan/LoanService';
import TypePositiveInteger from '../../domain/valueObject/TypePositiveInteger';
import LoanScrollRAMGateway from '../../domain/loanScroll/LoanScrollRAMGateway';

interface Props {
    loanStatus: number;
    loanId: number;
}

const PartSelect = ({ loanStatus, loanId }: Props) => {
    const [value, setValue] = useState(loanStatus);

    const handleStatusChange = (selectedValue: number) => {
        setValue(selectedValue);
        updateStatus(selectedValue);
    };

    const updateStatus = async (status: number) => {
        const valueBeforeChange = value;
        try {
            await LoanService.updateStatus(
                new TypePositiveInteger(loanId),
                new TypePositiveInteger(status)
            );
            new LoanScrollRAMGateway().clearPersistedData();
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage('Status update failed')
            );
            setValue(valueBeforeChange);
        }
    };

    return (
        <div
            className={classNames('select-element', {
                'loan-status--phase-1': phase1LoanStatusValues.includes(value),
                'loan-status--phase-2': phase2LoanStatusValues.includes(value),
                'loan-status--phase-3': phase3LoanStatusValues.includes(value),
                'loan-status--phase-4': phase4LoanStatusValues.includes(value)
            })}
        >
            <Select
                data-testid="select"
                popupClassName={classNames('select-element-dropdown', {
                    'loan-status--phase-1':
                        phase1LoanStatusValues.includes(value),
                    'loan-status--phase-2':
                        phase2LoanStatusValues.includes(value),
                    'loan-status--phase-3':
                        phase3LoanStatusValues.includes(value),
                    'loan-status--phase-4':
                        phase4LoanStatusValues.includes(value)
                })}
                value={value}
                onChange={(value) => {
                    handleStatusChange(value);
                }}
                showArrow={true}
                suffixIcon={
                    <div className="select-arrow__wrapper">
                        <div className="select-arrow__wrapper-box">
                            <ArrowDown />
                        </div>
                    </div>
                }
                dropdownRender={(menu) => (
                    <div data-testid="dropdown">{menu}</div>
                )}
                options={[
                    {
                        value: DEAL_STATUS_PENDING,
                        label: DEAL_STATUS_NAME_PENDING
                    },
                    {
                        value: DEAL_STATUS_QUOTES_ORIGINATION,
                        label: DEAL_STATUS_NAME_QUOTES_ORIGINATION
                    },
                    {
                        value: DEAL_STATUS_DUE_DILIGENCE,
                        label: DEAL_STATUS_NAME_DUE_DILIGENCE
                    },
                    {
                        value: DEAL_STATUS_UNDERWRITING,
                        label: DEAL_STATUS_NAME_UNDERWRITING
                    },
                    {
                        value: DEAL_STATUS_COMMITTEE,
                        label: DEAL_STATUS_NAME_COMMITTEE
                    },
                    { value: DEAL_STATUS_LEGAL, label: DEAL_STATUS_NAME_LEGAL },
                    {
                        value: DEAL_STATUS_FUNDED,
                        label: DEAL_STATUS_NAME_FUNDED
                    },
                    {
                        value: DEAL_STATUS_ON_HOLD,
                        label: DEAL_STATUS_NAME_ON_HOLD
                    },
                    {
                        value: DEAL_STATUS_LOST,
                        label: DEAL_STATUS_NAME_LOST
                    }
                ]}
            />
        </div>
    );
};

export default PartSelect;
