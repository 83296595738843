import React from 'react';
import LoadingGif from '../../assets/images/LoadingGIF.gif';

const PartLoadingSpinner: React.FC = () => {
    return (
        <div className="loader">
            <img width={48} src={LoadingGif} alt="Loading" />
        </div>
    );
};

export default PartLoadingSpinner;
