// import { USER_ROLE_USER } from '../../domain/credentials/valueObject/UserRoleType';

interface PartNewLoanButtonProps {
    authState: any;
}

const PartNewLoanButton = ({ authState }: PartNewLoanButtonProps) => {
    // const createHref = (userType: number) => {
    //     return userType === USER_ROLE_USER
    //         ? `https://castlingcapital.com/apply?firstname=${authState.firstName}&lastname=${authState.lastName}&email=${authState.email}&phone=${authState.phone}`
    //         : `https://castlingcapital.com/apply`;
    // };

    return (
        <a
            className="my-loans__select-button-wrapper"
            href="/new-loan-application"
            // href={createHref(authState.userType)}
            // target={'_blank'}
            rel="noreferrer"
        >
            <div className="btn btn--small blue">
                New Loan
                <span className="btn__icon plus"></span>
            </div>
        </a>
    );
};

export default PartNewLoanButton;
