import BaseModel from '../../BaseModel';
import Collection from '../../valueObject/Collection';

export default class LoanScrollFilterRulesEntity extends BaseModel {
    private _loanStatus: Collection | undefined;

    public get loanStatus(): Collection | undefined {
        return this._loanStatus;
    }

    public setLoanStatus(value: Collection | undefined) {
        this._loanStatus = value;
        this._executeAfterChangeTriggers();
        return this;
    }

    public clearLoanStatus() {
        this._loanStatus = undefined;
        this._executeAfterChangeTriggers();
        return this;
    }

    public hasLoanStatus(): boolean {
        return this._loanStatus !== undefined;
    }
}
