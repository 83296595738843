import { RAM_PERSISTENCE } from '../../utils/RAMPersistence';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';

export default class LoanRAMGateway {
    _entityKey = 'LoanEntity';

    retrieveById(loanId: TypePositiveInteger) {
        return RAM_PERSISTENCE.retrieve(loanId.value, this._entityKey);
    }

    persist(loan: any) {
        RAM_PERSISTENCE.persist(loan.id.value, loan, this._entityKey);
        return this;
    }
}
