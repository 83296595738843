import noloans from '../../assets/images/noloans-bc.svg';

const PartNoLoansMessage = () => {
    return (
        <div className="container">
            <div className="info-page__wrapper">
                <div className="info-page__image">
                    <img src={noloans} alt="no loans" />
                </div>
                <h1 className="page-info__title">No Loans Found</h1>
            </div>
        </div>
    );
};

export default PartNoLoansMessage;
