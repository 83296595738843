import React from 'react';
import {
    USER_ROLE_ADMIN,
    USER_ROLE_SUPER_ADMIN,
    USER_ROLE_USER
} from '../../domain/credentials/valueObject/UserRoleType';
import {
    loanStatusMapper,
    phase1LoanStatusValues,
    phase2LoanStatusValues,
    phase3LoanStatusValues,
    phase4LoanStatusValues
} from '../../domain/loan/valueObject/LoanStatusType';

interface PartLoanStatusLabelProps {
    status: number;
    userRole: number | null;
    showLargeStatus?: boolean;
}

const PartLoanStatusLabel = ({
    status,
    userRole,
    showLargeStatus = false
}: PartLoanStatusLabelProps) => {
    const loanStatusButtonText = loanStatusMapper[status] ?? 'Pending';
    let statusClass: string = '';

    switch (true) {
        case phase2LoanStatusValues.includes(status):
            statusClass = 'loan-card__item-status--yellow';
            break;
        case phase3LoanStatusValues.includes(status):
            statusClass = 'loan-card__item-status--green';
            break;
        case phase4LoanStatusValues.includes(status):
            statusClass = 'loan-card__item-status--gray'; // TODO: create css class, rename or create with same name
            break;
        default:
            statusClass = 'loan-card__item-status--blue';
    }

    if (showLargeStatus) userRole = USER_ROLE_ADMIN;

    const renderLoanStatus = () => {
        switch (userRole) {
            case USER_ROLE_SUPER_ADMIN:
                return (
                    <div className={'loan-card__item-status ' + statusClass}>
                        <span className="loan-card__item-status-val">
                            {loanStatusButtonText}
                        </span>
                    </div>
                );
            case USER_ROLE_ADMIN:
                return (
                    <div
                        className={
                            'loan-card__item loan-card__item-status ' +
                            statusClass
                        }
                    >
                        <span className="loan-card__item-status-val">
                            {loanStatusButtonText}
                        </span>
                    </div>
                );
            case USER_ROLE_USER:
                return (
                    <div
                        className={
                            'loan-card__item loan-card__item-status ' +
                            statusClass
                        }
                    >
                        <span className="loan-card__item-status-val">
                            {loanStatusButtonText}
                        </span>
                    </div>
                );

            default:
                return null;
        }
    };

    return renderLoanStatus();
};

export default PartLoanStatusLabel;
