import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DEAL_TYPE_NO_DOC_DSCR } from '../../../domain/loan/valueObject/LoanType';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import PartInputField from '../../parts/PartInputField';
import BlockStepsButtons from '../BlockStepsButtons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface EstimatedAsIsValueProps extends NewLoanApplicationStepsProps {
    submitForm: () => void;
}

const EstimatedAsIsValue = ({
    submitForm,
    prevStep,
    newLoanEntity
}: EstimatedAsIsValueProps) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                estimatedAsIsValue: yup
                    .string()
                    .required('Please complete this required field.')
                    .matches(
                        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                        'You must specify a number.'
                    )
            })
        )
    });

    const onSubmit = async (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );
        setSubmitButtonDisabled(true);
        await submitForm();
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        newLoanEntity.loanType.value === DEAL_TYPE_NO_DOC_DSCR
            ? prevStep(NewLoanSteps.MONTHLY_GROSS_RENTS_EXTENDED)
            : prevStep(NewLoanSteps.REHAB_AMOUNT);
    };

    useEffect(() => {
        newLoanEntity.hasEstimatedAsIsValue() &&
            setValue(
                'estimatedAsIsValue',
                newLoanEntity.estimatedAsIsValue.value
            );
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    What is the market value of your property?
                </h1>
                <p className="login__subtitle">Estimated As Is Value</p>
                <div className="login__box">
                    <PartInputField
                        label="Estimated As Is Value"
                        name="estimatedAsIsValue"
                        control={control}
                        errors={errors}
                        isRequired
                        isDollarAmount={true}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                        isFinalStep={true}
                        isSubmitButtonDisabled={submitButtonDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default EstimatedAsIsValue;
