import { ErrorMessage } from '@hookform/error-message';
import { Radio, Space } from 'antd';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Exclamation from '../icons/IconExclamation';

interface RadioButtonFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    capitalizeFirstLetter?: boolean;
    validationMessage?: string;
    defaultValue?: string;
    allowClear?: boolean;
    options: Array<{ label: string; value: number }>;
}

const PartRadioButton = ({
    name,
    label,
    control,
    errors,
    isRequired,
    validationMessage,
    options
}: RadioButtonFieldProps) => {
    return (
        <div
            className={`form-element form-element--radio ${
                name in errors ? 'form-element--error' : ''
            }`}
        >
            {label && (
                <label>
                    {label}
                    {isRequired && <span>{' *'}</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Radio.Group {...field}>
                        {options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartRadioButton;
