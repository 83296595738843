import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const USER_ROLE_USER = 1;
export const USER_ROLE_ADMIN = 2;
export const USER_ROLE_SUPER_ADMIN = 3;

export default class UserRoleType extends BaseValueObject {
    _value: any;

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    get value(): number {
        return parseInt(this._value);
    }

    getAvailableUserTypes() {
        return [USER_ROLE_USER, USER_ROLE_ADMIN, USER_ROLE_SUPER_ADMIN];
    }

    _validateValue() {
        if (
            this.getAvailableUserTypes().indexOf(this._value) < 0 ||
            this._value > this.getAvailableUserTypes().length
        ) {
            throw new Exception('Invalid User Type', 400);
        }
    }
}
