import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PartInputField from '../../parts/PartInputField';
import BlockStepsButtons from '../BlockStepsButtons';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { NewLoanSteps } from '../../../utils/enums';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
interface EstimatedAfterRepairValueProps extends NewLoanApplicationStepsProps {}

const EstimatedAfterRepairValue = ({
    nextStep,
    prevStep,
    newLoanEntity
}: EstimatedAfterRepairValueProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                estimatedAfterRepairValue: yup
                    .string()
                    .required('Please complete this required field.')
                    .matches(
                        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                        'You must specify a number.'
                    )
            })
        )
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.REHAB_AMOUNT);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.REHAB_TYPE);
    };

    useEffect(() => {
        newLoanEntity.hasEstimatedAfterRepairValue() &&
            setValue(
                'estimatedAfterRepairValue',
                newLoanEntity.estimatedAfterRepairValue.value
            );
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    What will the property value be post renovations?
                </h1>
                <p className="login__subtitle">After Repair Value</p>
                <div className="login__box">
                    <PartInputField
                        label="Estimated After Repair Value"
                        name="estimatedAfterRepairValue"
                        control={control}
                        errors={errors}
                        isRequired
                        isDollarAmount={true}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default EstimatedAfterRepairValue;
