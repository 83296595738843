import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const DEAL_TYPE_FIX_AND_FLIP = 1;
export const DEAL_TYPE_GROUND_UP = 2;
export const DEAL_TYPE_MULTIFAMILY_BRIDGE = 3;
export const DEAL_TYPE_STABILIZED_BRIDGE = 4;
export const DEAL_TYPE_NO_DOC_DSCR = 5;

export const FIELD_NAME_DEAL_TYPE_FIX_AND_FLIP = 'Fix and Flip';
export const FIELD_NAME_DEAL_TYPE_GROUND_UP = 'Ground Up';
export const FIELD_NAME_DEAL_TYPE_MULTIFAMILY_BRIDGE = 'Multifamily Bridge';
export const FIELD_NAME_DEAL_TYPE_STABILIZED_BRIDGE = 'Stabilized Bridge';
export const FIELD_NAME_DEAL_TYPE_NO_DOC_DSCR = 'No Doc - DSCR';

export default class LoanType extends BaseValueObject {
    _value: any;
    _name: string;
    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableDealTypes() {
        return [
            DEAL_TYPE_FIX_AND_FLIP,
            DEAL_TYPE_GROUND_UP,
            DEAL_TYPE_MULTIFAMILY_BRIDGE,
            DEAL_TYPE_STABILIZED_BRIDGE,
            DEAL_TYPE_NO_DOC_DSCR
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case DEAL_TYPE_FIX_AND_FLIP:
                this._name = FIELD_NAME_DEAL_TYPE_FIX_AND_FLIP;
                break;
            case DEAL_TYPE_GROUND_UP:
                this._name = FIELD_NAME_DEAL_TYPE_GROUND_UP;
                break;
            case DEAL_TYPE_MULTIFAMILY_BRIDGE:
                this._name = FIELD_NAME_DEAL_TYPE_MULTIFAMILY_BRIDGE;
                break;
            case DEAL_TYPE_STABILIZED_BRIDGE:
                this._name = FIELD_NAME_DEAL_TYPE_STABILIZED_BRIDGE;
                break;
            case DEAL_TYPE_NO_DOC_DSCR:
                this._name = FIELD_NAME_DEAL_TYPE_NO_DOC_DSCR;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableDealTypes().indexOf(this._value) < 0) {
            throw new Exception('Invalid Deal Type', 400);
        }
    }
}
