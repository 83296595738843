import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import authSliceState, { triggerLogout } from '../../store/auth';
import headerLogo from '../../assets/images/NDL logo.png';
import profilePlaceholder from '../../assets/images/profile-placeholder.png';
import profilePlaceholderBig from '../../assets/images/profile-placeholder-big.png';
import IconArrowDown from '../../components/icons/IconArrowDown';
import { useSelector } from 'react-redux';
import CredentialsService from '../../domain/credentials/CredentialsService';
import store from '../../store/storeIndex';
import { Button } from 'antd';
import IconArrowUpRightSquare from '../icons/IconArrowUpRightSquare';

const useAuth = () => {
    const isUserLoggedIn = localStorage.getItem('isLoggedIn');
    return isUserLoggedIn;
};

const MainHeader = () => {
    // toggling header dropdown
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [loggingOut, setLoggingOut] = useState(false);
    const headerAccount = useRef<HTMLDivElement | null>(null);
    const headerAccountNav = useRef<HTMLDivElement | null>(null);
    const [isHamburgerOpen, hamburgerIsOpen] = useState(false);

    const isAuth = useAuth();
    const authReduxState = useSelector(authSliceState);

    function toggleDropdown() {
        isDropdownOpen ? setIsDropdownOpen(false) : setIsDropdownOpen(true);
    }

    function toggleHamburgermenu() {
        if (window.innerWidth < 1025) {
            isHamburgerOpen ? hamburgerIsOpen(false) : hamburgerIsOpen(true);
            document.body.classList.toggle('prevent-scroll');
        }
    }

    const handleLogoutClick = async () => {
        setLoggingOut(true);
        await CredentialsService.logout();
        store.dispatch(triggerLogout());
        setIsDropdownOpen(false);
        hamburgerIsOpen(false);
        setLoggingOut(false);
        window.location.href = '/login';
    };

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                isDropdownOpen &&
                headerAccount.current &&
                !headerAccount.current.contains(e.target) &&
                !headerAccountNav.current!.contains(e.target)
            ) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isDropdownOpen]);

    return (
        <header className="header">
            <div
                className={
                    isDropdownOpen || isHamburgerOpen
                        ? 'overlay--active overlay'
                        : 'overlay'
                }
                onClick={toggleHamburgermenu}
            ></div>
            <div className="container container--long">
                <div className="header__wrapper">
                    <div className="header__logo-wrapper">
                        <NavLink to={'/my-loans'}>
                            <img
                                className="header__logo"
                                src={headerLogo}
                                alt="Header logo"
                            />
                        </NavLink>
                    </div>
                    {authReduxState.token && isAuth ? (
                        <>
                            <div
                                className={
                                    isHamburgerOpen
                                        ? 'nav-wrapper nav-wrapper--active'
                                        : 'nav-wrapper'
                                }
                            >
                                <div
                                    className="nav-wrapper__close-btn-mobile"
                                    onClick={toggleHamburgermenu}
                                ></div>
                                <div className="nav-wrapper__account-mobile-wrapper">
                                    <div className="header__account-dropdown-img-wrapper">
                                        <img
                                            src=""
                                            alt="Profile image"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                    profilePlaceholderBig;
                                            }}
                                        ></img>
                                    </div>
                                    <span className="header__account-dropdown-name">
                                        {authReduxState.firstName + ' '}
                                        {authReduxState.lastName}
                                    </span>
                                    <span className="header__account-dropdown-email">
                                        {authReduxState.email}
                                    </span>
                                </div>
                                <div className="header__nav">
                                    {/* <NavLink
                                        to={'/'}
                                        onClick={toggleHamburgermenu}
                                    >
                                        Loan Calculation
                                    </NavLink> */}
                                    <NavLink
                                        to={'/my-loans'}
                                        onClick={toggleHamburgermenu}
                                    >
                                        Loans
                                    </NavLink>
                                    <NavLink
                                        to={'https://ndllistings.com/'}
                                        onClick={toggleHamburgermenu}
                                        className="header__nav-link-icon"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Listings
                                        <IconArrowUpRightSquare />
                                    </NavLink>
                                    {/* <NavLink
                                        to={'/'}
                                        onClick={toggleHamburgermenu}
                                    >
                                        Messages{' '}
                                        <span className="messages-counter">
                                            3
                                        </span>
                                    </NavLink> */}
                                </div>
                                <div className="nav-wrapper__button-mobile-wrapper">
                                    <Button
                                        className="btn white"
                                        disabled={loggingOut}
                                        onClick={handleLogoutClick}
                                        loading={loggingOut}
                                    >
                                        {loggingOut
                                            ? 'Logging Out...'
                                            : 'Logout'}
                                    </Button>
                                </div>
                                <div
                                    ref={headerAccount}
                                    className="header__account"
                                    onClick={toggleDropdown}
                                >
                                    <div className="header__account-img">
                                        <img
                                            src=""
                                            alt="Profile image"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                    profilePlaceholder;
                                            }}
                                        ></img>
                                    </div>
                                    <div className="header__account-name">
                                        {authReduxState.firstName ||
                                            localStorage.getItem('firstName')}
                                    </div>
                                    <div className="header__account-icon">
                                        <IconArrowDown />
                                    </div>
                                </div>
                                <div
                                    ref={headerAccountNav}
                                    className={
                                        isDropdownOpen
                                            ? 'header__account-dropdown header__account-dropdown--active'
                                            : 'header__account-dropdown'
                                    }
                                >
                                    <div className="header__account-dropdown-img-wrapper">
                                        <img
                                            src=""
                                            alt="Profile image"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                    profilePlaceholderBig;
                                            }}
                                        ></img>
                                    </div>
                                    <span className="header__account-dropdown-name">
                                        {authReduxState.firstName + ' '}
                                        {authReduxState.lastName}
                                    </span>
                                    <span className="header__account-dropdown-email">
                                        {authReduxState.email}
                                    </span>
                                    <Button
                                        className="btn white"
                                        disabled={loggingOut}
                                        loading={loggingOut}
                                        onClick={handleLogoutClick}
                                    >
                                        {loggingOut
                                            ? 'Logging Out...'
                                            : 'Logout'}
                                    </Button>
                                </div>
                            </div>
                            <div
                                className="hamburger-icon"
                                onClick={toggleHamburgermenu}
                            >
                                <span></span>
                            </div>
                        </>
                    ) : (
                        <>
                            <NavLink
                                to={'/login'}
                                className="header__login-btn"
                            >
                                Login
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};

export default MainHeader;
