import {
    sanitizePositiveFloat,
    sanitizePositiveInteger
} from '../../utils/utils';
import Collection from '../valueObject/Collection';
import TypePositiveFloat from '../valueObject/TypePositiveFloat';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import { LoanTermEntity } from './LoanTermEntity';
import { LoanTermAmortizationType } from './valueObject/LoanTermAmortizationType';
import { LoanTermInterestAccrualMethod } from './valueObject/LoanTermInterestAccrualMethod';
import { LoanTermInterestMechanicTerm } from './valueObject/LoanTermInterestMechanicTerm';
import { LoanTermInterestReserve } from './valueObject/LoanTermInterestReserve';
import { LoanTermPurpose } from './valueObject/LoanTermLoanPurpose';
import { LoanTermRateType } from './valueObject/LoanTermRateType';
import { LoanTermRecourseGuarantyStructure } from './valueObject/LoanTermRecourseGuarantyStructure';
import { InterestMechanicsPrePaymentPenalty } from './valueObject/InterestMechanicsPrePaymentPenalty';
import { PitiReserve } from './valueObject/PitiReserve';
import { PricingPrePaymentPenalty } from './valueObject/PricingPrePaymentPenalty';

export class LoanTermFactory {
    reconstitute(data: Collection) {
        const loanTerm = new LoanTermEntity();
        if (data.has('loan_id')) {
            loanTerm.setLoanId(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('loan_id'))
                )
            );
        }
        if (data.has('loan_type')) {
            loanTerm.setLoanType(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('loan_type'))
                )
            );
        }
        // total_loan_amount
        if (data.has('total_loan_amount')) {
            loanTerm.setTotalLoanAmount(
                new TypePositiveInteger(data.get('total_loan_amount'))
            );
        }
        if (data.has('construction_hold_back')) {
            loanTerm.setConstructionHoldback(
                new TypePositiveFloat(data.get('construction_hold_back'))
            );
        }
        if (data.has('construction_hold_back_renovation_budget')) {
            loanTerm.setConstructionHoldbackRenovationBudget(
                new TypePositiveFloat(
                    data.get('construction_hold_back_renovation_budget')
                )
            );
        }
        // created_at
        // id
        if (data.has('initial_loan_amount')) {
            loanTerm.setInitialLoanAmount(
                new TypePositiveInteger(data.get('initial_loan_amount'))
            );
        }
        if (data.has('initial_loan_amount_purchase_price')) {
            loanTerm.setInitialLoanAmountPurchasePrice(
                new TypePositiveInteger(
                    data.get('initial_loan_amount_purchase_price')
                )
            );
        }
        if (data.has('recourse_guaranty_structure')) {
            loanTerm.setRecourseGuarantyStructure(
                new LoanTermRecourseGuarantyStructure(
                    data.get('recourse_guaranty_structure')
                )
            );
        }

        if (data.has('interest_accrual_method')) {
            loanTerm.setInterestAccrualMethod(
                new LoanTermInterestAccrualMethod(
                    data.get('interest_accrual_method')
                )
            );
        }
        if (data.has('interest_mechanic_term')) {
            loanTerm.setInterestMechanicTerm(
                new LoanTermInterestMechanicTerm(
                    data.get('interest_mechanic_term')
                )
            );
        }

        if (data.has('interest_rate')) {
            loanTerm.setInterestRate(
                new TypePositiveFloat(data.get('interest_rate'))
            );
        }
        if (data.has('origination_fee_percentage')) {
            loanTerm.setOriginationFeePercentage(
                new TypePositiveFloat(data.get('origination_fee_percentage'))
            );
        }

        if (data.has('broker_fee_percentage')) {
            loanTerm.setBrokerFeePercentage(
                new TypePositiveFloat(data.get('broker_fee_percentage'))
            );
        }
        if (data.has('interest_reserve')) {
            loanTerm.setInterestReserve(
                new LoanTermInterestReserve(data.get('interest_reserve'))
            );
        }
        if (data.has('exit_fees')) {
            loanTerm.setExitFee(new TypePositiveFloat(data.get('exit_fees')));
        }
        if (data.has('rate_type')) {
            loanTerm.setRateType(new LoanTermRateType(data.get('rate_type')));
        }
        if (data.has('amortization_type')) {
            loanTerm.setAmortizationType(
                new LoanTermAmortizationType(data.get('amortization_type'))
            );
        }

        if (data.has('underwriting_fee')) {
            loanTerm.setUnderwritingFee(
                new TypePositiveInteger(data.get('underwriting_fee'))
            );
        }
        if (data.has('processing_fee')) {
            loanTerm.setProcessingFee(
                new TypePositiveInteger(data.get('processing_fee'))
            );
        }
        if (data.has('legal_fee')) {
            loanTerm.setLegalFee(
                new TypePositiveInteger(data.get('legal_fee'))
            );
        }
        if (data.has('ltv')) {
            loanTerm.setCcLtv(new TypePositiveFloat(data.get('ltv')));
        }
        if (data.has('ltarv')) {
            loanTerm.setCcLtarv(new TypePositiveFloat(data.get('ltarv')));
        }
        if (data.has('ltfc')) {
            loanTerm.setCcLtfc(new TypePositiveFloat(data.get('ltfc')));
        }
        if (data.has('liquidity')) {
            loanTerm.setCcLiquidity(
                new TypePositiveInteger(data.get('liquidity'))
            );
        }
        if (data.has('min_credit_score')) {
            loanTerm.setCcMinCreditScore(
                new TypePositiveInteger(data.get('min_credit_score'))
            );
        }
        if (data.has('net_worth')) {
            loanTerm.setCcNetWorth(
                new TypePositiveInteger(data.get('net_worth'))
            );
        }
        if (data.has('track_record')) {
            loanTerm.setCcTrackRecord(
                new TypePositiveInteger(data.get('track_record'))
            );
        }
        if (data.has('dscr')) {
            loanTerm.setCcDscr(new TypePositiveFloat(data.get('dscr')));
        }
        if (data.has('loan_purpose')) {
            loanTerm.setLoanPurpose(
                new LoanTermPurpose(data.get('loan_purpose'))
            );
        }
        if (data.has('loan_to_value')) {
            loanTerm.setLoanToValue(
                new TypePositiveFloat(data.get('loan_to_value'))
            );
        }

        if (data.has('prepayment_penalty')) {
            if (
                InterestMechanicsPrePaymentPenalty.getAvailablePrePaymentPenalty().includes(
                    data.get('prepayment_penalty')
                )
            ) {
                loanTerm.setInterestMechanicsPrePaymentPenalty(
                    new InterestMechanicsPrePaymentPenalty(
                        data.get('prepayment_penalty')
                    )
                );
            } else {
                loanTerm.setPricingPrePaymentPenalty(
                    new PricingPrePaymentPenalty(data.get('prepayment_penalty'))
                );
            }
        }

        if (data.has('piti_reserve')) {
            loanTerm.setFePitiReserve(
                new PitiReserve(data.get('piti_reserve'))
            );
        }

        if (data.has('insurance_escrow_amount')) {
            loanTerm.setFeInsuranceEscrowAmount(
                new TypePositiveInteger(data.get('insurance_escrow_amount'))
            );
        }
        if (data.has('tax_escrow_amount')) {
            loanTerm.setFeTaxEscrowAmount(
                new TypePositiveInteger(data.get('tax_escrow_amount'))
            );
        }

        return loanTerm;
    }

    static makeFromForm(data: Collection) {
        const loanTerm = new LoanTermEntity();

        if (data.has('loan_id')) {
            loanTerm.setLoanId(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('loan_id'))
                )
            );
        }
        if (data.has('loan_type')) {
            loanTerm.setLoanType(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('loan_type'))
                )
            );
        }

        // Loan terms

        if (data.has('loan_terms-loan_purpose')) {
            loanTerm.setLoanPurpose(
                new LoanTermPurpose(data.get('loan_terms-loan_purpose'))
            );
        }
        if (data.has('loan_terms-initial_loan_amount')) {
            loanTerm.setInitialLoanAmount(
                new TypePositiveInteger(
                    sanitizePositiveInteger(
                        data.get('loan_terms-initial_loan_amount')
                    )
                )
            );
        }
        if (
            data.has('loan_terms-initial_loan_amount_purchase_price') &&
            data.get('loan_terms-initial_loan_amount_purchase_price') !==
                undefined
        ) {
            loanTerm.setInitialLoanAmountPurchasePrice(
                new TypePositiveInteger(
                    sanitizePositiveInteger(
                        data.get(
                            'loan_terms-initial_loan_amount_purchase_price'
                        )
                    )
                )
            );
        }
        if (data.has('loan_terms-construction_hold_back')) {
            loanTerm.setConstructionHoldback(
                new TypePositiveFloat(
                    data.get('loan_terms-construction_hold_back')
                )
            );
        }
        if (
            data.has('loan_terms-construction_hold_back_renovation_budget') &&
            data.get('loan_terms-construction_hold_back_renovation_budget') !==
                undefined
        ) {
            loanTerm.setConstructionHoldbackRenovationBudget(
                new TypePositiveFloat(
                    data.get(
                        'loan_terms-construction_hold_back_renovation_budget'
                    )
                )
            );
        }
        if (
            data.has('loan_terms-total_loan_amount') &&
            data.get('loan_terms-total_loan_amount') !== undefined
        )
            loanTerm.setTotalLoanAmount(
                new TypePositiveInteger(
                    sanitizePositiveInteger(
                        data.get('loan_terms-total_loan_amount')
                    )
                )
            );
        if (
            data.has('loan_terms-recourse_guaranty_structure') &&
            data.get('loan_terms-recourse_guaranty_structure') !== undefined
        ) {
            loanTerm.setRecourseGuarantyStructure(
                new LoanTermRecourseGuarantyStructure(
                    data.get('loan_terms-recourse_guaranty_structure')
                )
            );
        }
        if (data.has('loan_terms-loan-amount')) {
            // loanTerm.setLoanAmount(
            //     new TypePositiveInteger(data.get('loan_terms-loan-amount'))
            // );
        }
        if (
            data.has('loan_terms-loan_to_value') &&
            data.get('loan_terms-loan_to_value') !== undefined
        ) {
            loanTerm.setLoanToValue(
                new TypePositiveFloat(data.get('loan_terms-loan_to_value'))
            );
        }

        // Pricing

        if (data.has('pricing-interest_rate')) {
            loanTerm.setInterestRate(
                new TypePositiveFloat(data.get('pricing-interest_rate'))
            );
        }

        if (data.has('pricing-exit_fee')) {
            loanTerm.setExitFee(
                new TypePositiveFloat(data.get('pricing-exit_fee'))
            );
        }

        if (
            data.has('pricing-rate_type') &&
            data.get('pricing-rate_type') !== undefined
        ) {
            loanTerm.setRateType(
                new LoanTermRateType(data.get('pricing-rate_type'))
            );
        }
        if (
            data.has('pricing-amortization_type') &&
            data.get('pricing-amortization_type') !== undefined
        ) {
            loanTerm.setAmortizationType(
                new LoanTermAmortizationType(
                    data.get('pricing-amortization_type')
                )
            );
        }
        if (
            data.has('pricing-pre_payment_penalty') &&
            data.get('pricing-pre_payment_penalty') !== undefined
        ) {
            loanTerm.setPricingPrePaymentPenalty(
                new PricingPrePaymentPenalty(
                    data.get('pricing-pre_payment_penalty')
                )
            );
        }

        // Interest Mechanics

        if (data.has('interest_mechanics-term')) {
            loanTerm.setInterestMechanicTerm(
                new LoanTermInterestMechanicTerm(
                    data.get('interest_mechanics-term')
                )
            );
        }
        if (data.has('interest_mechanics-interest_accrual_method')) {
            loanTerm.setInterestAccrualMethod(
                new LoanTermInterestAccrualMethod(
                    data.get('interest_mechanics-interest_accrual_method')
                )
            );
            if (data.has('interest_mechanics-pre_payment_penalty')) {
                loanTerm.setInterestMechanicsPrePaymentPenalty(
                    new InterestMechanicsPrePaymentPenalty(
                        data.get('interest_mechanics-pre_payment_penalty')
                    )
                );
            }
            if (data.has('interest_mechanics-interest_reserve')) {
                loanTerm.setInterestReserve(
                    new LoanTermInterestReserve(
                        data.get('interest_mechanics-interest_reserve')
                    )
                );
            }
        }

        // Closing Conditions

        if (data.has('cc-ltv')) {
            loanTerm.setCcLtv(new TypePositiveFloat(data.get('cc-ltv')));
        }
        if (data.has('cc-ltarv')) {
            loanTerm.setCcLtarv(new TypePositiveFloat(data.get('cc-ltarv')));
        }
        if (data.has('cc-ltfc')) {
            loanTerm.setCcLtfc(new TypePositiveFloat(data.get('cc-ltfc')));
        }
        if (data.has('cc-min_credit_score')) {
            loanTerm.setCcMinCreditScore(
                new TypePositiveInteger(data.get('cc-min_credit_score'))
            );
        }
        if (data.has('cc-track_record')) {
            loanTerm.setCcTrackRecord(
                new TypePositiveInteger(data.get('cc-track_record'))
            );
        }
        if (data.has('cc-liquidity')) {
            loanTerm.setCcLiquidity(
                new TypePositiveInteger(
                    sanitizePositiveFloat(data.get('cc-liquidity'))
                )
            );
        }
        if (data.has('cc-net_worth')) {
            loanTerm.setCcNetWorth(
                new TypePositiveInteger(
                    sanitizePositiveFloat(data.get('cc-net_worth'))
                )
            );
        }
        if (data.has('cc-dscr') && data.get('cc-dscr') !== undefined) {
            loanTerm.setCcDscr(new TypePositiveFloat(data.get('cc-dscr')));
        }

        // Fees and Escrows
        if (data.has('fees_and_escrows-piti_reserve')) {
            loanTerm.setFePitiReserve(
                new PitiReserve(data.get('fees_and_escrows-piti_reserve'))
            );
        }
        if (
            data.has('fees_and_escrows-insurance_escrow_amount') &&
            data.get('fees_and_escrows-insurance_escrow_amount') !== undefined
        ) {
            loanTerm.setFeInsuranceEscrowAmount(
                new TypePositiveInteger(
                    sanitizePositiveInteger(
                        data.get('fees_and_escrows-insurance_escrow_amount')
                    )
                )
            );
        }
        if (
            data.has('fees_and_escrows-tax_escrow_amount') &&
            data.get('fees_and_escrows-tax_escrow_amount') !== undefined
        ) {
            loanTerm.setFeTaxEscrowAmount(
                new TypePositiveInteger(
                    sanitizePositiveInteger(
                        data.get('fees_and_escrows-tax_escrow_amount')
                    )
                )
            );
        }
        if (
            data.has('underwriting_fee') &&
            data.get('underwriting_fee') !== undefined
        ) {
            loanTerm.setUnderwritingFee(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('underwriting_fee'))
                )
            );
        }
        if (
            data.has('processing_fee') &&
            data.get('processing_fee') !== undefined
        ) {
            loanTerm.setProcessingFee(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('processing_fee'))
                )
            );
        }
        if (data.has('legal_fee') && data.get('legal_fee') !== undefined) {
            loanTerm.setLegalFee(
                new TypePositiveInteger(
                    sanitizePositiveInteger(data.get('legal_fee'))
                )
            );
        }

        if (data.has('origination_fee_percentage')) {
            loanTerm.setOriginationFeePercentage(
                new TypePositiveFloat(data.get('origination_fee_percentage'))
            );
        }

        if (data.has('broker_fee_percentage')) {
            loanTerm.setBrokerFeePercentage(
                new TypePositiveFloat(data.get('broker_fee_percentage'))
            );
        }
        return loanTerm;
    }
}
