import BaseModel from '../BaseModel';
import TypeOrderDirection from '../valueObject/TypeOrderDirection';
import LoanSortingField from './valueObject/LoanSortingField';

export default class LoanSortingRule extends BaseModel {
    private _field: LoanSortingField;
    private _direction: TypeOrderDirection;
    public get field(): LoanSortingField {
        return this._field;
    }
    public setField(value: LoanSortingField) {
        this._field = value;
        return this;
    }
    public get direction(): TypeOrderDirection {
        return this._direction;
    }
    public setDirection(value: TypeOrderDirection) {
        this._direction = value;
        this._executeAfterChangeTriggers();
        return this;
    }
}
