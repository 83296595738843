import TypeString from '../valueObject/TypeString';

export default class AddressEntity {
    private _street: TypeString;
    private _city: TypeString;
    private _state: TypeString;
    private _zip: TypeString;

    public get street(): TypeString {
        return this._street;
    }
    public setStreet(value: TypeString) {
        this._street = value;
        return this;
    }
    public hasStreet() {
        return this._street instanceof TypeString;
    }
    public get city(): TypeString {
        return this._city;
    }
    public setCity(value: TypeString) {
        this._city = value;
        return this;
    }
    public hasCity() {
        return this._city instanceof TypeString;
    }
    public get state(): TypeString {
        return this._state;
    }
    public setState(value: TypeString) {
        this._state = value;
        return this;
    }
    public hasState() {
        return this._state instanceof TypeString;
    }
    public get zip(): TypeString {
        return this._zip;
    }
    public setZip(value: TypeString) {
        this._zip = value;
        return this;
    }
    public hasZip() {
        return this._zip instanceof TypeString;
    }
}
