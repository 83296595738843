import DealAssetType from '../loan/valueObject/DealAssetType';
import DealTransactionType from '../loan/valueObject/DealTransactionType';
import LoanType from '../loan/valueObject/LoanType';
import RehabType from '../loan/valueObject/RehabType';
import Collection from '../valueObject/Collection';
import TypeFloat from '../valueObject/TypeFloat';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';
import LoanApplicationEntity from './LoanApplicationEntity';
import { sanitizePositiveInteger as sanitizePositiveInteger } from './../../utils/utils';

export default class LoanApplicationFactory {
    static makeFromForm(
        newLoanEntity: LoanApplicationEntity,
        data: Collection
    ) {
        // Contact Details Info
        if (data.has('firstName'))
            newLoanEntity.setFirstName(new TypeString(data.get('firstName')));
        if (data.has('lastName'))
            newLoanEntity.setLastName(new TypeString(data.get('lastName')));
        if (data.has('email'))
            newLoanEntity.setEmail(new TypeString(data.get('email')));
        if (data.has('phone'))
            newLoanEntity.setPhone(new TypeString(data.get('phone')));

        // Contact Details Address
        if (data.has('address') && data.get('address') !== undefined)
            newLoanEntity.setPropertyAddress(
                new TypeString(data.get('address'))
            );
        if (data.has('city') && data.get('city') !== undefined)
            newLoanEntity.setCity(new TypeString(data.get('city')));
        if (data.has('state') && data.get('state') !== undefined)
            newLoanEntity.setState(new TypeString(data.get('state')));
        if (data.has('postal') && data.get('postal') !== undefined)
            newLoanEntity.setPostalCode(new TypeString(data.get('postal')));
        if (data.has('unitCount') && data.get('unitCount') !== undefined)
            newLoanEntity.setUnitCount(
                new TypePositiveInteger(data.get('unitCount'))
            );
        if (data.has('assetClass') && data.get('assetClass') !== undefined)
            newLoanEntity.setAssetClass(
                new DealAssetType(data.get('assetClass'))
            );

        // Credit Score
        if (data.has('creditScore') && data.get('creditScore') !== undefined)
            newLoanEntity.setCreditScore(
                new TypeFloat(data.get('creditScore'))
            );

        // Rental Property Experience
        if (
            data.has('rentalPropertyExperience') &&
            data.get('rentalPropertyExperience') !== undefined
        )
            newLoanEntity.setPropertyCountExperience(
                new TypePositiveInteger(data.get('rentalPropertyExperience'))
            );

        // Transaction Type
        if (
            data.has('transactionType') &&
            data.get('transactionType') !== undefined
        ) {
            newLoanEntity.setTransactionType(
                new DealTransactionType(parseInt(data.get('transactionType')))
            );
        }

        // Original Purchase Date
        if (
            data.has('originalPurchaseDate') &&
            data.get('originalPurchaseDate') !== undefined
        )
            newLoanEntity.setOriginalPurchaseDate(
                new Date(data.get('originalPurchaseDate'))
            );

        // Purchase Price
        if (
            data.has('purchasePrice') &&
            data.get('purchasePrice') !== undefined
        )
            newLoanEntity.setPurchasePrice(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('purchasePrice'))
                )
            );

        // Rehab Amount
        if (data.has('rehabAmount') && data.get('rehabAmount') !== undefined)
            newLoanEntity.setRehabAmount(
                new TypeFloat(sanitizePositiveInteger(data.get('rehabAmount')))
            );

        // Rehab Amount Spent to Date
        if (
            data.has('rehabAmountSpentToDate') &&
            data.get('rehabAmountSpentToDate') !== undefined
        )
            newLoanEntity.setRehabAmountSpendToDate(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('rehabAmountSpentToDate'))
                )
            );

        // Existing Debt
        if (data.has('existingDebt') && data.get('existingDebt') !== undefined)
            newLoanEntity.setExistingDebt(
                new TypeFloat(sanitizePositiveInteger(data.get('existingDebt')))
            );

        // Original Purchase Price
        if (
            data.has('originalPurchasePrice') &&
            data.get('originalPurchasePrice') !== undefined
        )
            newLoanEntity.setOriginalPurchasePrice(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('originalPurchasePrice'))
                )
            );

        // Loan Type
        if (data.has('loanType') && data.get('loanType') !== undefined)
            newLoanEntity.setLoanType(new LoanType(data.get('loanType')));

        // Estimated As Is Value
        if (
            data.has('estimatedAsIsValue') &&
            data.get('estimatedAsIsValue') !== undefined
        )
            newLoanEntity.setEstimatedAsIsValue(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('estimatedAsIsValue'))
                )
            );

        // Rehab Type
        if (data.has('rehabType') && data.get('rehabType') !== undefined)
            newLoanEntity.setRehabType(new RehabType(data.get('rehabType')));

        if (
            data.has('estimatedAfterRepairValue') &&
            data.get('estimatedAfterRepairValue') !== undefined
        )
            newLoanEntity.setEstimatedAfterRepairValue(
                new TypeFloat(
                    sanitizePositiveInteger(
                        data.get('estimatedAfterRepairValue')
                    )
                )
            );

        // Monthly Gross Rents
        if (
            data.has('monthlyGrossRents') &&
            data.get('monthlyGrossRents') !== undefined
        )
            newLoanEntity.setMonthlyGrossRents(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('monthlyGrossRents'))
                )
            );

        // Monthly Gross Rents Extended
        if (data.has('taxes') && data.get('taxes') !== undefined)
            newLoanEntity.setTaxes(
                new TypeFloat(sanitizePositiveInteger(data.get('taxes')))
            );
        if (data.has('insurance') && data.get('insurance') !== undefined)
            newLoanEntity.setInsurance(
                new TypeFloat(sanitizePositiveInteger(data.get('insurance')))
            );
        if (data.has('utilities') && data.get('utilities') !== undefined)
            newLoanEntity.setUtilities(
                new TypeFloat(sanitizePositiveInteger(data.get('utilities')))
            );
        if (data.has('hoa') && data.get('hoa') !== undefined)
            newLoanEntity.setHoa(
                new TypeFloat(sanitizePositiveInteger(data.get('hoa')))
            );
        if (
            data.has('otherExpenses') &&
            data.get('otherExpenses') !== undefined
        )
            newLoanEntity.setOtherExpenses(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('otherExpenses'))
                )
            );
        if (
            data.has('repairsMaintenance') &&
            data.get('repairsMaintenance') !== undefined
        )
            newLoanEntity.setrepairsMaintenance(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('repairsMaintenance'))
                )
            );
        if (
            data.has('managementExpenses') &&
            data.get('managementExpenses') !== undefined
        )
            newLoanEntity.setManagementExpenses(
                new TypeFloat(
                    sanitizePositiveInteger(data.get('managementExpenses'))
                )
            );
        // populaate the rest of the dields into newLoanEntity instnace

        return newLoanEntity;
    }

    static makeEmpty() {
        return new LoanApplicationEntity();
    }
}
