import React, { useEffect } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';

const TermsAndConditions = () => {
    useDocumentTitle('Terms and Conditions');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            <div className="basic-content">
                <h1>Terms and Conditions</h1>
                <span>LAST UPDATED MARCH 2022</span>
                <h2>Disclaimer</h2>
                <p>
                    The information on this website has been obtained from
                    sources believed to be reliable. We have not verified it and
                    make no guarantee, warranty, or representation about it. Any
                    projections, opinions, assumptions, or estimates used are
                    for example only, and do not represent the current or future
                    performance of the property. You and your advisors should
                    conduct a careful, independent investigation of the property
                    and loan specifications to determine to your satisfaction
                    the suitability of the property and loan for your needs.
                    Please see our Advertiser Disclosure below.
                </p>
                <h2>Terms of Use</h2>
                <p>
                    This Terms of Use agreement (this “agreement”) is a legal
                    agreement between you and NDL, the owner of this website
                    (“Company,” “we,” “us,” or “our”) providing, among other
                    things, the terms and conditions for your use of our website
                    (the “Website”).
                </p>
                <p>
                    We may from time to time modify these Terms and Conditions
                    and will post a copy of the amended agreement on the Terms
                    and Conditions web page. If you do not agree to, or cannot
                    comply with, the agreement as amended, you should not use
                    the Website. You will be deemed to have accepted the
                    agreement as amended if you continue to use the Website
                    after any amendments are posted on the Website. We reserve
                    the right to refuse to provide our products and services to
                    anyone at any time.
                </p>
                <p>
                    This agreement contains warranty and liability disclaimers
                    as well as advertising disclosures. By using this Website,
                    you accept and agree to the terms and conditions of this
                    agreement without any reservations, modifications,
                    additions, or deletions. If you do not agree to the Terms of
                    Use contained in this agreement, you are not authorized to
                    use the Website. You may be denied access to the Website,
                    with or without prior notice to you, if you do not comply
                    with any provision of this agreement.
                </p>
                <h2>Products and services</h2>
                <p>
                    You understand and agree that Company is not a lender and is
                    not the creditor for or issuer of the products featured on
                    the Website. Any products or services advertised at this
                    site or made available to you after you receive a financial
                    product from an issuer are by and remain the sole
                    responsibility of the respective product vendors and service
                    providers. Company may act as a consultant, broker, or may
                    simply forward you to certain third parties that may offer
                    products or services that you may seek, particularly in the
                    area of commercial, business and multifamily loans.
                </p>
                <h2>Loan request policy</h2>
                <p>
                    Company is not a lender and does not accept loan
                    applications. It only accepts requests to be connected with
                    lenders and may collect information from your business
                    pertaining to potential loan applications with such third
                    parties. Each lender may use the information you provide on
                    this Website for your application for credit with that
                    lender. Your receipt of an electronic or other form of
                    confirmation that your request has been submitted does not
                    signify any acceptance of your application with your
                    prospective lender, nor does it constitute confirmation of
                    any offer to fund. Company may require additional
                    verifications or information before accepting any request.
                    Company is not responsible for requests on the Website or
                    applications to lenders that cannot be funded or
                    unsuccessful funding applications arising from an
                    applicant’s ineligibility to qualify for the funding
                    opportunity.
                </p>
                <h3>1. Authorized users</h3>
                <h4>1.1. Age requirement; authority.</h4>
                <p>
                    In order to use the Website, you must be at least 18 years
                    of age. You represent that:
                </p>
                <ol>
                    <li>
                        you are an authorized representative of the company
                        seeking loan assistance;
                    </li>
                    <li>
                        you have read, understand, and agree to be bound by,
                        this agreement; and
                    </li>
                    <li>you are at least 18 years old.</li>
                </ol>
                <p>
                    If you do not agree to, or cannot comply with, any of these
                    terms and conditions of this agreement, please do not
                    attempt to access this Website.
                </p>
                <h4>1.2. Registration.</h4>
                <p>
                    We reserve the right to restrict certain areas of
                    information on this Website to our approved registered
                    users. In the event that any area of the Website requires
                    you to register with us prior to our granting you access to
                    such areas of the Website, you agree to provide truthful
                    information, including but not limited to a member name and
                    password as well as your name, address, telephone number,
                    and a valid email address (the “account information”). You
                    agree to provide accurate account information and to update
                    your account information as necessary to keep it accurate.
                </p>
                <p>
                    Company will use your account information in accordance with
                    its privacy policy, which can be found on our Privacy Policy
                    page, as described further in Section 6 of this agreement.
                    You are solely responsible for maintaining the
                    confidentiality of your member name and password. You agree
                    to notify us immediately of any unauthorized use of your
                    member name, password, or account. Company will not be
                    responsible for any losses arising from the unauthorized use
                    of your account, and you agree to indemnify and hold
                    harmless Company, its partners, parents, subsidiaries,
                    agents, affiliates, and/or licensors, as applicable, for any
                    improper, unauthorized, or illegal uses of your account.
                </p>
                <h3>2. License to use the site</h3>
                <h4>2.1. Grant of license.</h4>
                <p>
                    Company grants to you a limited, non-exclusive,
                    non-transferable license to access and use the Website in
                    accordance with the terms and conditions set forth in this
                    agreement. Company reserves all right, title, and interest
                    not expressly granted under this license to the fullest
                    extent possible under applicable laws. Any use of the
                    Website not specifically permitted under this agreement is
                    strictly prohibited.
                </p>
                <h4>2.2. Restrictions.</h4>
                <p>You agree that you will not:</p>
                <ol>
                    <li>
                        use the Website to reproduce, transmit, display, or
                        distribute copyrighted material in any medium or via any
                        method without Company`s express written permission;
                    </li>
                    <li>
                        copy, store, edit, change, prepare any derivative work
                        of, or alter in any way any of the content provided on
                        this Website; or
                    </li>
                    <li>
                        use our Website in any way that violates the terms of
                        this agreement.
                    </li>
                </ol>
                <p>
                    The Website is owned by NDL and is protected by United
                    States copyright laws and international treaty provisions.
                    You will not sub-license, assign, or transfer the license
                    granted to you under this agreement. Any attempt to
                    sub-license, assign, or transfer any of the rights, duties,
                    or obligations in violation of the provisions of this
                    agreement is void.
                </p>
                <h3>3. Other restrictions</h3>
                <p>You agree that you will not:</p>
                <ol className="alphabeth-list">
                    <li>
                        impersonate any person or entity, or misrepresent your
                        affiliation with any other person or entity;
                    </li>
                    <li>
                        engage in spamming, flooding, harvesting of email
                        addresses or other personal information, spidering,
                        screen scraping, database scraping, or any other
                        activity with the purpose of obtaining lists of users or
                        any other information, including, specifically, property
                        listings available through the Website;
                    </li>
                    <li>
                        send chain letters or pyramid schemes via the Website;
                    </li>
                    <li>
                        attempt to gain unauthorized access to other computer
                        systems through the Website; or
                    </li>
                    <li>
                        use the Website in any manner that could damage,
                        disable, overburden, or impair the Website or interfere
                        with any other party`s use and enjoyment of the Website.
                    </li>
                </ol>
                <h3>4. Copyrights and other intellectual property</h3>
                <h4>4.1.</h4>
                <p>
                    As between you and Company, you acknowledge that Company
                    owns or has a license to all title and copyrights in and to
                    the content provided on this Website, including trademarks.
                    All title and intellectual property rights in and to any
                    licensed content provided on this Website is the property of
                    the respective content owners; and may be protected by
                    applicable copyright, trademark or other intellectual
                    property laws and treaties, and subject to use restrictions
                    under such laws or treaties.
                </p>
                <h4>4.2.</h4>
                <p>
                    Photos herein are the property of their respective owners
                    and use of these images without the express written consent
                    of the owner is prohibited.
                </p>
                <h3>5. Third-party trademarks</h3>
                <p>
                    The Website may contain third-party trademarks, service
                    marks, graphics, and logos. You are not granted any right or
                    license with respect to any intellectual property of any
                    third party.
                </p>
                <h3>6. Privacy policy</h3>
                <p>
                    Unless otherwise addressed in this agreement, your use of
                    this Website is subject to Company’s Online Privacy Policy,
                    which is incorporated by reference and made a part of this
                    agreement. It is important that you read and understand the
                    terms of Company’s Online Privacy Policy. Company may
                    cooperate with and disclose information (including your
                    account information) to any authority, government official,
                    or third party, without giving any notice to you, in
                    connection with any investigation, proceeding, or claim
                    arising from an asserted illegal action or infringement
                    whether related or unrelated to your use or misuse of this
                    Website. It may also share your contact information with
                    third parties that may offer goods and services that may be
                    of interest to you.
                </p>
                <h3>7. Submissions or other information</h3>
                <p>
                    If you submit to us or post on the Website any property
                    listing, testimonial, comment, review, suggestion, or any
                    work of authorship (collectively a “submission”), including,
                    without limitation, submissions about any of our products or
                    services, such submission will not be confidential or
                    secret, and may be used by us in any manner. Please do not
                    submit or send any submission to us that you consider
                    contains confidential or proprietary information. No
                    submission sent to us will be considered or treated as
                    confidential information. We do not prescreen submissions,
                    and we will have no obligation to read any particular
                    submission submitted or sent to us. By submitting or sending
                    a submission to us, you:
                </p>
                <ol>
                    <li>
                        represent and warrant that the submission is original to
                        you, that no other party has any rights thereto, and
                        that any “moral rights” in such submission have been
                        waived; and
                    </li>
                    <li>
                        grant us a royalty-free, unrestricted, worldwide,
                        perpetual, irrevocable, non-exclusive and fully
                        transferable, and assignable and sub-licensable right;
                        and license to use, reproduce, publish, distribute,
                        display, translate, summarize, modify, and adapt such
                        submission (in whole or in part); and/or to incorporate
                        it into other works in any form, media, or technology
                        now known or later developed, in our sole discretion,
                        with or without your name.
                    </li>
                </ol>
                <h3>8. Disclaimers</h3>
                <h4>8.1.</h4>
                <p>
                    The Website (including all of its content) is provided to
                    you “as is.” Any use of this Website is at your own risk. To
                    the maximum extent permitted by applicable law, Company
                    disclaims all warranties, either express or implied,
                    including but not limited to implied warranties of
                    merchantability, fitness for a particular purpose, title,
                    and non-infringement. Company makes no representations,
                    warranties, or guarantees that this site will be free from
                    loss, destruction, damage, corruption, attack, viruses,
                    interference, hacking, or other security intrusion, and
                    Company disclaims any liability relating thereto.
                </p>
                <h4>8.2.</h4>
                <p>
                    Company makes no representations, warranties, or guarantees
                    that the use or results of the use of the Website (including
                    all of its content) is or will be accurate, reliable,
                    current, uninterrupted, or without errors. Without prior
                    notice, Company may modify, suspend, or discontinue any
                    aspect or feature of this Website or your use of this
                    Website. If Company elects to modify, suspend, or
                    discontinue the Website, it will not be liable to you or any
                    third party.
                </p>
                <h4>8.3.</h4>
                <p>
                    All property listing information which may be contained
                    within this Website is subject to the possibility of errors
                    or omissions; change of price, rental, or other conditions;
                    prior sale, lease, or financing; or withdrawal without
                    notice. Any projections, opinions, assumptions, or estimates
                    contained within this Website are for example only, and such
                    projections, opinions, assumptions, or estimates may not
                    represent the current or future performance of a listed
                    property. You and your tax and legal advisors should conduct
                    your own investigation of any property listed on this
                    Website and of any contemplated transaction concerning any
                    property listed on this Website.
                </p>
                <h4>8.4.</h4>
                <p>
                    You acknowledge that your submission of any information to
                    us is at your own risk. Company does not assume any
                    liability to you with regard to any loss or liability
                    relating to such information in any way.
                </p>
                <h4>8.5.</h4>
                <p>
                    Some of the content, products, and services available
                    through the Company Website may include materials that
                    belong to third parties. You acknowledge that Company
                    assumes no responsibility for such content, products, or
                    services.
                </p>
                <h3>9. Limitation of liability</h3>
                <p>
                    In no event will Company be liable to you for indirect,
                    general, special, incidental, consequential, exemplary, or
                    other damages (including, without limitation, damages for
                    loss of profits, business interruption, corruption of files,
                    loss of business information, or any other pecuniary loss),
                    even if Company has been advised of the possibility of such
                    damages. No oral or written information or advice given by
                    Company or others will create a warranty, and neither you
                    nor any third party may rely on any such information or
                    advice. Some jurisdictions do not allow the exclusion of
                    implied warranties or limitations, or the exclusion of
                    liability for incidental or consequential damages, so the
                    above exclusions and limitations may or may not apply to
                    you.
                </p>
                <h3>10. Indemnity</h3>
                <p>
                    You will indemnify and hold harmless Company, its directors,
                    officers, employees, affiliates, agents, contractors, and
                    licensors with respect to any suits or claims arising from:
                </p>
                <ol>
                    <li>
                        your breach of this agreement, including but not limited
                        to any infringement by you of the copyright or
                        intellectual property rights of any third party; or
                    </li>
                    <li>your use or misuse of the Website.</li>
                </ol>
                <h3>11. General</h3>
                <h4>11.1.</h4>
                <p>
                    You will be responsible for providing the dial-up, DSL,
                    cable modem, or other form of internet access, and any other
                    hardware and software necessary to access and use the
                    Website.
                </p>
                <h4>11.2.</h4>
                <p>
                    Company complies with all applicable laws, including but not
                    limited to privacy laws and individual state laws. You agree
                    that you will comply with all such laws applicable to you.
                </p>
                <h4>11.3.</h4>
                <p>
                    Company or its business partners may present advertisements
                    or promotional materials on or through the Website. Your
                    dealings with, or participation in promotions of, any
                    third-party advertisers on or through the Website are solely
                    between you and such third parties, and your participation
                    is subject to the terms and conditions associated with that
                    advertisement or promotion. You agree that Company is not
                    responsible or liable for any loss or damage of any sort
                    incurred as the result of any such dealings or as the result
                    of the presence of such third-party materials on the
                    Website.
                </p>
                <h4>11.4.</h4>
                <p>
                    If you know of, or suspect, copyright infringement, please
                    send a notice to NDL:{' '}
                    <a href="mailto:Processing@nodocloans.com">
                        Processing@nodocloans.com
                    </a>
                    .The notice must contain all of the information set forth in
                    section 512(c)(3)(a) of the U.S. Copyright Act, 17 U.S.C. §
                    101 et seq.
                </p>
                <h4>11.5.</h4>
                <p>
                    This agreement will be governed by the laws of the state of
                    New York. The exclusive jurisdiction for any claim, action,
                    or dispute with Company, or relating in any way to your use
                    of the Website, will be in the state and federal courts of
                    the state of New York, and the venue for the adjudication or
                    disposition of any such claim, action, or dispute will be in
                    New York.
                </p>
                <h4>11.6.</h4>
                <p>
                    Company may send notices to you with respect to your use of
                    the Website by sending an email message to the email address
                    listed in your account information, by sending a letter via
                    U.S. mail to the contact address listed in your account
                    information, or by posting a note on the site when you
                    access your account. You agree that we may provide notice to
                    you through such means. Unless otherwise stated in the
                    notices, the notices will become effective immediately.
                </p>
                <h4>11.7. General contact information.</h4>
                <p>
                    For questions regarding this Website or your use of this
                    Website, please email us at{' '}
                    <a href="mailto:Processing@nodocloans.com">
                        Processing@nodocloans.com
                    </a>
                    .
                </p>
                <p>
                    You acknowledge that you have read this agreement,
                    understand it, and will be bound by its terms and
                    conditions. You further acknowledge that this agreement
                    represents the complete and exclusive statement of the
                    agreement between us, and that it supersedes any proposal or
                    prior agreement, oral or written, as well as any other
                    communications between us relating to the subject matter of
                    this agreement.
                </p>
                <h2>Advertiser Disclosure</h2>
                <p>Company is not affiliated with any government agency.</p>
                <p>
                    Loan offers that appear on this Website or on websites to
                    which we may direct visitors are from companies or
                    affiliates from which Company may receive compensation. This
                    compensation may impact how and when we may direct visitors
                    to particular companies. Company does not include all
                    business and multi-family loan companies or all types of
                    offers available in the marketplace.
                </p>
                <p>
                    We offer a loan connecting service for multifamily,
                    commercial, business and other related loans products. While
                    third parties typically pay for the services that we
                    provide, if you will ever be charged for such services, we
                    will advise you ahead of time and you will be required to
                    separately agree to such an arrangement.
                </p>
                <p>
                    We promptly send loan requests to a Loan Agent or Affiliate
                    Partner. The Loan Agent or Lender Partner will review this
                    request. If they determine that you may qualify for a
                    particular loan, they then connect you with a specific
                    lender in their network and assist you in submitting your
                    loan application to the lender. The lender you are connected
                    with (if any) will only be disclosed after you submit your
                    request.
                </p>
                <p>
                    Submitting a request on this Website and submitting the
                    application through a Loan Agent or Affiliate Partner are
                    free to you (unless disclosed and agreed to ahead of time).
                    Your lender will compensate our Loan Agent or Affiliate
                    Partner for acting as a loan agent. Loan Agent or Lender
                    Partner will compensate us for advertising their loan agent
                    and other business loan services. Many factors impact which
                    lender, if any, you may be connected with. How much the
                    lender is willing to pay Loan Agent or Lender Partner may
                    have an impact. Loan Agent or Lender Partner does not have
                    access to all lenders offering loans. However, the loan
                    terms for HUD Loans are always the same no matter what
                    lender you apply with.
                </p>
                <p>
                    If Loan Agent or Lender Partner determines that you are not
                    eligible for a particular loan, you may receive offers for
                    different types of loans. You are at no obligation to accept
                    any loan offers made to you. Many factors impact which of
                    these lenders, if any, may offer you a loan. How much they
                    are willing to pay may have an impact. Always carefully
                    review the details of loan offers shown to you and compare
                    it with other options.
                </p>
                <p>
                    There is no one-size fits all business or multi-family loan.
                    Rather there are several types that will likely be offered
                    and or discussed with you upon completing your request. The
                    companies to which we may connect you determine the
                    underwriting criteria necessary for approval. You should
                    review each such company’s complete terms and conditions to
                    determine which funding option works for your business’s
                    financial situation. There is never a guarantee your
                    business will be approved for credit or that upon approval
                    your business will qualify for the advertised rates, fees,
                    or terms shown. Lender terms and conditions will apply and
                    all products may not be available in all states. Ask your
                    loan representative for details.
                </p>
                <p>
                    This disclosure is not intended to fully and completely
                    describe all the advertising activities of this Website or
                    of its affiliates and partners.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
