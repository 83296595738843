import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermInterestReserve extends BaseValueObject {
    _value: any;
    _name: string;
    static NONE = 1;
    static MONTHS_1 = 2;
    static MONTHS_2 = 3;
    static MONTHS_3 = 4;
    static MONTHS_4 = 5;
    static MONTHS_5 = 6;
    static MONTHS_6 = 7;
    static MONTHS_7 = 8;
    static MONTHS_8 = 9;
    static MONTHS_9 = 10;
    static MONTHS_10 = 11;
    static MONTHS_11 = 12;
    static MONTHS_12 = 13;
    static MONTHS_13 = 14;
    static MONTHS_14 = 15;
    static MONTHS_15 = 16;
    static MONTHS_16 = 17;
    static MONTHS_17 = 18;
    static MONTHS_18 = 19;

    static NONE_NAME = 'None';
    static MONTHS_1_NAME = '1 month';
    static MONTHS_2_NAME = '2 months';
    static MONTHS_3_NAME = '3 months';
    static MONTHS_4_NAME = '4 months';
    static MONTHS_5_NAME = '5 months';
    static MONTHS_6_NAME = '6 months';
    static MONTHS_7_NAME = '7 months';
    static MONTHS_8_NAME = '8 months';
    static MONTHS_9_NAME = '9 months';
    static MONTHS_10_NAME = '10 months';
    static MONTHS_11_NAME = '11 months';
    static MONTHS_12_NAME = '12 months';
    static MONTHS_13_NAME = '13 months';
    static MONTHS_14_NAME = '14 months';
    static MONTHS_15_NAME = '15 months';
    static MONTHS_16_NAME = '16 months';
    static MONTHS_17_NAME = '17 months';
    static MONTHS_18_NAME = '18 months';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableInterestReserve() {
        return [
            LoanTermInterestReserve.NONE,
            LoanTermInterestReserve.MONTHS_1,
            LoanTermInterestReserve.MONTHS_2,
            LoanTermInterestReserve.MONTHS_3,
            LoanTermInterestReserve.MONTHS_4,
            LoanTermInterestReserve.MONTHS_5,
            LoanTermInterestReserve.MONTHS_6,
            LoanTermInterestReserve.MONTHS_7,
            LoanTermInterestReserve.MONTHS_8,
            LoanTermInterestReserve.MONTHS_9,
            LoanTermInterestReserve.MONTHS_10,
            LoanTermInterestReserve.MONTHS_11,
            LoanTermInterestReserve.MONTHS_12,
            LoanTermInterestReserve.MONTHS_13,
            LoanTermInterestReserve.MONTHS_14,
            LoanTermInterestReserve.MONTHS_15,
            LoanTermInterestReserve.MONTHS_16,
            LoanTermInterestReserve.MONTHS_17,
            LoanTermInterestReserve.MONTHS_18
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermInterestReserve.NONE:
                this._name = LoanTermInterestReserve.NONE_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_1:
                this._name = LoanTermInterestReserve.MONTHS_1_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_2:
                this._name = LoanTermInterestReserve.MONTHS_2_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_3:
                this._name = LoanTermInterestReserve.MONTHS_3_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_4:
                this._name = LoanTermInterestReserve.MONTHS_4_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_5:
                this._name = LoanTermInterestReserve.MONTHS_5_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_6:
                this._name = LoanTermInterestReserve.MONTHS_6_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_7:
                this._name = LoanTermInterestReserve.MONTHS_7_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_8:
                this._name = LoanTermInterestReserve.MONTHS_8_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_9:
                this._name = LoanTermInterestReserve.MONTHS_9_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_10:
                this._name = LoanTermInterestReserve.MONTHS_10_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_11:
                this._name = LoanTermInterestReserve.MONTHS_11_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_12:
                this._name = LoanTermInterestReserve.MONTHS_12_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_13:
                this._name = LoanTermInterestReserve.MONTHS_13_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_14:
                this._name = LoanTermInterestReserve.MONTHS_14_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_15:
                this._name = LoanTermInterestReserve.MONTHS_15_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_16:
                this._name = LoanTermInterestReserve.MONTHS_16_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_17:
                this._name = LoanTermInterestReserve.MONTHS_17_NAME;
                break;
            case LoanTermInterestReserve.MONTHS_18:
                this._name = LoanTermInterestReserve.MONTHS_18_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableInterestReserve().indexOf(this._value) < 0) {
            throw new Exception('Invalid Interest Reserve', 400);
        }
    }
}
