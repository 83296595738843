import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BlockSingleLoan from '../components/blocks/BlockSingleLoan';
import LoanService from '../domain/loan/LoanService';
import LoanViewMapper, {
    SingleLoanDataType
} from '../domain/loan/LoanViewMapper';
import NotificationController from '../domain/notification/NotificationController';
import InvalidToastMessage from '../domain/notification/notificationTemplate/InvalidToastMessage';
import TypePositiveInteger from '../domain/valueObject/TypePositiveInteger';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { LoanTermService } from '../domain/loanTerm/LoanTermService';
import { LoanTermRepository } from '../domain/loanTerm/LoanTermRepository';
import { LoanTermGateway } from '../domain/loanTerm/LoanTermGateway';
import { LoanTermFactory } from '../domain/loanTerm/LoanTermFactory';
import LoanTermViewMapper, {
    LoanTermViewData
} from '../domain/loanTerm/LoanTermViewMapper';

const SingleLoan = () => {
    const [loan, setLoan] = useState<SingleLoanDataType | null>(null);
    const [loanTerm, setLoanTerm] = useState<LoanTermViewData | null>(null);

    const [address, setAddress] = useState('');
    const { loanId }: any = useParams();
    useDocumentTitle(`Loan ${address}`);

    const getLoanTermById = async (loanTermId: number, loanType: number) => {
        try {
            const loanTerm = await new LoanTermRepository(
                new LoanTermGateway(),
                new LoanTermFactory()
            ).getLoanTerm(new TypePositiveInteger(loanTermId));
            const loanTermData = LoanTermViewMapper.map(loanTerm, loanType);
            setLoanTerm(loanTermData);
        } catch (error) {
            console.log(error);
        }
    };

    const getLoanById = async (loanId: any) => {
        let loan = null;
        try {
            if (loanId !== undefined) {
                loan = await LoanService.getById(
                    new TypePositiveInteger(loanId)
                );
                if (loan.hasLoanTermId()) {
                    getLoanTermById(loan.loanTermId.value, loan.loanType.value);
                }
                const loanMapped = LoanViewMapper.map(loan);
                setLoan(loanMapped);
                const address =
                    loanMapped.address !== null
                        ? `| ${loanMapped.address.streetName}, ${loanMapped.address.city}, ${loanMapped.address.state}, ${loanMapped.address.zip}`
                        : '';
                setAddress(address);
            }
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage(
                    'An error occured while trying to fetch the loan. Please try again'
                )
            );
            // navigate('/not-found');
        }
    };

    useEffect(() => {
        getLoanById(loanId);
    }, []);

    return (
        <div className="single-loan">
            <BlockSingleLoan loan={loan} loanTerm={loanTerm} />{' '}
        </div>
    );
};

export default SingleLoan;
