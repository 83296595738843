import React from 'react';
import { Link } from 'react-router-dom';
import IconCircleQuestion from '../icons/IconCircleQuestion';
import IconArrowSquare from '../icons/IconArrowSquare';

const MainFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className="footer">
                <span>&copy; {currentYear} NDL App</span>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <a
                    href="mailto:support@ndlapp.com"
                    className="footer__icon-help"
                >
                    <IconCircleQuestion />
                    Help
                    <IconArrowSquare />
                </a>
            </div>
        </footer>
    );
};

export default MainFooter;
