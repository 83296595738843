import { useForm } from 'react-hook-form';
import BlockStepsButtons from '../BlockStepsButtons';
import PartRadioButton from '../../parts/PartRadioButton';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import { useEffect } from 'react';

interface RentalPropertyExperienceProps extends NewLoanApplicationStepsProps {}

const RentalPropertyExperience = ({
    nextStep,
    prevStep,
    newLoanEntity
}: RentalPropertyExperienceProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object().shape({
                rentalPropertyExperience: yup
                    .number()
                    .required('Please choose one option.')
            })
        ),
        shouldFocusError: false
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.TRANSACTION_TYPE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.CREDIT_SCORE);
    };

    useEffect(() => {
        newLoanEntity.hasPropertyCountExperience() &&
            setValue(
                'rentalPropertyExperience',
                newLoanEntity.propertyCountExperience.value
            );
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    What is your real estate experience?
                </h1>
                <p className="login__subtitle login__subtitle--middle">
                    Real Estate Experience
                </p>
                <div className="login__box">
                    <PartRadioButton
                        control={control}
                        name="rentalPropertyExperience"
                        errors={errors}
                        options={[
                            { label: '0 Properties', value: 1 },
                            { label: '1-2 Properties', value: 2 },
                            { label: '3-4 Properties', value: 3 },
                            { label: '5-9 Properties', value: 4 },
                            { label: '10-49 Properties', value: 5 },
                            { label: '50+ Properties', value: 6 }
                        ]}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RentalPropertyExperience;
