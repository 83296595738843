import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CSRFTokenService } from '../../domain/CSRFToken/CSRFTokenService';
import BaseValueObject from '../../domain/valueObject/BaseValueObject';
import Collection from '../../domain/valueObject/Collection';
import TypeString from '../../domain/valueObject/TypeString';
import {
    AxiosRequestInterface,
    ErrorResponse
} from '../../interfaces/interfacesIndex';
import { triggerLogout } from '../../store/auth';
import store from '../../store/storeIndex';
import ValidationException from '../exceptions/ValidationException';

export const API_CLIENT_CONTENT_TYPE_JSON = 1;
export const API_CLIENT_CONTENT_TYPE_FORM_DATA = 2;

export default class APIClient {
    _axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        withCredentials: true,
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': '*'
        }
    });

    _loginPromise: Promise<any> | undefined;

    constructor() {
        const onRequest = (
            config: AxiosRequestInterface
        ): AxiosRequestInterface => {
            if (config.data instanceof Collection)
                switch (config.method) {
                    case 'post':
                        if (
                            config?.contentType === API_CLIENT_CONTENT_TYPE_JSON
                        )
                            config.data = this._dataToJson(config.data);
                        else {
                            config.data = this._dataToFormData(config.data);
                        }
                        break;
                    case 'put':
                    case 'delete':
                    case 'get':
                        config.url += this._dataToQueryString(config.data);
                        break;
                }

            const token = localStorage.getItem('token');
            if (token && config.headers) {
                config.headers['X-CSRF-TOKEN'] = token;
            }
            return config;
        };

        const onRequestError = (error: AxiosError): Promise<AxiosError> => {
            // ErrorLogger.log(error, "interceptor onRequestError");
            return Promise.reject(error);
        };

        const onResponse = (response: AxiosResponse): any => {
            return response.data ? response.data : undefined;
        };

        const onResponseError = async (
            error: ErrorResponse
        ): Promise<ErrorResponse> => {
            const errData = error.response?.data.message;
            let token: TypeString;
            switch (errData) {
                case 'CSRF_ERROR':
                    token = await CSRFTokenService.getToken();
                    localStorage.setItem('token', token.value);
                    if (error.config.headers)
                        error.config.headers['X-CSRF-TOKEN'] = token.value;

                    return this._axiosClient.request(error.config);
                // JWT Session has expired
                case 'UNAUTHORIZED': {
                    let oldToken = undefined;
                    if (error.response?.config.headers)
                        oldToken =
                            error.response?.config.headers['X-CSRF-TOKEN'];
                    token = await CSRFTokenService.getToken();

                    if (oldToken === token.value) {
                        store.dispatch(triggerLogout());
                    }
                    localStorage.removeItem('token');
                    if (error.config.headers)
                        error.config.headers['X-CSRF-TOKEN'] = token.value;
                    break;
                }
                // break;
                case 'VALIDATION_EXCEPTION':
                    localStorage.removeItem('token');
                    throw new ValidationException(
                        error.response?.data.message,
                        error.response?.data.code,
                        error.response?.data.data
                    );
            }
            return Promise.reject(error);
        };

        this._axiosClient.interceptors.request.use(onRequest, onRequestError);
        this._axiosClient.interceptors.response.use(
            onResponse,
            onResponseError
        );
    }

    get(endpoint: string, data?: Collection): any {
        return this._axiosClient.get(endpoint, {
            data: data
        });
    }

    post(
        endpoint: string,
        data?: Collection,
        config?: AxiosRequestInterface
    ): any {
        const axiosConfig: AxiosRequestConfig = { ...config };
        return config
            ? this._axiosClient.post(endpoint, data, axiosConfig)
            : this._axiosClient.post(endpoint, data);
    }

    put(endpoint: string, data?: Collection): any {
        return this._axiosClient.put(endpoint, data);
    }

    delete(endpoint: string, data?: Collection): any {
        return this._axiosClient.delete(endpoint, { data: data });
    }

    _dataToFormData(data: Collection) {
        const formData = new FormData();
        data.forEach((value: any, key: any) => {
            if (value instanceof Collection) {
                // multilevel support
                value.forEach((subValue: any, subKey: any) => {
                    const parsedValue =
                        subValue instanceof BaseValueObject
                            ? subValue.value
                            : subValue;

                    formData.append(`${key}[${subKey}]`, parsedValue);
                });
            } else {
                // single level support
                const parsedValue =
                    value instanceof BaseValueObject ? value.value : value;

                formData.append(key, parsedValue);
            }
        });

        return formData;
    }

    _dataToJson(data: Collection) {
        return data.map((item: any) => {
            switch (true) {
                case item instanceof Collection:
                    return this._dataToJson(item);
                case item instanceof BaseValueObject:
                    return item.value;
                default:
                    return item;
            }
        }).items;
    }

    _arrayToQueryString(
        array: Array<any>,
        resultingItems: Array<any>,
        key: any
    ) {
        let index = 0;
        array.forEach((value) => {
            this._valueToQueryString(
                value,
                resultingItems,
                `${key}[${index++}]`
            );
        });
    }

    _associativeCollectionToQueryString(
        collection: Collection,
        resultingItems: Array<any>,
        key: any
    ) {
        collection.forEach((value: any, parameterKey: any) => {
            this._valueToQueryString(
                value,
                resultingItems,
                `${key}[${parameterKey}]`
            );
        });
    }

    _collectionToQueryString(
        collection: Collection,
        resultingItems: Array<any>,
        key: any
    ) {
        collection.isAssociative()
            ? this._associativeCollectionToQueryString(
                  collection,
                  resultingItems,
                  key
              )
            : this._arrayToQueryString(collection.items, resultingItems, key);
    }

    _valueToQueryString(value: any, resultingItems: Array<any>, key: any) {
        if (Array.isArray(value))
            this._arrayToQueryString(value, resultingItems, key);
        else if (value instanceof Collection)
            this._collectionToQueryString(value, resultingItems, key);
        else {
            resultingItems.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    value instanceof BaseValueObject ? value.value : value
                )}`
            );
        }
    }

    _dataToQueryString(data: Collection) {
        const queryString: Array<any> = [];
        data.forEach((value: any, key: any) => {
            this._valueToQueryString(value, queryString, key);
        });
        return '?' + queryString.join('&');
    }
}

export const API = new APIClient();
