import PartInputField from '../../parts/PartInputField';
import PartRadioDotted from '../../parts/PartRadioDotted';
import ArrowRightLong from '../../icons/IconArrowRightlong';
import InvalidToastMessage from '../../../domain/notification/notificationTemplate/InvalidToastMessage';
import SuccessToastTemplate from '../../../domain/notification/notificationTemplate/SuccessToastTemplate';
import Collection from '../../../domain/valueObject/Collection';
import NotificationController from '../../../domain/notification/NotificationController';
import { useForm } from 'react-hook-form';
import { LoanTermFormProps } from './LoanTermTab';
import { yupResolver } from '@hookform/resolvers/yup';
import { loanTerm2ValidationSchema } from '../../../utils/validationSchemas';
import { LoanTermService } from '../../../domain/loanTerm/LoanTermService';
import { LoanTermFactory } from '../../../domain/loanTerm/LoanTermFactory';
import { useEffect } from 'react';

/*
For following types loan types:
- Multifamilty Bridge
*/

export const LoanTermFormType2 = ({ nextStep, loan }: LoanTermFormProps) => {
    const {
        clearErrors,
        watch,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<any>({
        resolver: yupResolver(loanTerm2ValidationSchema),
        mode: 'onBlur'
    });

    const onSubmit = async (formData: any) => {
        try {
            await new LoanTermService().create(
                LoanTermFactory.makeFromForm(
                    new Collection({
                        ...formData,
                        loan_id: loan.id,
                        loan_type: loan.loanType
                    })
                )
            );
            NotificationController.notify(
                new SuccessToastTemplate('Loan terms updated')
            );
            nextStep();
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage('Error updating loan terms')
            );
        }
    };

    const loanAmount = watch('loan_terms-total_loan_amount');

    useEffect(() => {
        clearErrors('broker_fee_percentage');
        clearErrors('origination_fee_percentage');
    }, [loanAmount]);

    return (
        <div className="loan-terms__tab-content">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="loan-terms__tab-form">
                    <div className="loan-terms__tab-content-box">
                        <div className="loan-terms__tab-content-left">
                            <h2 className="loan-terms__tab-content-left-title">
                                Loan terms
                            </h2>
                            <p className="loan-terms__tab-content-left-text"></p>
                        </div>
                        <div className="loan-terms__tab-content-right">
                            <div className="loan-terms__tab-content-form-box">
                                <PartInputField
                                    name="loan_terms-initial_loan_amount"
                                    label="Initial Loan Amount"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="loan_terms-initial_loan_amount_purchase_price"
                                    label="Initial Loan Amount/Purchase Price"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                />
                                <PartInputField
                                    name="loan_terms-construction_hold_back"
                                    label="Construction Holdback"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="loan_terms-construction_hold_back_renovation_budget"
                                    label="Construction Holdback/Renovation Budget"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                />
                                <PartInputField
                                    name="loan_terms-total_loan_amount"
                                    label="Total Loan Amount"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                />

                                <PartRadioDotted
                                    name="loan_terms-recourse_guaranty_structure"
                                    control={control}
                                    errors={errors}
                                    label="Recourse/Guaranty Structure"
                                    options={[
                                        {
                                            label: 'Recourse',
                                            value: 1
                                        },
                                        {
                                            label: 'Non Recourse',
                                            value: 2
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loan-terms__tab-form">
                    <div className="loan-terms__tab-content-box">
                        <div className="loan-terms__tab-content-left">
                            <h2 className="loan-terms__tab-content-left-title">
                                Pricing
                            </h2>
                            <p className="loan-terms__tab-content-left-text"></p>
                        </div>
                        <div className="loan-terms__tab-content-right">
                            <div className="loan-terms__tab-content-form-box">
                                <PartInputField
                                    name="pricing-interest_rate"
                                    label="Interest Rate"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="origination_fee_percentage"
                                    label="Origination Fee"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="pricing-exit_fee"
                                    label="Exit Fee"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="broker_fee_percentage"
                                    label="Broker Fee"
                                    control={control}
                                    errors={errors}
                                    isPercentageAmount
                                    isRequired
                                />
                                <PartInputField
                                    name="underwriting_fee"
                                    label="Underwriting Fee"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                />
                                <PartInputField
                                    name="processing_fee"
                                    label="Processing Fee"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                />
                                <PartInputField
                                    name="legal_fee"
                                    label="Legal Fee"
                                    control={control}
                                    errors={errors}
                                    isDollarAmount
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loan-terms__tab-form">
                    <div className="loan-terms__tab-content-box">
                        <div className="loan-terms__tab-content-left">
                            <h2 className="loan-terms__tab-content-left-title">
                                Interest Mechanics
                            </h2>
                            <p className="loan-terms__tab-content-left-text"></p>
                        </div>
                        <div className="loan-terms__tab-content-right">
                            <div className="loan-terms__tab-content-form-box">
                                <PartRadioDotted
                                    name="interest_mechanics-term"
                                    control={control}
                                    errors={errors}
                                    label="Term (Duration)"
                                    isRequired
                                    options={[
                                        {
                                            label: '12 months',
                                            value: 1
                                        },
                                        {
                                            label: '18 months',
                                            value: 2
                                        },
                                        {
                                            label: '24 months',
                                            value: 3
                                        }
                                    ]}
                                />
                                <PartRadioDotted
                                    label="Pre-Payment Penalty"
                                    name="interest_mechanics-pre_payment_penalty"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    options={[
                                        {
                                            label: '0',
                                            value: 7
                                        },
                                        {
                                            label: '3 Months',
                                            value: 8
                                        },
                                        {
                                            label: '6 Months',
                                            value: 9
                                        },
                                        {
                                            label: '12 Months',
                                            value: 10
                                        }
                                    ]}
                                />
                                <PartRadioDotted
                                    name="interest_mechanics-interest_accrual_method"
                                    control={control}
                                    errors={errors}
                                    label="Interest Accrual Method"
                                    isRequired
                                    options={[
                                        {
                                            label: 'Full Boat',
                                            value: 1
                                        },
                                        {
                                            label: 'As disbursed',
                                            value: 2
                                        }
                                    ]}
                                />
                                <PartRadioDotted
                                    name="interest_mechanics-interest_reserve"
                                    control={control}
                                    errors={errors}
                                    label="Interest Reserve"
                                    isRequired
                                    options={[
                                        {
                                            label: 'None',
                                            value: 1
                                        },
                                        {
                                            label: '1 month',
                                            value: 2
                                        },
                                        {
                                            label: '2 months',
                                            value: 3
                                        },
                                        {
                                            label: '3 months',
                                            value: 4
                                        },
                                        {
                                            label: '4 months',
                                            value: 5
                                        },
                                        {
                                            label: '5 months',
                                            value: 6
                                        },
                                        {
                                            label: '6 months',
                                            value: 7
                                        },
                                        {
                                            label: '7 months',
                                            value: 8
                                        },
                                        {
                                            label: '8 months',
                                            value: 9
                                        },
                                        {
                                            label: '9 months',
                                            value: 10
                                        },
                                        {
                                            label: '10 months',
                                            value: 11
                                        },
                                        {
                                            label: '11 months',
                                            value: 12
                                        },
                                        {
                                            label: '12 months',
                                            value: 13
                                        },
                                        {
                                            label: '13 months',
                                            value: 14
                                        },
                                        {
                                            label: '14 months',
                                            value: 15
                                        },
                                        {
                                            label: '15 months',
                                            value: 16
                                        },
                                        {
                                            label: '16 months',
                                            value: 17
                                        },
                                        {
                                            label: '17 months',
                                            value: 18
                                        },
                                        {
                                            label: '18 months',
                                            value: 19
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loan-terms__tab-form">
                    <div className="loan-terms__tab-content-box">
                        <div className="loan-terms__tab-content-left">
                            <h2 className="loan-terms__tab-content-left-title">
                                Closing Conditions
                            </h2>
                            <p className="loan-terms__tab-content-left-text"></p>
                        </div>
                        <div className="loan-terms__tab-content-right">
                            <div className="loan-terms__tab-content-form-box">
                                <PartInputField
                                    label="LTV"
                                    name="cc-ltv"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isPercentageAmount
                                />
                                <PartInputField
                                    label="LTARV"
                                    name="cc-ltarv"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isPercentageAmount
                                />
                                <PartInputField
                                    label="LTFC"
                                    name="cc-ltfc"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isPercentageAmount
                                />
                                <PartInputField
                                    label="Min Credit Score"
                                    name="cc-min_credit_score"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isNumberFormat
                                />
                                <PartInputField
                                    label="Verified Properties"
                                    name="cc-track_record"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isNumberFormat
                                />
                                <PartInputField
                                    label="Liquidity"
                                    name="cc-liquidity"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isDollarAmount
                                />
                                <PartInputField
                                    label="Net worth"
                                    name="cc-net_worth"
                                    control={control}
                                    errors={errors}
                                    isRequired
                                    isDollarAmount
                                />
                            </div>
                            <div className="loan-terms__tab-submit-wrapp">
                                <div className="loan-terms__button-submit-btn">
                                    <input
                                        type="submit"
                                        className="loan-terms__button-submit"
                                        value={'Save and Submit'}
                                    />
                                    <ArrowRightLong />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
