import UserEntity from './UserEntity';
import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';
import UserRoleType from '../credentials/valueObject/UserRoleType';

export default class UserFactory {
    static reconstitute(data: Collection) {
        const user = new UserEntity();
        if (data.has('id')) user.setId(new TypePositiveInteger(data.get('id')));
        if (data.has('hs_id'))
            user.setId(new TypePositiveInteger(data.get('hs_id')));
        if (data.has('first_name'))
            user.setFirstName(new TypeString(data.get('first_name')));
        if (data.has('last_name'))
            user.setLastName(new TypeString(data.get('last_name')));
        if (data.has('email')) user.setEmail(new TypeString(data.get('email')));
        if (data.has('type')) user.setType(new UserRoleType(data.get('type')));
        if (data.has('phone')) user.setPhone(new TypeString(data.get('phone')));

        return user;
    }
}
