import { useForm } from 'react-hook-form';
import BlockStepsButtons from '../BlockStepsButtons';
import PartRadioButton from '../../parts/PartRadioButton';
import {
    DEAL_TRANSACTION_TYPE_PURCHASE,
    DEAL_TRANSACTION_TYPE_REFINANCE,
    FIELD_NAME_DEAL_TRANSACTION_TYPE_PURCHASE,
    FIELD_NAME_DEAL_TRANSACTION_TYPE_REFINANCE
} from '../../../domain/loan/valueObject/DealTransactionType';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import { useEffect } from 'react';

interface TransactionTypeDetailsProps extends NewLoanApplicationStepsProps {}

const TransactionTypeDetails = ({
    nextStep,
    prevStep,
    newLoanEntity
}: TransactionTypeDetailsProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                transactionType: yup
                    .number()
                    .required('Please choose one option.')
            })
        )
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection({ transactionType: data.transactionType })
        );

        nextStep(
            data.transactionType == DEAL_TRANSACTION_TYPE_PURCHASE
                ? NewLoanSteps.TRANSACTION_PURCHASE_PRICE
                : NewLoanSteps.DATE_OF_PURCHASE
        );
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.RENTAL_PROPERTY_EXPERIENCE);
    };

    useEffect(() => {
        newLoanEntity.hasTransactionType() &&
            setValue('transactionType', newLoanEntity.transactionType.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    Is this for a purchase or refinance?
                </h1>
                <p className="login__subtitle">Transaction Type</p>
                <div className="login__box">
                    <PartRadioButton
                        control={control}
                        name="transactionType"
                        errors={errors}
                        options={[
                            {
                                label: FIELD_NAME_DEAL_TRANSACTION_TYPE_PURCHASE,
                                value: DEAL_TRANSACTION_TYPE_PURCHASE
                            },
                            {
                                label: FIELD_NAME_DEAL_TRANSACTION_TYPE_REFINANCE,
                                value: DEAL_TRANSACTION_TYPE_REFINANCE
                            }
                        ]}
                    />

                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionTypeDetails;
