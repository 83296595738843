import { API } from '../../utils/http/ApiClient';

interface Token {
    csrf_token: string;
}
export default class CSRFTokenGateway {
    getToken(): Promise<Token> {
        return API.get('CSRF/GetToken');
    }
}
