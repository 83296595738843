import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import LoanEntity from './LoanEntity';
import LoanFactory from './LoanFactory';
import LoanGateway from './LoanGateway';
import LoanRAMGateway from './LoanRAMGateway';
import LoanRepository from './LoanRepository';
import LoanStatusType from './valueObject/LoanStatusType';

export default class LoanService {
    static getById(id: TypePositiveInteger): Promise<LoanEntity> {
        return new LoanRepository(new LoanGateway(), new LoanFactory()).getLoan(
            id
        );
    }

    static updateStatus(id: TypePositiveInteger, status: TypePositiveInteger) {
        return new LoanGateway().updateStatus(id, status);
    }

    static persistInRAM(loan: LoanEntity) {
        new LoanRAMGateway().persist(loan);
    }
}
