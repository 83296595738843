import Collection from '../valueObject/Collection';
import LoanScrollEntity from './LoanScrollEntity';
import LoanScrollFactory from './LoanScrollFactory';
import LoanScrollGateway from './LoanScrollGateway';

export default class LoanScrollRepository {
    _gateway: LoanScrollGateway;
    _factory: LoanScrollFactory;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async loadNextPage(loanScroll: LoanScrollEntity) {
        const data = await this._gateway.loadNextPage(
            loanScroll.currentPage.value + 1,
            loanScroll.perPage.value,
            loanScroll.sortingRules,
            loanScroll.filterRules
        );
        const nextData = this._factory.reconstitute(new Collection(data));

        loanScroll.totalCount = nextData.totalCount;
        loanScroll.currentCount = nextData.currentCount;
        loanScroll.currentPage = nextData.currentPage;
        loanScroll.perPage = nextData.perPage;
        loanScroll.totalPageCount = nextData.totalPageCount;
        loanScroll.appendMultiple(nextData.items);
    }
}
