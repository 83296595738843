import BaseNotificationTemplate, {
    NOTIFICATION_FORMAT_TOAST
} from './BaseNotificationTemplate';
import IconCheckCircle from '../../../components/icons/IconCheckCircle';
export default class SuccessToastTemplate extends BaseNotificationTemplate {
    constructor(content: string) {
        super(
            'success',
            content,
            5,
            NOTIFICATION_FORMAT_TOAST,
            <IconCheckCircle />
        );
    }
}
