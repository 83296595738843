import LoanEntity from '../loan/LoanEntity';
import { DEAL_STATUS_PENDING } from '../loan/valueObject/LoanStatusType';
import Collection from '../valueObject/Collection';

const NO_INFO = '--';
export default class LoanScrollViewMapper {
    static mapLoans(items: Collection) {
        const loanAmountCurrencyFormatter = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        const loans = items.map((loan: LoanEntity) => {
            const address = loan.hasPropertyAddress()
                ? {
                      streetName: loan.propertyAddress.hasStreet()
                          ? loan.propertyAddress.street.value
                          : undefined,
                      city: loan.propertyAddress.hasCity()
                          ? loan.propertyAddress.city.value
                          : NO_INFO,
                      state: loan.propertyAddress.hasState()
                          ? loan.propertyAddress.state.value
                          : NO_INFO,
                      zip: loan.propertyAddress.hasZip()
                          ? loan.propertyAddress.zip.value
                          : NO_INFO
                  }
                : null;

            const processor = loan.hasProcessor()
                ? loan.processor.hasFirstName() && loan.processor.hasLastName()
                    ? `${loan.processor.firstName.value} ${loan.processor.lastName.value}`
                    : NO_INFO
                : NO_INFO;

            const originator = loan.hasOriginator()
                ? loan.originator.hasFirstName() &&
                  loan.originator.hasLastName()
                    ? `${loan.originator.firstName.value} ${loan.originator.lastName.value}`
                    : NO_INFO
                : NO_INFO;

            const borrower = loan.hasBorrower()
                ? loan.borrower.hasFirstName() && loan.borrower.hasLastName()
                    ? `${loan.borrower.firstName.value} ${loan.borrower.lastName.value}`
                    : NO_INFO
                : NO_INFO;

            return {
                id: loan.loanId.value,
                transactionType: loan.hasTransactionType()
                    ? loan.transactionType.name
                    : NO_INFO,
                loanType: loan.hasLoanType() ? loan.loanType.name : NO_INFO,
                loanAmount: loan.hasLoanAmount()
                    ? loanAmountCurrencyFormatter.format(loan.loanAmount)
                    : NO_INFO,
                unitCount: loan.hasUnits() ? loan.units.value : NO_INFO,
                status: loan.hasLoanStatus()
                    ? loan.loanStatus.value
                    : DEAL_STATUS_PENDING,
                lender: loan.hasLender() ? loan.lender.value : NO_INFO,
                borrower: borrower,
                assetType: loan.hasAssetType() ? loan.assetType.name : NO_INFO,
                messageCount: '1',
                address: address,
                originator: originator,
                processor: processor
            };
        }).items;

        return loans;
    }
}
