import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PartInputPassword from '../components/parts/PartInputPassword';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { newPasswordValidationSchema } from '../utils/validationSchemas';

import ArrowRightLong from '../components/icons/IconArrowRightlong';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CredentialsService from '../domain/credentials/CredentialsService';
import Collection from '../domain/valueObject/Collection';
import NotificationController from '../domain/notification/NotificationController';
import InvalidToastMessage from '../domain/notification/notificationTemplate/InvalidToastMessage';

const ResetPassword = () => {
    useDocumentTitle('Reset Password');
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = searchParams.get('id');
    const token = searchParams.get('token');

    const [conditionsMatched, setConditionsMatched] = useState({
        atLeast8Chars: false,
        atLeastNumber: false,
        atLeastCharacter: false
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitting, isValid }
    } = useForm<any>({
        mode: 'onBlur',
        resolver: yupResolver(newPasswordValidationSchema)
    });

    const navigate = useNavigate();

    const handleOnChangePassword = (event: any) => {
        const pwd: string = event.target.value;

        const conditions = { ...conditionsMatched };
        conditions.atLeast8Chars = false;
        conditions.atLeastCharacter = false;
        conditions.atLeastNumber = false;

        if (pwd.length >= 8) conditions.atLeast8Chars = true;
        if (/\d/.test(pwd)) conditions.atLeastNumber = true;
        //Check if special characters where typed
        if (/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pwd))
            conditions.atLeastCharacter = true;

        setConditionsMatched(conditions);
    };

    const onSubmit = async (data: any) => {
        try {
            setButtonLoading(true);
            await CredentialsService.setNewPassword(
                new Collection({
                    token: token,
                    user_id: userId,
                    new_password: data.passwordConfirm
                })
            );
            navigate('/reset-password-success');
        } catch (error: any) {
            if (error?.response?.status === 400) {
                navigate('/forgot-password');
                NotificationController.notify(
                    new InvalidToastMessage(
                        'This link has expired, please request a new email'
                    )
                );
            } else {
                NotificationController.notify(
                    new InvalidToastMessage(
                        'Oops.. An error occured. Please try again'
                    )
                );
            }
        }
        setButtonLoading(false);
    };

    const checkPasswordResetLink = async () => {
        try {
            await CredentialsService.checkPasswordResetLink(userId, token);
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage(
                    'This link has expired, please request a new email'
                )
            );
            navigate('/forgot-password');
        }
    };

    useEffect(() => {
        if (userId !== null && token !== null) checkPasswordResetLink();
    }, []);

    return (
        <div className="reset-password">
            <div className="container">
                <div className="reset-password__wrapper">
                    <h1 className="reset-password__title">Reset my password</h1>
                    <div className="reset-password__box">
                        <PartInputPassword
                            control={control}
                            errors={errors}
                            label="New Password"
                            name="password"
                            onChangeProps={handleOnChangePassword}
                            hideErrorMessage={!isSubmitted}
                        />

                        <div className="create-account__req create-account__req--reset-pass">
                            <span className="create-account__req-title">
                                Password requirements:
                            </span>
                            <span className="create-account__req-item">
                                <span
                                    className={
                                        conditionsMatched.atLeast8Chars
                                            ? 'create-account__req-item-icon green'
                                            : 'create-account__req-item-icon'
                                    }
                                ></span>
                                Be at least 8 characters
                            </span>
                            <span className="create-account__req-item">
                                <span
                                    className={
                                        conditionsMatched.atLeastNumber
                                            ? 'create-account__req-item-icon green'
                                            : 'create-account__req-item-icon'
                                    }
                                ></span>
                                Have at least 1 number
                            </span>
                            <span className="create-account__req-item">
                                <span
                                    className={
                                        conditionsMatched.atLeastCharacter
                                            ? 'create-account__req-item-icon green'
                                            : 'create-account__req-item-icon'
                                    }
                                ></span>
                                Have at least 1 symbol
                            </span>
                        </div>

                        <PartInputPassword
                            control={control}
                            errors={errors}
                            label="Confirm Password"
                            name="passwordConfirm"
                        />

                        <Button
                            className="btn blue reset-password__btn"
                            onClick={handleSubmit(onSubmit)}
                            loading={buttonLoading}
                        >
                            {isSubmitting && isValid
                                ? 'Resetting password...'
                                : 'Reset Password'}
                            <span className="btn__icon">
                                <ArrowRightLong />
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
