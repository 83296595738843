import BaseNotificationTemplate, {
    NOTIFICATION_FORMAT_TOAST
} from './BaseNotificationTemplate';
import IconExclamationFull from '../../../components/icons/IconExclamationFull';

export default class InvalidToastMessage extends BaseNotificationTemplate {
    constructor(content: string, duration?: number) {
        super(
            'error',
            content,
            duration || 5,
            NOTIFICATION_FORMAT_TOAST,
            <IconExclamationFull />
        );
    }
}
