import { Button } from 'antd';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';

import womanIllustration from '../assets/images/Illustration-woman-bc.svg';
import ArrowRightLong from '../components/icons/IconArrowRightlong';

const AccountCreated = () => {
    useDocumentTitle('Account Created');

    return (
        <div className="container">
            <div className="info-page__wrapper">
                <div className="info-page__image">
                    <img src={womanIllustration} alt="woman Illustration" />
                </div>
                <h1 className="page-info__title">Good Move!</h1>
                <p className="page-info__subtitle">
                    Your account has been created.
                    <br /> You can now view and manage your loaning process on
                    one platform.
                </p>
                <Link to={'/login'}>
                    <Button className="btn blue">
                        Login
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default AccountCreated;
