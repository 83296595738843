import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const DEAL_TRANSACTION_TYPE_PURCHASE = 1;
export const DEAL_TRANSACTION_TYPE_REFINANCE = 2;

export const FIELD_NAME_DEAL_TRANSACTION_TYPE_PURCHASE = 'Purchase';
export const FIELD_NAME_DEAL_TRANSACTION_TYPE_REFINANCE = 'Refinance';

export default class DealTransactionType extends BaseValueObject {
    _value: any;
    _name: string;
    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableTransactionTypes() {
        return [
            DEAL_TRANSACTION_TYPE_PURCHASE,
            DEAL_TRANSACTION_TYPE_REFINANCE
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case DEAL_TRANSACTION_TYPE_PURCHASE:
                this._name = FIELD_NAME_DEAL_TRANSACTION_TYPE_PURCHASE;
                break;
            case DEAL_TRANSACTION_TYPE_REFINANCE:
                this._name = FIELD_NAME_DEAL_TRANSACTION_TYPE_REFINANCE;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableTransactionTypes().indexOf(this._value) < 0) {
            throw new Exception('Invalid Deal Transaction Type', 400);
        }
    }
}
