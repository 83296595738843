import DealAssetType from '../loan/valueObject/DealAssetType';
import DealTransactionType from '../loan/valueObject/DealTransactionType';
import LoanType from '../loan/valueObject/LoanType';
import RehabType from '../loan/valueObject/RehabType';
import TypeFloat from '../valueObject/TypeFloat';
import TypeInteger from '../valueObject/TypeInteger';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';

export default class LoanApplicationEntity {
    _firstName: TypeString;
    _lastName: TypeString;
    _email: TypeString;
    _phone: TypeString;
    _propertyAddress: TypeString;
    _city: TypeString;
    _state: TypeString;
    _postalCode: TypeString;
    _unitCount: TypePositiveInteger;
    _assetClass: DealAssetType;
    _creditScore: TypeFloat;
    _propertyCountExperience: TypePositiveInteger;

    // This field leans the form into a specific direction (Purchase / Refinance)
    _transactionType: DealTransactionType;
    // Purchase fields
    _purchasePrice: TypeFloat;

    // Refinance fields
    _originalPurchaseDate: Date;
    _rehabAmountSpendToDate: TypeFloat;
    _existingDebt: TypeFloat;
    _originalPurchasePrice: TypeFloat;

    // Loan Type (Bridge, Term, Agency)
    _loanType: LoanType;

    // Bridge loan type fields
    _rehabType: RehabType;
    _estimatedAfterRepairValue: TypeFloat;
    _rehabAmount: TypeFloat;

    // Term/Agency fields
    _monthlyGrossRents: TypeFloat;
    _taxes: TypeFloat;
    _insurance: TypeFloat;
    _hoa: TypeFloat;
    _otherExpenses: TypeFloat;
    _managementExpenses: TypeFloat;
    _repairsMaintenance: TypeFloat;
    _utilities: TypeFloat;

    _estimatedAsIsValue: TypeFloat;

    get firstName(): TypeString {
        return this._firstName;
    }

    setFirstName(firstName: TypeString) {
        this._firstName = firstName;
        return this;
    }

    hasFirstName(): boolean {
        return this._firstName instanceof TypeString;
    }

    get lastName(): TypeString {
        return this._lastName;
    }

    setLastName(lastName: TypeString) {
        this._lastName = lastName;
        return this;
    }

    hasLastName(): boolean {
        return this._lastName instanceof TypeString;
    }

    get email(): TypeString {
        return this._email;
    }

    setEmail(email: TypeString) {
        this._email = email;
        return this;
    }

    hasEmail(): boolean {
        return this._email instanceof TypeString;
    }

    get phone(): TypeString {
        return this._phone;
    }

    setPhone(phone: TypeString) {
        this._phone = phone;
        return this;
    }

    hasPhone(): boolean {
        return this._phone instanceof TypeString;
    }

    get propertyAddress(): TypeString {
        return this._propertyAddress;
    }

    setPropertyAddress(propertyAddress: TypeString) {
        this._propertyAddress = propertyAddress;
        return this;
    }

    hasPropertyAddress(): boolean {
        return this._propertyAddress instanceof TypeString;
    }

    get city(): TypeString {
        return this._city;
    }

    setCity(city: TypeString) {
        this._city = city;
        return this;
    }

    hasCity(): boolean {
        return this._city instanceof TypeString;
    }

    get state(): TypeString {
        return this._state;
    }

    setState(state: TypeString) {
        this._state = state;
        return this;
    }

    hasState(): boolean {
        return this._state instanceof TypeString;
    }

    get postalCode(): TypeString {
        return this._postalCode;
    }

    setPostalCode(postalCode: TypeString) {
        this._postalCode = postalCode;
        return this;
    }

    hasPostalCode(): boolean {
        return this._postalCode instanceof TypeString;
    }

    get unitCount(): TypePositiveInteger {
        return this._unitCount;
    }

    setUnitCount(unitCount: TypePositiveInteger) {
        this._unitCount = unitCount;
        return this;
    }

    hasUnitCount(): boolean {
        return this._unitCount instanceof TypePositiveInteger;
    }

    get assetClass(): DealAssetType {
        return this._assetClass;
    }

    setAssetClass(assetClass: DealAssetType) {
        this._assetClass = assetClass;
        return this;
    }

    hasAssetClass(): boolean {
        return this._assetClass instanceof DealAssetType;
    }

    get creditScore(): TypeFloat {
        return this._creditScore;
    }

    setCreditScore(creditScore: TypeFloat) {
        this._creditScore = creditScore;
        return this;
    }

    hasCreditScore(): boolean {
        return this._creditScore instanceof TypeFloat;
    }

    get propertyCountExperience(): TypePositiveInteger {
        return this._propertyCountExperience;
    }

    setPropertyCountExperience(propertyCountExperience: TypePositiveInteger) {
        this._propertyCountExperience = propertyCountExperience;
        return this;
    }

    hasPropertyCountExperience(): boolean {
        return this._propertyCountExperience instanceof TypePositiveInteger;
    }

    get transactionType(): DealTransactionType {
        return this._transactionType;
    }

    setTransactionType(transactionType: DealTransactionType) {
        this._transactionType = transactionType;
        return this;
    }

    hasTransactionType(): boolean {
        return this._transactionType instanceof DealTransactionType;
    }

    get purchasePrice(): TypeFloat {
        return this._purchasePrice;
    }

    setPurchasePrice(purchasePrice: TypeFloat) {
        this._purchasePrice = purchasePrice;
        return this;
    }

    hasPurchasePrice(): boolean {
        return this._purchasePrice instanceof TypeFloat;
    }

    get originalPurchaseDate(): Date {
        return this._originalPurchaseDate;
    }

    setOriginalPurchaseDate(originalPurchaseDate: Date) {
        this._originalPurchaseDate = originalPurchaseDate;
        return this;
    }

    hasOriginalPurchaseDate(): boolean {
        return this._originalPurchaseDate instanceof Date;
    }

    get rehabAmountSpendToDate(): TypeFloat {
        return this._rehabAmountSpendToDate;
    }

    setRehabAmountSpendToDate(rehabAmountSpendToDate: TypeFloat) {
        this._rehabAmountSpendToDate = rehabAmountSpendToDate;
        return this;
    }

    hasRehabAmountSpendToDate(): boolean {
        return this._rehabAmountSpendToDate instanceof TypeFloat;
    }

    get existingDebt(): TypeFloat {
        return this._existingDebt;
    }

    setExistingDebt(existingDebt: TypeFloat) {
        this._existingDebt = existingDebt;
        return this;
    }

    hasExistingDebt(): boolean {
        return this._existingDebt instanceof TypeFloat;
    }

    get originalPurchasePrice(): TypeFloat {
        return this._originalPurchasePrice;
    }

    setOriginalPurchasePrice(originalPurchasePrice: TypeFloat) {
        this._originalPurchasePrice = originalPurchasePrice;
        return this;
    }

    hasOriginalPurchasePrice(): boolean {
        return this._originalPurchasePrice instanceof TypeFloat;
    }

    get loanType(): LoanType {
        return this._loanType;
    }

    setLoanType(loanType: LoanType) {
        this._loanType = loanType;
        return this;
    }

    hasLoanType(): boolean {
        return this._loanType instanceof LoanType;
    }

    get rehabType(): RehabType {
        return this._rehabType;
    }

    setRehabType(rehabType: RehabType) {
        this._rehabType = rehabType;
        return this;
    }

    hasRehabType(): boolean {
        return this._rehabType instanceof RehabType;
    }

    get estimatedAfterRepairValue(): TypeFloat {
        return this._estimatedAfterRepairValue;
    }

    setEstimatedAfterRepairValue(estimatedAfterRepairValue: TypeFloat) {
        this._estimatedAfterRepairValue = estimatedAfterRepairValue;
        return this;
    }

    hasEstimatedAfterRepairValue(): boolean {
        return this._estimatedAfterRepairValue instanceof TypeFloat;
    }

    get rehabAmount(): TypeFloat {
        return this._rehabAmount;
    }

    setRehabAmount(rehabAmount: TypeFloat) {
        this._rehabAmount = rehabAmount;
        return this;
    }

    hasRehabAmount(): boolean {
        return this._rehabAmount instanceof TypeFloat;
    }

    get monthlyGrossRents(): TypeFloat {
        return this._monthlyGrossRents;
    }

    setMonthlyGrossRents(monthlyGrossRents: TypeFloat) {
        this._monthlyGrossRents = monthlyGrossRents;
        return this;
    }

    hasMonthlyGrossRents(): boolean {
        return this._monthlyGrossRents instanceof TypeFloat;
    }

    get taxes(): TypeFloat {
        return this._taxes;
    }

    setTaxes(taxes: TypeFloat) {
        this._taxes = taxes;
        return this;
    }

    hasTaxes(): boolean {
        return this._taxes instanceof TypeFloat;
    }

    get insurance(): TypeFloat {
        return this._insurance;
    }

    setInsurance(insurance: TypeFloat) {
        this._insurance = insurance;
        return this;
    }

    hasInsurance(): boolean {
        return this._insurance instanceof TypeFloat;
    }

    get utilities(): TypeFloat {
        return this._utilities;
    }

    setUtilities(utilities: TypeFloat) {
        this._utilities = utilities;
        return this;
    }

    hasUtilities(): boolean {
        return this._utilities instanceof TypeFloat;
    }

    get otherExpenses(): TypeFloat {
        return this._otherExpenses;
    }

    setOtherExpenses(otherExpenses: TypeFloat) {
        this._otherExpenses = otherExpenses;
        return this;
    }

    hasOtherExpenses(): boolean {
        return this._otherExpenses instanceof TypeFloat;
    }

    get managementExpenses(): TypeFloat {
        return this._managementExpenses;
    }

    setManagementExpenses(managementExpenses: TypeFloat) {
        this._managementExpenses = managementExpenses;
        return this;
    }

    hasManagementExpenses(): boolean {
        return this._managementExpenses instanceof TypeFloat;
    }

    get repairsMaintenance(): TypeFloat {
        return this._repairsMaintenance;
    }

    setrepairsMaintenance(repairsMaintenance: TypeFloat) {
        this._repairsMaintenance = repairsMaintenance;
        return this;
    }

    hasrepairsMaintenance(): boolean {
        return this._repairsMaintenance instanceof TypeFloat;
    }

    get estimatedAsIsValue(): TypeFloat {
        return this._estimatedAsIsValue;
    }

    setEstimatedAsIsValue(estimatedAsIsValue: TypeFloat) {
        this._estimatedAsIsValue = estimatedAsIsValue;
        return this;
    }

    hasEstimatedAsIsValue(): boolean {
        return this._estimatedAsIsValue instanceof TypeFloat;
    }

    get hoa(): TypeFloat {
        return this._hoa;
    }

    setHoa(hoa: TypeFloat) {
        this._hoa = hoa;
        return this;
    }

    hasHoa(): boolean {
        return this._hoa instanceof TypeFloat;
    }
}
