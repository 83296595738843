import BaseModel from './BaseModel';
import Collection from './valueObject/Collection';
import TypePositiveInteger from './valueObject/TypePositiveInteger';

export default abstract class BaseScrollEntity extends BaseModel {
    _total_item_count: TypePositiveInteger;
    _current_item_count: TypePositiveInteger;
    _page: TypePositiveInteger;
    _per_page: TypePositiveInteger;
    _total_page_count: TypePositiveInteger;
    _items: Collection;

    constructor(
        total_item_count: TypePositiveInteger,
        current_item_count: TypePositiveInteger,
        page: TypePositiveInteger,
        per_page: TypePositiveInteger,
        total_page_count: TypePositiveInteger,
        items: Collection
    ) {
        super();
        this._total_item_count = total_item_count;
        this._current_item_count = current_item_count;
        this._page = page;
        this._per_page = per_page;
        this._total_page_count = total_page_count;
        this._items = items;
    }

    canLoadMore() {
        return (
            this._page.value < this._total_page_count.value ||
            this._page.value === 0
        );
    }

    isFirstPageLoaded() {
        return this._page.value > 0;
    }

    reset() {
        this._items = new Collection([]);
        this._current_item_count = new TypePositiveInteger(0);
        this._page = new TypePositiveInteger(0);
        // this._perPage = new TypeInteger(0); // Perhaps we shouldn't reset this
        this._total_page_count = new TypePositiveInteger(0);
        this._total_item_count = new TypePositiveInteger(0);
        this._executeAfterChangeTriggers();
    }

    get totalCount() {
        return this._total_item_count;
    }

    set totalCount(value: TypePositiveInteger) {
        if (this._total_item_count instanceof TypePositiveInteger) {
            this._total_item_count.value = value.value;
        } else {
            this._total_item_count = value;
        }
    }

    get currentCount() {
        return this._current_item_count;
    }

    set currentCount(value: TypePositiveInteger) {
        if (this._current_item_count instanceof TypePositiveInteger) {
            this._current_item_count.value = value.value;
        } else {
            this._current_item_count = value;
        }
    }

    get currentPage() {
        return this._page;
    }

    set currentPage(value: TypePositiveInteger) {
        if (this._page instanceof TypePositiveInteger) {
            this._page.value = value.value;
        } else {
            this._page = value;
        }
    }

    get perPage() {
        return this._per_page;
    }

    set perPage(value: TypePositiveInteger) {
        if (this._per_page instanceof TypePositiveInteger) {
            this._per_page.value = value.value;
        } else {
            this._per_page = value;
        }
    }

    get totalPageCount() {
        return this._total_page_count;
    }

    set totalPageCount(value: TypePositiveInteger) {
        if (this._total_page_count instanceof TypePositiveInteger) {
            this._total_page_count.value = value.value;
        } else {
            this._total_page_count = value;
        }
    }

    get items() {
        return this._items;
    }

    set items(value: Collection) {
        this._items = value;
    }

    appendMultiple(items: Collection) {
        items.forEach((item: any) => {
            this._insertItem(item);
        });
        if (!items.isEmpty()) {
            this._executeAfterChangeTriggers();
            this._executeAfterAddTriggers();
        }
    }

    push(item: any) {
        if (!this.hasItem(item)) {
            this._insertItem(item);
            this._executeAfterChangeTriggers();
            this._executeAfterAddTriggers();
        }
    }

    _insertItem(item: any) {
        if (!this.hasItem(item)) {
            this._items.insert(item);
            this._current_item_count.value = this._current_item_count.value + 1;
        }
    }

    unshift(item: any) {
        if (!this.hasItem(item)) {
            this._items.unshift(item);
            this._current_item_count.value = this._current_item_count.value + 1;
            this._executeAfterChangeTriggers();
            this._executeAfterAddTriggers();
        }
    }

    remove(item: any) {
        const itemPosition = this.getItemPosition(item);
        this._items.remove(itemPosition);
        this._current_item_count.value = this._items.count();
        this._executeAfterChangeTriggers();
    }

    // eslint-disable-next-line
    abstract hasItem(item: any): boolean;
    // eslint-disable-next-line
    abstract getItemPosition(item: any): number;
}
