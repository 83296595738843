import BaseScrollEntity from '../BaseScrollEntity';
import LoanEntity from '../loan/LoanEntity';
import LoanScrollFilterRulesEntity from '../loanFilterRule/valueObject/LoanScrollFilterRulesEntity';
import LoanSortingRulesEntity from '../loanSortingRule/LoanSortingRulesEntity';
import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';

export default class LoanScrollEntity extends BaseScrollEntity {
    _sortingRules: LoanSortingRulesEntity | undefined;
    _filterRules: LoanScrollFilterRulesEntity | undefined;
    constructor(
        total_count: TypePositiveInteger,
        current_count: TypePositiveInteger,
        page: TypePositiveInteger,
        per_page: TypePositiveInteger,
        total_page_count: TypePositiveInteger,
        items: Collection
    ) {
        super(
            total_count,
            current_count,
            page,
            per_page,
            total_page_count,
            items
        );
    }

    get sortingRules(): LoanSortingRulesEntity | undefined {
        return this._sortingRules;
    }
    setSortingRules(value: LoanSortingRulesEntity | undefined) {
        this._sortingRules = value;
        return this;
    }

    get filterRules(): LoanScrollFilterRulesEntity | undefined {
        return this._filterRules;
    }
    setFilterRules(value: LoanScrollFilterRulesEntity | undefined) {
        this._filterRules = value;
        return this;
    }

    hasFilterRules(): boolean {
        return this._filterRules instanceof LoanScrollFilterRulesEntity;
    }

    reset() {
        this._sortingRules = undefined;
        this._filterRules = undefined;
        super.reset();
    }
    resetScroll() {
        super.reset();
    }

    hasItem(item: any) {
        const result: any = this.items.find((loan: LoanEntity) => {
            return loan.loanId.sameAs(item.loanId);
        });

        return result instanceof LoanEntity;
    }

    getItemPosition(loan: any) {
        let index = -1;
        this.items.forEach((item: any, i: number) => {
            if (loan.loanId.sameAs(item.loanId)) {
                index = i;
            }
        });

        return index;
    }

    appendMultiple(items: Collection) {
        items.forEach((item: any) => {
            this._insertItem(item);
        });
        if (!items.isEmpty()) {
            this._executeAfterChangeTriggers();
            this._executeAfterAddTriggers();
        }
        items.forEach((item: any) => {
            item.afterChange('item_changed_update_scroll', () => {
                this._executeAfterChangeTriggers();
            });
        });
    }
}
