const ArrowDown = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.552"
            height="16.974"
            viewBox="0 0 12.552 16.974"
        >
            <g
                id="Group_3946"
                data-name="Group 3946"
                transform="translate(-5.084 20.391) rotate(-90)"
            >
                <path
                    id="Path_1957"
                    data-name="Path 1957"
                    d="M20.141,10.751H4.667a.75.75,0,1,1,0-1.5H20.141a.75.75,0,0,1,0,1.5Z"
                    transform="translate(-0.5 1.36)"
                    fill="#548943"
                />
                <path
                    id="Path_1958"
                    data-name="Path 1958"
                    d="M12.167,16.276a.75.75,0,0,1-.53-1.28L17.163,9.47a.75.75,0,0,1,1.061,1.061L12.7,16.057A.748.748,0,0,1,12.167,16.276Z"
                    transform="translate(1.948 1.36)"
                    fill="#548943"
                />
                <path
                    id="Path_1959"
                    data-name="Path 1959"
                    d="M17.693,12.11a.748.748,0,0,1-.53-.22L11.636,6.364A.75.75,0,0,1,12.7,5.3l5.526,5.526a.75.75,0,0,1-.53,1.28Z"
                    transform="translate(1.948 0)"
                    fill="#548943"
                />
            </g>
        </svg>
    );
};

export default ArrowDown;
