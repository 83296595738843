import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PartInputField from '../components/parts/PartInputField';
import { loginFormValidationSchema } from '../utils/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import PartInputPassword from '../components/parts/PartInputPassword';
import { Button } from 'antd';
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import CredentialsService from '../domain/credentials/CredentialsService';
import CredentialsFactory from '../domain/credentials/CredentialsFactory';
import TypeString from '../domain/valueObject/TypeString';
import CredentialsType from '../domain/credentials/valueObject/CredentialsType';
import ArrowRightLong from '../components/icons/IconArrowRightlong';
import Exclamation from '../components/icons/IconExclamation';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { storeUserInLocalStorage, storeUserInRedux } from '../utils/utils';

interface FormData {
    email: string;
    password: string;
}

const Login = () => {
    useDocumentTitle('Login');
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isValid }
    } = useForm<any>({
        resolver: yupResolver(loginFormValidationSchema),
        mode: 'onBlur'
    });

    const [responseErrorMessage, setResponseErrorMessage] = useState<
        string | null
    >(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as { from: Location };
    const from = state ? state.from.pathname : '/my-loans';

    const onSubmit = async (data: FormData) => {
        try {
            setButtonLoading(true);
            const credentials = CredentialsFactory.makeNew(
                new TypeString(data.email),
                new TypeString(data.password),
                CredentialsType.makeEmail()
            );
            await CredentialsService.login(credentials);
            const me = await CredentialsService.me();
            if (me.hasUser()) {
                storeUserInLocalStorage(me);
                storeUserInRedux(me);
            }

            navigate(from, { replace: true });
        } catch (error) {
            setResponseErrorMessage(
                `Your email or password is incorrect. Please try again or click Forgot password to reset`
            );
        }
        setButtonLoading(false);
    };

    useEffect(() => {
        if (localStorage.getItem('isLoggedIn'))
            navigate('/my-loans', { replace: true });
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">Login</h1>
                <p className="login__subtitle big-margin">
                    Access your NDL account
                </p>
                <div className="login__box">
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Email"
                        name="email"
                        isRequired
                    />

                    <PartInputPassword
                        control={control}
                        errors={errors}
                        label="Password"
                        name="password"
                        onPressEnter={handleSubmit(onSubmit)}
                        isRequired
                    />
                    <span className="login__error-wrap">
                        {responseErrorMessage !== null ? (
                            <span className="login__error">
                                <span className="form-element__error-msg-icon">
                                    <Exclamation />
                                </span>
                                <span>{responseErrorMessage}</span>
                            </span>
                        ) : null}
                    </span>
                    <Button
                        className="btn blue login__btn"
                        disabled={isSubmitting}
                        type="primary"
                        loading={buttonLoading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {isSubmitting && isValid ? 'Logging in...' : 'Login'}
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </Button>
                    <div className="login__footer">
                        <Link to={'/forgot-password'}>
                            Forgot Your Password?
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
