import React from 'react';
import Exception from '../../../utils/exceptions/Exception';

export const NOTIFICATION_FORMAT_NOTIFICATION: string = 'notification';
export const NOTIFICATION_FORMAT_TOAST: string = 'toast';

export default class BaseNotificationTemplate {
    _type: 'success' | 'info' | 'error' | 'warning';
    _content: React.ReactNode | string;
    _duration: number;
    _format: string;
    _icon: React.ReactNode;
    constructor(
        type: any,
        content: React.ReactNode | string,
        duration: number,
        format: string,
        icon: React.ReactNode
    ) {
        this._type = type;
        this._content = content;
        this._duration = duration;
        this._icon = icon;
        if (
            [
                NOTIFICATION_FORMAT_NOTIFICATION,
                NOTIFICATION_FORMAT_TOAST
            ].indexOf(format) < 0
        )
            throw new Exception('Invalid notification format', 500);
        this._format = format;
    }

    get type() {
        return this._type;
    }

    get content() {
        return this._content;
    }

    get duration() {
        return this._duration;
    }

    get format() {
        return this._format;
    }

    get icon() {
        return this._icon;
    }
}
