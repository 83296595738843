import { DEAL_TYPE_NO_DOC_DSCR } from '../loan/valueObject/LoanType';
import { LoanTermEntity } from './LoanTermEntity';

export interface LoanTermViewData {
    loanTerms: {
        loanPurpose: string;
        initialLoanAmount: string;
        initialLoanAmountPurchasePrice: string;
        constructionHoldback: string;
        constructionHoldbackRenovationBudget: string;
        loanToValue: string;
        totalLoanAmount: string;
        recourseGuarantyStructure: string;
    };
    pricing?: {
        interestRate: string;
        exitFee: string;
        underwritingFee: string;
        processingFee: string;
        legalFee: string;
        originationFeePercentage: string;
        brokerFeePercentage: string;
        pricingPrePaymentPenalty: string;
        rateType: string;
        amortizationType: string;
    };
    interestMechanics: {
        interestMechanicTerm: string;
        interestAccrualMethod: string;
        interestReserve: string;
        interestMechanicsPrePaymentPenalty: string;
    };
    feesAndEscrows?: {
        underwritingFee: string;
        processingFee: string;
        legalFee: string;
        originationFeePercentage: string;
        brokerFeePercentage: string;
        fePitiReserve: string;
        feInsuranceEscrowAmount: string;
        feTaxEscrowAmount: string;
    };
    closingConditions: {
        ccLtv: string;
        ccLtarv: string;
        ccLtfc: string;
        ccMinCreditScore: string;
        ccTrackRecord: string;
        ccLiquidity: string;
        ccNetWorth: string;
        ccDscr: string;
    };

    // Combination
}

const loanAmountCurrencyFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const percentageFormatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const NO_INFO = '';

export default class LoanTermViewMapper {
    static map(loanTerm: LoanTermEntity, loanType: number): LoanTermViewData {
        return {
            loanTerms: {
                loanPurpose: loanTerm.hasLoanPurpose()
                    ? loanTerm.loanPurpose.name
                    : NO_INFO,
                initialLoanAmount: loanTerm.hasInitialLoanAmount()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.initialLoanAmount.value
                      )
                    : NO_INFO,
                initialLoanAmountPurchasePrice:
                    loanTerm.hasInitialLoanAmountPurchasePrice()
                        ? loanAmountCurrencyFormatter.format(
                              loanTerm.initialLoanAmountPurchasePrice.value
                          )
                        : NO_INFO,
                constructionHoldback: loanTerm.hasConstructionHoldback()
                    ? percentageFormatter.format(
                          loanTerm.constructionHoldback.value / 100
                      )
                    : NO_INFO,
                constructionHoldbackRenovationBudget:
                    loanTerm.hasConstructionHoldbackRenovationBudget()
                        ? percentageFormatter.format(
                              loanTerm.constructionHoldbackRenovationBudget
                                  .value / 100
                          )
                        : NO_INFO,
                totalLoanAmount: loanTerm.hasTotalLoanAmount()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.totalLoanAmount.value
                      )
                    : NO_INFO,
                recourseGuarantyStructure:
                    loanTerm.hasRecourseGuarantyStructure()
                        ? loanTerm.recourseGuarantyStructure.name
                        : NO_INFO,
                loanToValue: loanTerm.hasLoanToValue()
                    ? percentageFormatter.format(
                          loanTerm.loanToValue.value / 100
                      )
                    : NO_INFO
            },
            pricing: {
                interestRate: loanTerm.hasInterestRate()
                    ? percentageFormatter.format(
                          loanTerm.interestRate.value / 100
                      )
                    : NO_INFO,
                originationFeePercentage:
                    loanType !== DEAL_TYPE_NO_DOC_DSCR &&
                    loanTerm.hasOriginationFeePercentage()
                        ? percentageFormatter.format(
                              loanTerm.originationFeePercentage.value / 100
                          )
                        : NO_INFO,
                brokerFeePercentage:
                    loanType !== DEAL_TYPE_NO_DOC_DSCR &&
                    loanTerm.hasBrokerFeePercentage()
                        ? percentageFormatter.format(
                              loanTerm.brokerFeePercentage.value / 100
                          )
                        : NO_INFO,
                exitFee: loanTerm.hasExitFee()
                    ? percentageFormatter.format(loanTerm.exitFee.value / 100)
                    : NO_INFO,
                underwritingFee:
                    loanType !== DEAL_TYPE_NO_DOC_DSCR &&
                    loanTerm.hasUnderwritingFee()
                        ? loanAmountCurrencyFormatter.format(
                              loanTerm.underwritingFee.value
                          )
                        : NO_INFO,
                processingFee:
                    loanType !== DEAL_TYPE_NO_DOC_DSCR &&
                    loanTerm.hasProcessingFee()
                        ? loanAmountCurrencyFormatter.format(
                              loanTerm.processingFee.value
                          )
                        : NO_INFO,
                legalFee:
                    loanType !== DEAL_TYPE_NO_DOC_DSCR && loanTerm.hasLegalFee()
                        ? loanAmountCurrencyFormatter.format(
                              loanTerm.legalFee.value
                          )
                        : NO_INFO,
                rateType: loanTerm.hasRateType()
                    ? loanTerm.rateType.name
                    : NO_INFO,
                amortizationType: loanTerm.hasAmortizationType()
                    ? loanTerm.amortizationType.name
                    : NO_INFO,
                pricingPrePaymentPenalty: loanTerm.hasPricingPrePaymentPenalty()
                    ? loanTerm.pricingPrePaymentPenalty.name
                    : NO_INFO
            },
            interestMechanics: {
                interestAccrualMethod: loanTerm.hasInterestAccrualMethod()
                    ? loanTerm.interestAccrualMethod.name
                    : NO_INFO,
                interestMechanicTerm: loanTerm.hasInterestMechanicTerm()
                    ? loanTerm.interestMechanicTerm.name
                    : NO_INFO,
                interestReserve: loanTerm.hasInterestReserve()
                    ? loanTerm.interestReserve.name
                    : NO_INFO,
                interestMechanicsPrePaymentPenalty:
                    loanTerm.hasInterestMechanicsPrePaymentPenalty()
                        ? loanTerm.interestMechanicsPrePaymentPenalty.name
                        : NO_INFO
            },
            closingConditions: {
                ccLtv: loanTerm.hasCcLtv()
                    ? percentageFormatter.format(loanTerm.ccLtv.value / 100)
                    : NO_INFO,
                ccDscr: loanTerm.hasCcDscr()
                    ? percentageFormatter.format(loanTerm.ccDscr.value / 100)
                    : NO_INFO,
                ccLiquidity: loanTerm.hasCcLiquidity()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.ccLiquidity.value
                      )
                    : NO_INFO,
                ccLtarv: loanTerm.hasCcLtarv()
                    ? percentageFormatter.format(loanTerm.ccLtarv.value / 100)
                    : NO_INFO,
                ccLtfc: loanTerm.hasCcLtfc()
                    ? percentageFormatter.format(loanTerm.ccLtfc.value / 100)
                    : NO_INFO,
                ccMinCreditScore: loanTerm.hasCcMinCreditScore()
                    ? loanTerm.ccMinCreditScore.value
                    : NO_INFO,
                ccNetWorth: loanTerm.hasCcNetWorth()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.ccNetWorth.value
                      )
                    : NO_INFO,
                ccTrackRecord: loanTerm.hasCcTrackRecord()
                    ? loanTerm.ccTrackRecord.value
                    : NO_INFO
            },
            feesAndEscrows: {
                originationFeePercentage: loanTerm.hasOriginationFeePercentage()
                    ? percentageFormatter.format(
                          loanTerm.originationFeePercentage.value / 100
                      )
                    : NO_INFO,
                brokerFeePercentage: loanTerm.hasBrokerFeePercentage()
                    ? percentageFormatter.format(
                          loanTerm.brokerFeePercentage.value / 100
                      )
                    : NO_INFO,
                underwritingFee: loanTerm.hasUnderwritingFee()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.underwritingFee.value
                      )
                    : NO_INFO,
                processingFee: loanTerm.hasProcessingFee()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.processingFee.value
                      )
                    : NO_INFO,
                legalFee: loanTerm.hasLegalFee()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.legalFee.value
                      )
                    : NO_INFO,
                feInsuranceEscrowAmount: loanTerm.hasFeInsuranceEscrowAmount()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.feInsuranceEscrowAmount.value
                      )
                    : NO_INFO,
                fePitiReserve: loanTerm.hasFePitiReserve()
                    ? loanTerm.fePitiReserve.name
                    : NO_INFO,
                feTaxEscrowAmount: loanTerm.hasFeTaxEscrowAmount()
                    ? loanAmountCurrencyFormatter.format(
                          loanTerm.feTaxEscrowAmount.value
                      )
                    : NO_INFO
            }
        };
    }
}
