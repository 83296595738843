import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PartInputField from '../../parts/PartInputField';
import BlockStepsButtons from '../BlockStepsButtons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { NewLoanSteps } from '../../../utils/enums';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';

interface CreditScoreProps extends NewLoanApplicationStepsProps {}

const CreditScore = ({
    nextStep,
    prevStep,
    newLoanEntity
}: CreditScoreProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                creditScore: yup
                    .string()
                    .required('Please complete this required field.')
                    .matches(
                        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                        'You must specify a number.'
                    )
            })
        )
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.RENTAL_PROPERTY_EXPERIENCE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.CONTACT_DETAILS_PROPERTY);
    };

    useEffect(() => {
        newLoanEntity.hasCreditScore() &&
            setValue('creditScore', newLoanEntity.creditScore.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">What&#39;s your credit score?</h1>
                <p className="login__subtitle">Personal Credit</p>
                <div className="login__box">
                    <PartInputField
                        label="Credit Score"
                        name="creditScore"
                        control={control}
                        errors={errors}
                        isNumberFormat
                        isRequired
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreditScore;
