import { AutoComplete, Input } from 'antd';
import {
    ChangeEventHandler,
    ReactNode,
    useEffect,
    useRef,
    useState
} from 'react';
import NotificationController from '../../domain/notification/NotificationController';
import InvalidToastMessage from '../../domain/notification/notificationTemplate/InvalidToastMessage';
import { useNavigate } from 'react-router-dom';
import TypeString from '../../domain/valueObject/TypeString';
import LoanSearchService from '../../domain/loanSearch/LoanSearchService';
import { getBoldedTextMatchingKeywords } from '../../utils/utils';

const PartAutoCompleteSearchSelectField = () => {
    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(false);
    const [searchOptions, setSearchOptions] = useState<
        Array<{ label: ReactNode; options: Array<ReactNode> }>
    >([]);
    const renderTitle = (title: string, hasOptions: boolean) => (
        <>
            <span>{title}</span>
            {!hasOptions && (
                <span className="search-no-results">No Results</span>
            )}
        </>
    );
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            clearTimeout(timeoutIdRef.current!);
        };
    }, []);

    const renderItemLoanID = (
        loanId: number,
        loanAddress: string,
        keywords: Array<string>
    ) => ({
        value: `id-${loanId}`,
        label: (
            <div
                style={{
                    display: 'flex'
                }}
            >
                <div
                    className="loan-id"
                    dangerouslySetInnerHTML={{
                        __html: `#${getBoldedTextMatchingKeywords(
                            loanId.toString(),
                            keywords
                        )}`
                    }}
                />
                <div className="loan-title">{loanAddress}</div>
            </div>
        )
    });

    const propertyAddress = (
        loanId: number,
        loanAddress: string,
        keywords: Array<string>
    ) => {
        return {
            value: `address-${loanId}`,
            label: (
                <div
                    style={{
                        display: 'flex'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: getBoldedTextMatchingKeywords(
                            loanAddress,
                            keywords
                        )
                    }}
                ></div>
            )
        };
    };

    const queryLoanOptions: ChangeEventHandler<HTMLInputElement> = async (
        event
    ) => {
        clearTimeout(timeoutIdRef.current!);
        timeoutIdRef.current = setTimeout(async () => {
            const searchTerm = event.target.value;
            if (!searchTerm) {
                setSearchOptions([]);
                return;
            }
            setIsFetching(true);
            try {
                const result = await LoanSearchService.searchByKeyword(
                    new TypeString(searchTerm)
                );
                setSearchOptions(mapToSearchOptions(result.items, searchTerm));
            } catch (error) {
                NotificationController.notify(
                    new InvalidToastMessage('Error searching loans')
                );
            } finally {
                setIsFetching(false);
            }
        }, 500);
    };

    function mapToSearchOptions(loans: any[], keywords: string) {
        const keywordsMap = keywords
            .toLowerCase()
            .split(/\s+/)
            .filter((keyword) => keyword.length > 0);

        const idMatches = loans.filter((loan) => {
            return keywordsMap.some((keyword) => {
                return loan.id.toString() === keyword;
            });
        });

        const addressMatches = loans.filter((loan) => {
            return keywordsMap.some((keyword) => {
                const fullAddress = [
                    loan.property_address.street_address,
                    loan.property_address.city,
                    loan.property_address.state,
                    loan.property_address.zip
                ].join(' ');
                return fullAddress.toLowerCase().includes(keyword);
            });
        });

        return [
            {
                label: renderTitle('Loan ID', idMatches.length > 0),
                options: idMatches.map((loan) => {
                    return renderItemLoanID(
                        loan.id,
                        [
                            loan.property_address.street_address,
                            loan.property_address.city,
                            loan.property_address.state,
                            loan.property_address.zip
                        ].join(', '),
                        keywordsMap
                    );
                })
            },
            {
                label: renderTitle(
                    'Property Address',
                    addressMatches.length > 0
                ),
                options: addressMatches.map((loan) => {
                    return propertyAddress(
                        loan.id,
                        [
                            loan.property_address.street_address,
                            loan.property_address.city,
                            loan.property_address.state,
                            loan.property_address.zip
                        ].join(', '),
                        keywordsMap
                    );
                })
            }
        ];
    }

    return (
        <div
            className={`form-element my-loans__search`}
            data-testid="autocomplete"
        >
            <AutoComplete
                popupClassName="my-loans__dropdown"
                options={searchOptions}
                onSelect={(value) => {
                    const loanId = value
                        .replace('id-', '')
                        .replace('address-', '');
                    navigate(`/my-loans/${loanId}`);
                }}
            >
                <Input.Search
                    size="large"
                    placeholder="Search by ID or Address"
                    onChange={queryLoanOptions}
                />
            </AutoComplete>
        </div>
    );
};

export default PartAutoCompleteSearchSelectField;
