import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

// Pages
import AccountCreated from './pages/AccountCreated';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import NotFoundPage from './pages/NotFoundPage';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordSuccess from './pages/ResetPasswordSuccess';
import SetupAccount from './pages/SetupAccount';
import MyLoans from './pages/MyLoans';
import SingleLoan from './pages/SingleLoan';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NewLoanApplication from './pages/NewLoanApplication';

// Router, Store and Assets
import PrivateOutlet from './router/PrivateOutlet';
import CredentialsService from './domain/credentials/CredentialsService';
import store from './store/storeIndex';
import { triggerLogout } from './store/auth';
import { storeUserInLocalStorage, storeUserInRedux } from './utils/utils';
import PublicOutlet from './router/PublicOutlet';
import LoanTerms from './pages/LoanTerms';
import classNames from 'classnames';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const locationTerms = location.pathname === '/loan-terms';

    const getMe = async () => {
        try {
            const me = await CredentialsService.me();
            if (me.hasUser()) {
                storeUserInLocalStorage(me);
                storeUserInRedux(me);
            }
        } catch (error) {
            store.dispatch(triggerLogout());
        }
    };

    useEffect(() => {
        if (location.pathname === '/') navigate('/my-loans');
    }, [location]);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== null) {
            getMe();
        }
    }, []);

    return (
        <div
            className={classNames('app-wrapper--main', {
                'loan-terms-main': locationTerms
            })}
        >
            <Routes>
                {/* Private Routes */}
                <Route element={<PrivateOutlet />}>
                    <Route path="/my-loans/:loanId" element={<SingleLoan />} />
                    <Route
                        path="/my-loans/:loanId/loan-terms"
                        element={<LoanTerms />}
                    />
                    <Route path="/my-loans" element={<MyLoans />} />
                </Route>
                {/* Public Routes */}
                <Route element={<PublicOutlet />}>
                    <Route path="login" element={<Login />} />
                    <Route path="set-up-account" element={<SetupAccount />} />
                    <Route
                        path="account-created"
                        element={<AccountCreated />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />

                    <Route
                        path="reset-password-success"
                        element={<ResetPasswordSuccess />}
                    />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />

                    <Route
                        path="new-loan-application"
                        element={<NewLoanApplication />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
