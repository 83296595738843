import * as yup from 'yup';

const PASSWORD_MINIMUM_CHARACTERS = 8;

// Rule: Minimum eight characters, at least one letter, one number and one special character
const PASSWORD_REGULAR_EXPRESSION =
    /^(?=.*[A-Za-z\d])(?=.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;

export const loginFormValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Please enter valid email format')
        .required('Please provide an email address'),
    password: yup.string().required('Please provide a password')
});

export const forgotPasswordValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Please enter valid email format')
        .required('Please provide an email address')
});

export const setupAccountValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Please enter valid email format')
        .required('Please provide an email address'),
    password: yup
        .string()
        .required('Please provide a password')
        .min(PASSWORD_MINIMUM_CHARACTERS, '')
        .matches(
            PASSWORD_REGULAR_EXPRESSION,
            'Please follow password requirements'
        )
});

export const newPasswordValidationSchema = yup.object().shape({
    password: yup
        .string()
        .required('Please provide a password')
        .min(PASSWORD_MINIMUM_CHARACTERS, '')
        .matches(
            PASSWORD_REGULAR_EXPRESSION,
            'Please follow password requirements'
        ),
    passwordConfirm: yup
        .string()
        // .min(
        //     PASSWORD_MINIMUM_CHARACTERS,
        //     `Minimum ${PASSWORD_MINIMUM_CHARACTERS} characters are required`
        // )
        // .matches(PASSWORD_REGULAR_EXPRESSION, 'Invalid password format')
        .required('')
        .oneOf(
            [yup.ref('password'), null],
            "Hmmm... Seems like the passwords don't match"
        )
});

export const contactDetailsValidationSchema = yup.object().shape({
    firstName: yup.string().required('Please complete this required field.'),
    lastName: yup.string().required('Please complete this required field.'),
    email: yup
        .string()
        .email('Email must be a valid format.')
        .required('Please complete this required field.'),
    phone: yup
        .string()
        .required('Please complete this required field.')
        .test('len', 'Phone number length is incorrect.', (val) => {
            return val ? val?.replace(/[-+()\s]/g, '').length > 9 : false;
        })
});

export const contactDetailsPropertyValidationSchema = yup.object().shape({
    address: yup.string().required('Please complete this required field.'),
    city: yup.string().required('Please complete this required field.'),
    state: yup.string().required('Please complete this required field.'),
    postal: yup.string().required('Please complete this required field.'),
    unitCount: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    assetClass: yup.number().required('Please complete this required field.')
});

export const monthlyGrossRentsExtValidationSchema = yup.object().shape({
    taxes: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    insurance: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    utilities: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    otherExpenses: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    managementExpenses: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    repairsMaintenance: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    hoa: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        )
});

export const loanTerm1ValidationSchema = yup.object().shape({
    'loan_terms-initial_loan_amount': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-initial_loan_amount_purchase_price': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-construction_hold_back': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-construction_hold_back_renovation_budget': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-total_loan_amount': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'pricing-interest_rate': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    origination_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    broker_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    underwriting_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    processing_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    legal_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'interest_mechanics-term': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-interest_accrual_method': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-pre_payment_penalty': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-interest_reserve': yup
        .number()
        .required('Please complete this required field.'),
    'cc-ltv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltarv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltfc': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-min_credit_score': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-track_record': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-liquidity': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-net_worth': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        )
});

export const loanTerm2ValidationSchema = yup.object().shape({
    'loan_terms-initial_loan_amount': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-initial_loan_amount_purchase_price': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-construction_hold_back': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-construction_hold_back_renovation_budget': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-total_loan_amount': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'loan_terms-recourse_guaranty_structure': yup.number().optional(),
    'pricing-interest_rate': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    origination_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'pricing-exit_fee': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    broker_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    underwriting_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    processing_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    legal_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'interest_mechanics-term': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-pre_payment_penalty': yup
        .number()
        .required('Please complete this required field.'),

    'interest_mechanics-interest_accrual_method': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-interest_reserve': yup
        .number()
        .required('Please complete this required field.'),
    'cc-ltv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltarv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltfc': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-min_credit_score': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-track_record': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-liquidity': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-net_worth': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        )
});

export const loanTerm3ValidationSchema = yup.object().shape({
    'loan_terms-loan_purpose': yup
        .number()
        .required('Please complete this required field.'),
    'loan_terms-total_loan_amount': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),

    'loan_terms-loan_to_value': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'pricing-interest_rate': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    origination_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    broker_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    underwriting_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    processing_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    legal_fee: yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'interest_mechanics-term': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-interest_accrual_method': yup
        .number()
        .required('Please complete this required field.'),
    'interest_mechanics-interest_reserve': yup
        .number()
        .required('Please complete this required field.'),
    'cc-ltv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltfc': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-min_credit_score': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-track_record': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-liquidity': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-net_worth': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        )
});

export const loanTerm4ValidationSchema = yup.object().shape({
    'loan_terms-loan_purpose': yup
        .number()
        .required('Please complete this required field.'),
    'loan_terms-total_loan_amount': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),

    'loan_terms-loan_to_value': yup
        .string()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'pricing-interest_rate': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'pricing-rate_type': yup.number().optional(),
    'pricing-amortization_type': yup.number().optional(),
    'pricing-pre_payment_penalty': yup.number().optional(),
    origination_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    broker_fee_percentage: yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'fees_and_escrows-underwriting_fee': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'fees_and_escrows-processing_fee': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    '-legal_fee': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'fees_and_escrows-piti_reserve': yup
        .number()
        .required('Please complete this required field.'),
    'fees_and_escrows-insurance_escrow_amount': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'fees_and_escrows-tax_escrow_amount': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-ltv': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-min_credit_score': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-track_record': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-liquidity': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-net_worth': yup
        .string()
        .required('Please complete this required field.')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        ),
    'cc-dscr': yup
        .string()
        .optional()
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'You must specify a number.'
        )
});
