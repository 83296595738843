import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import IconArrowDown from '../icons/IconArrowDown';
import { LOAN_SORTING_FIELD_CREATED_AT } from '../../domain/loanSortingRule/valueObject/LoanSortingField';
import TypeOrderDirection, {
    ORDER_DIRECTION_DIRECTION_ASCENDING,
    ORDER_DIRECTION_DIRECTION_DESCENDING
} from '../../domain/valueObject/TypeOrderDirection';
import LoanSortingRule from '../../domain/loanSortingRule/LoanSortingRule';
import LoanScrollEntity from '../../domain/loanScroll/LoanScrollEntity';

interface PartLoanSortProps {
    loanScroll: React.RefObject<LoanScrollEntity | undefined>;
}

const PartLoanSort = ({ loanScroll }: PartLoanSortProps) => {
    const [selectedValue, setSelectedValue] = useState<any>(undefined);
    const handleSelectChange = (value: any) => {
        // eslint-disable-next-line no-unused-vars
        const [key, direction] = value.split('-');

        const rule = loanScroll.current?.sortingRules?.rules.find(
            (rule: LoanSortingRule) => {
                return rule.field.isCreatedAt();
            }
        );

        setSelectedValue(value);

        if (rule instanceof LoanSortingRule)
            rule.setDirection(new TypeOrderDirection(direction));
    };

    // Retrieve the current sorting rule and set the select value
    useEffect(() => {
        if (!loanScroll.current) return;
        const rule = loanScroll.current?.sortingRules?.rules.find(
            (rule: LoanSortingRule) => {
                return rule.field.isCreatedAt();
            }
        );
        const value = `${rule.field.value}-${rule?.direction.value}`;
        setSelectedValue(value);
    });

    return (
        <>
            Sort by:
            <Select
                suffixIcon={<IconArrowDown />}
                className="my-loans__select"
                dropdownClassName="my-loans__subheader-tabs-dropdown"
                defaultValue={`${LOAN_SORTING_FIELD_CREATED_AT}-${ORDER_DIRECTION_DIRECTION_DESCENDING}`}
                value={selectedValue}
                onChange={handleSelectChange}
                disabled={loanScroll.current?.totalCount.value === 0}
                options={[
                    {
                        value: `${LOAN_SORTING_FIELD_CREATED_AT}-${ORDER_DIRECTION_DIRECTION_DESCENDING}`,
                        label: 'Newest'
                    },
                    {
                        value: `${LOAN_SORTING_FIELD_CREATED_AT}-${ORDER_DIRECTION_DIRECTION_ASCENDING}`,
                        label: 'Oldest'
                    }
                ]}
            />
        </>
    );
};

export default PartLoanSort;
