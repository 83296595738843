import { useEffect, useRef, useState } from 'react';
import House from '../icons/IconHouse';
import PartLoadingSpinner from '../parts/PartLoadingSpinner';
import { useSelector } from 'react-redux';
import authSliceState from '../../store/auth';
import { Tabs, message } from 'antd';
import { USER_ROLE_USER } from '../../domain/credentials/valueObject/UserRoleType';
import PartSingleLoanItem from '../parts/PartSingleLoanItem';
import { SingleLoanDataType } from '../../domain/loan/LoanViewMapper';
import PartSelect from '../parts/PartSelect';
import PartLoanStatusLabel from '../parts/PartLoanStatusLabel';
import Plus from '../icons/IconPlus';
import Download from '../icons/IconDownload';
import { useNavigate } from 'react-router-dom';
import LoanType, {
    DEAL_TYPE_MULTIFAMILY_BRIDGE,
    DEAL_TYPE_NO_DOC_DSCR,
    DEAL_TYPE_STABILIZED_BRIDGE
} from '../../domain/loan/valueObject/LoanType';
import { LoanTermViewData } from '../../domain/loanTerm/LoanTermViewMapper';

import _jsreport from '@jsreport/browser-client'; // For some reason types are wrong, that's why we are reassigning it to any
import { LoanTermService } from '../../domain/loanTerm/LoanTermService';
import { PartLoadingTextSpinner } from '../parts/PartLoadingTextSpinner';
const jsreport = _jsreport as any;

interface SingleLoanProps {
    loan: SingleLoanDataType | null;
    loanTerm: LoanTermViewData | null;
}

const BlockSingleLoan = ({ loan, loanTerm }: SingleLoanProps) => {
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
    const authState = useSelector(authSliceState);
    const navigate = useNavigate();
    const { TabPane } = Tabs;

    const [toggleClass, setToggleClass] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);
    const excludeElementsRef = useRef<HTMLElement[]>([]);

    const handleToggle = () => {
        setToggleClass(!toggleClass);
    };

    const addToExcludeElementsRef = (el: HTMLElement | null) => {
        if (el) {
            excludeElementsRef.current.push(el);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        const isExcludedElement = excludeElementsRef.current.some(
            (el) => el && el.contains(event.target as Node)
        );
        if (
            !isExcludedElement &&
            elementRef.current &&
            !elementRef.current.contains(event.target as Node)
        ) {
            setToggleClass(false);
        }
    };

    const showOptionsButton = (): boolean => {
        if (authState.userType !== USER_ROLE_USER) return true;
        if (loanTerm) return true;
        return false;
    };

    // TODO: Will need to update the payload with data from the loan and loanTerm
    const downloadLoanTermsPDF = async (
        loan: SingleLoanDataType | null,
        loanTerm: LoanTermViewData | null
    ) => {
        setIsDownloadingPDF(true);
        const loanTermService = new LoanTermService();
        try {
            jsreport.serverUrl = process.env.REACT_APP_JSREPORT_API_URL;
            jsreport.headers['Authorization'] =
                'Basic ' +
                btoa(process.env.REACT_APP_JSREPORT_API_AUTHORIZATION!);
            const report = await jsreport.render({
                template: {
                    shortid: process.env.REACT_APP_JSREPORT_API_SHORTID
                },
                data: loanTermService.createLoanTermReportPayload(
                    loan,
                    loanTerm
                )
            });

            report.download(
                `${loan?.address?.streetName} ${loan?.address?.city} ${loan?.address?.state} ${loan?.address?.zip} Quote.pdf`
            );
        } catch (error) {
            message.error('Failed to download the PDF');
            console.log(error);
        } finally {
            setIsDownloadingPDF(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="container">
            {loan === null ? (
                <PartLoadingSpinner />
            ) : (
                <>
                    <div className="single-loan__summary-header">
                        <div className="single-loan__summary-header-title-wrapp">
                            <h3 className="single-loan__summary-header-title">
                                <House />
                                {loan.address !== null
                                    ? `${loan.address?.streetName}, ${loan.address?.city}, ${loan.address?.state}, ${loan.address?.zip}`
                                    : '--'}
                            </h3>
                            <span className="single-loan__summary-pill">
                                {loan.loanType
                                    ? new LoanType(loan.loanType!).name
                                    : ''}
                            </span>
                        </div>

                        <div className="single-loan__summary-header-right-side-wrapper">
                            <span className="single-loan__summary-header-right-side-text">
                                Loan ID: {loan.id}
                            </span>
                            {authState.userType === USER_ROLE_USER ? (
                                <PartLoanStatusLabel
                                    status={loan.status}
                                    userRole={authState.userType}
                                />
                            ) : (
                                <div className="single-loan__summary-select-box">
                                    <PartSelect
                                        loanStatus={loan.status}
                                        loanId={loan.id}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="single-loan__lists-wrapper">
                        {showOptionsButton() && (
                            <div className="add-loan-terms">
                                <span
                                    ref={elementRef}
                                    className="add-loan-terms__dots"
                                    onClick={handleToggle}
                                >
                                    <span className="add-loan-term__dot"></span>
                                    <span className="add-loan-term__dot"></span>
                                    <span className="add-loan-term__dot"></span>
                                </span>
                                <div
                                    className={`button-wrapper ${
                                        toggleClass
                                            ? 'add-loan-terms__button--show'
                                            : ''
                                    }`}
                                >
                                    {authState.userType !== USER_ROLE_USER && (
                                        <button
                                            ref={addToExcludeElementsRef}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                navigate(
                                                    `/my-loans/${loan.id}/loan-terms`,
                                                    {
                                                        state: {
                                                            loan
                                                        }
                                                    }
                                                );
                                            }}
                                            className="add-loan-terms__button"
                                        >
                                            <Plus /> Add Loan Terms
                                        </button>
                                    )}
                                    {loanTerm !== null && (
                                        <button
                                            ref={addToExcludeElementsRef}
                                            className="add-loan-terms__button"
                                            disabled={isDownloadingPDF}
                                            onClick={() => {
                                                downloadLoanTermsPDF(
                                                    loan,
                                                    loanTerm
                                                );
                                            }}
                                        >
                                            {isDownloadingPDF ? (
                                                <PartLoadingTextSpinner />
                                            ) : (
                                                <Download />
                                            )}{' '}
                                            Download Loan Terms
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Loan Summary" key="1">
                                <div className="single-loan__lists-summary single-loan__lists-summary--full">
                                    <h3 className="single-loan__lists-summary-title">
                                        Loan Summary
                                    </h3>

                                    <div className="single-loan__lists-summary-items">
                                        <div className="single-loan__lists-summary-items-left">
                                            <PartSingleLoanItem
                                                title={'Borrower'}
                                                loanData={loan.borrower}
                                            />
                                            <PartSingleLoanItem
                                                title={'Email Address'}
                                                loanData={loan.borrowerEmail}
                                            />
                                            {/*
                                            {authState.userType ===
                                            USER_ROLE_USER ? null : (
                                                <>
                                                    <PartSingleLoanItem
                                                        title={'Lender'}
                                                        loanData={loan.lender}
                                                    />
                                                </>
                                            )}
                                            */}

                                            <PartSingleLoanItem
                                                title="Phone"
                                                loanData={loan.phone}
                                            />
                                            <PartSingleLoanItem
                                                title={'Asset Type'}
                                                loanData={loan.assetType}
                                            />
                                            <PartSingleLoanItem
                                                title={'Units'}
                                                loanData={loan.unitCount}
                                            />
                                            <PartSingleLoanItem
                                                title={'Loan Type'}
                                                loanData={
                                                    loan.loanType
                                                        ? new LoanType(
                                                              loan.loanType!
                                                          ).name
                                                        : ''
                                                }
                                            />

                                            <PartSingleLoanItem
                                                title={'Transaction Type'}
                                                loanData={loan.transactionType}
                                            />
                                            <PartSingleLoanItem
                                                title={'Application Date'}
                                                loanData={loan.createdAt}
                                            />
                                        </div>

                                        <div className="single-loan__lists-summary-items-right">
                                            <PartSingleLoanItem
                                                title={'Credit Score'}
                                                loanData={loan.creditScore}
                                            />
                                            <PartSingleLoanItem
                                                title={'Experience Level'}
                                                loanData={loan.experienceLevel}
                                            />

                                            <PartSingleLoanItem
                                                title="Purchase Price"
                                                loanData={loan.purchasePrice}
                                            />
                                            <PartSingleLoanItem
                                                title={'Original Purchase Date'}
                                                loanData={
                                                    loan.originalPurchaseDate
                                                }
                                            />

                                            <PartSingleLoanItem
                                                title={
                                                    'Original Purchase Price'
                                                }
                                                loanData={
                                                    loan.originalPurchasePrice
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={
                                                    'Rehab Amount Spent To Date'
                                                }
                                                loanData={
                                                    loan.rehabAmountSpentToDate
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={'Existing Debt'}
                                                loanData={loan.existingDebt}
                                            />

                                            <PartSingleLoanItem
                                                title={'Rehab Type'}
                                                loanData={loan.rehabType}
                                            />
                                            <PartSingleLoanItem
                                                title={
                                                    'Estimated After Repair Value'
                                                }
                                                loanData={
                                                    loan.estimatedAfterRepairValue
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={'Rehab Amount'}
                                                loanData={loan.rehabAmount}
                                            />

                                            <PartSingleLoanItem
                                                title={'Monthly Gross Rents'}
                                                loanData={
                                                    loan.monthlyGrossRents
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={'Taxes'}
                                                loanData={loan.taxes}
                                            />
                                            <PartSingleLoanItem
                                                title={'Insurance'}
                                                loanData={loan.insurance}
                                            />
                                            <PartSingleLoanItem
                                                title={'HOA'}
                                                loanData={loan.hoa}
                                            />
                                            <PartSingleLoanItem
                                                title={'Utilities'}
                                                loanData={loan.utilities}
                                            />
                                            <PartSingleLoanItem
                                                title={'Management Expenses'}
                                                loanData={
                                                    loan.managementExpenses
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={'Repairs & Maintenance'}
                                                loanData={
                                                    loan.repairsAndMaintenance
                                                }
                                            />
                                            <PartSingleLoanItem
                                                title={'Other Expenses'}
                                                loanData={loan.otherExpenses}
                                            />
                                            {/* <PartSingleLoanItem
                                                title={'Loan Amount'}
                                                loanData={loan.loanAmount}
                                            /> */}

                                            {/* <PartSingleLoanItem
                                                title={'Target closing date'}
                                                loanData={loan.closeDate}
                                            />

                                            <PartSingleLoanItem
                                                title={'Loan Originator'}
                                                loanData={loan.originator}
                                            />

                                            <PartSingleLoanItem
                                                title={'Loan Processor'}
                                                loanData={loan.processor}
                                            /> */}
                                            <PartSingleLoanItem
                                                title={'Estimated As Is Value'}
                                                loanData={
                                                    loan.estimatedAsIsValue
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                {loanTerm !== null && (
                                    <div className="single-loan__lists-summary-wrapper single-loan__lists-summary-wrapper--left">
                                        <div className="single-loan__lists-summary single-loan__lists-summary--half">
                                            <h3 className="single-loan__lists-summary-title">
                                                Loan Terms
                                            </h3>

                                            <div className="single-loan__lists-summary-items">
                                                <div className="single-loan__lists-summary-items-right">
                                                    <PartSingleLoanItem
                                                        title={'Loan Purpose'}
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .loanPurpose
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title={
                                                            'Initial Loan Amount'
                                                        }
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .initialLoanAmount
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title={
                                                            'Initial Loan Amount Purchase Price'
                                                        }
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .initialLoanAmountPurchasePrice
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title={
                                                            'Construction Holdback'
                                                        }
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .constructionHoldback
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title={
                                                            'Construction Holdback / Renovation Budget'
                                                        }
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .constructionHoldbackRenovationBudget
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title={
                                                            [
                                                                DEAL_TYPE_NO_DOC_DSCR,
                                                                DEAL_TYPE_STABILIZED_BRIDGE
                                                            ].includes(
                                                                loan.loanType!
                                                            )
                                                                ? 'Loan Amount'
                                                                : 'Total Loan Amount'
                                                        }
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .totalLoanAmount
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Recourse Guaranty Structure"
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .recourseGuarantyStructure
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Loan To Value"
                                                        loanData={
                                                            loanTerm.loanTerms
                                                                .loanToValue
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {loan.loanType !==
                                            DEAL_TYPE_NO_DOC_DSCR && (
                                            <div className="single-loan__lists-summary single-loan__lists-summary--half">
                                                <h3 className="single-loan__lists-summary-title">
                                                    Interest Mechanics
                                                </h3>
                                                <div className="single-loan__lists-summary-items">
                                                    <div className="single-loan__lists-summary-items-right">
                                                        <PartSingleLoanItem
                                                            title="Term"
                                                            loanData={
                                                                loanTerm
                                                                    .interestMechanics
                                                                    ?.interestMechanicTerm
                                                            }
                                                        />
                                                        {loan.loanType ===
                                                        DEAL_TYPE_MULTIFAMILY_BRIDGE ? (
                                                            <PartSingleLoanItem
                                                                title="Pre-Payment Penalty"
                                                                loanData={
                                                                    loanTerm
                                                                        .interestMechanics
                                                                        ?.interestMechanicsPrePaymentPenalty
                                                                }
                                                            />
                                                        ) : null}
                                                        <PartSingleLoanItem
                                                            title="Interest Accrual Method"
                                                            loanData={
                                                                loanTerm
                                                                    .interestMechanics
                                                                    ?.interestAccrualMethod
                                                            }
                                                        />
                                                        {loan.loanType !==
                                                        DEAL_TYPE_MULTIFAMILY_BRIDGE ? (
                                                            <PartSingleLoanItem
                                                                title="Pre-Payment Penalty"
                                                                loanData={
                                                                    loanTerm
                                                                        .interestMechanics
                                                                        ?.interestMechanicsPrePaymentPenalty
                                                                }
                                                            />
                                                        ) : null}
                                                        <PartSingleLoanItem
                                                            title="Interest Reserve"
                                                            loanData={
                                                                loanTerm
                                                                    .interestMechanics
                                                                    ?.interestReserve
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {loan.loanType ===
                                            DEAL_TYPE_NO_DOC_DSCR && (
                                            <div className="single-loan__lists-summary single-loan__lists-summary--half">
                                                <h3 className="single-loan__lists-summary-title">
                                                    Fees And Escrows
                                                </h3>

                                                <div className="single-loan__lists-summary-items">
                                                    <div className="single-loan__lists-summary-items-right">
                                                        <PartSingleLoanItem
                                                            title="Origination Fee(%)"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.originationFeePercentage
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Broker Fee(%)"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.brokerFeePercentage
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Underwriting Fee"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.underwritingFee
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Processing Fee"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.processingFee
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Legal Fee"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.legalFee
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="PITI Reserve"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.fePitiReserve
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Insurance Escrow"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.feInsuranceEscrowAmount
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            title="Tax Escrow"
                                                            loanData={
                                                                loanTerm
                                                                    .feesAndEscrows
                                                                    ?.feTaxEscrowAmount
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {loanTerm !== null && (
                                    <div className="single-loan__lists-summary-wrapper single-loan__lists-summary-wrapper--right">
                                        <div className="single-loan__lists-summary single-loan__lists-summary--half">
                                            <h3 className="single-loan__lists-summary-title">
                                                Pricing
                                            </h3>

                                            <div className="single-loan__lists-summary-items">
                                                <div className="single-loan__lists-summary-items-right">
                                                    <PartSingleLoanItem
                                                        key="interestRate"
                                                        title="Interest Rate"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.interestRate
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Origination Fee(%)"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.originationFeePercentage
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Exit Fee"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.exitFee
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Broker Fee(%)"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.brokerFeePercentage
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Underwriting Fee"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.underwritingFee
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Processing Fee"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.processingFee
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        title="Legal Fee"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.legalFee
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        key="rateType"
                                                        title="Rate type"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.rateType
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        key="amortizationType"
                                                        title="Amortization Type"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.amortizationType
                                                        }
                                                    />
                                                    <PartSingleLoanItem
                                                        key="pricingPrePaymentPenalty"
                                                        title="Pre-Payment Penalty"
                                                        loanData={
                                                            loanTerm.pricing
                                                                ?.pricingPrePaymentPenalty
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {loanTerm.closingConditions ? (
                                            <div className="single-loan__lists-summary single-loan__lists-summary--half">
                                                <h3 className="single-loan__lists-summary-title">
                                                    Closing Conditions
                                                </h3>
                                                <div className="single-loan__lists-summary-items">
                                                    <div className="single-loan__lists-summary-items-right">
                                                        <PartSingleLoanItem
                                                            key="ccLtv"
                                                            title="LTV"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccLtv
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccLtarv"
                                                            title="LTARV"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccLtarv
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccLtfc"
                                                            title="LTFC"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccLtfc
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccMinCreditScore"
                                                            title="Min Credit Score"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccMinCreditScore
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccTrackRecord"
                                                            title="Verified Properties"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccTrackRecord
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccLiquidity"
                                                            title="Liquidity"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccLiquidity
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccNetWorth"
                                                            title="Net Worth"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccNetWorth
                                                            }
                                                        />
                                                        <PartSingleLoanItem
                                                            key="ccDscr"
                                                            title="DSCR"
                                                            loanData={
                                                                loanTerm
                                                                    .closingConditions
                                                                    .ccDscr
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlockSingleLoan;
