import { useForm } from 'react-hook-form';
import BlockStepsButtons from '../BlockStepsButtons';
import {
    DEAL_TYPE_FIX_AND_FLIP,
    DEAL_TYPE_GROUND_UP,
    DEAL_TYPE_MULTIFAMILY_BRIDGE,
    DEAL_TYPE_NO_DOC_DSCR,
    DEAL_TYPE_STABILIZED_BRIDGE,
    FIELD_NAME_DEAL_TYPE_FIX_AND_FLIP,
    FIELD_NAME_DEAL_TYPE_GROUND_UP,
    FIELD_NAME_DEAL_TYPE_MULTIFAMILY_BRIDGE,
    FIELD_NAME_DEAL_TYPE_NO_DOC_DSCR,
    FIELD_NAME_DEAL_TYPE_STABILIZED_BRIDGE
} from '../../../domain/loan/valueObject/LoanType';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import PartRadioButton from '../../parts/PartRadioButton';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import { DEAL_TRANSACTION_TYPE_PURCHASE } from '../../../domain/loan/valueObject/DealTransactionType';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';

interface LoanTypeDetailsProps extends NewLoanApplicationStepsProps {}

const LoanTypeDetails = ({
    nextStep,
    prevStep,
    newLoanEntity
}: LoanTypeDetailsProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                loanType: yup.number().required('Please choose one option.')
            })
        )
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        const loanTypesToMonthlyGrossRentStep = [DEAL_TYPE_NO_DOC_DSCR];

        const nextStepToGo = loanTypesToMonthlyGrossRentStep.includes(
            data.loanType
        )
            ? NewLoanSteps.MONTHLY_GROSS_RENTS
            : NewLoanSteps.REHAB_TYPE;

        nextStep(nextStepToGo);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(
            newLoanEntity.transactionType.value ===
                DEAL_TRANSACTION_TYPE_PURCHASE
                ? NewLoanSteps.TRANSACTION_PURCHASE_PRICE
                : NewLoanSteps.EXISTING_DEBT
        );
    };

    useEffect(() => {
        newLoanEntity.hasLoanType() &&
            setValue('loanType', newLoanEntity.loanType.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    What type of loan are you looking for today?
                </h1>
                <p className="login__subtitle">Loan Type</p>
                <div className="login__box">
                    <PartRadioButton
                        name="loanType"
                        control={control}
                        errors={errors}
                        options={[
                            {
                                label: FIELD_NAME_DEAL_TYPE_FIX_AND_FLIP,
                                value: DEAL_TYPE_FIX_AND_FLIP
                            },
                            {
                                label: FIELD_NAME_DEAL_TYPE_GROUND_UP,
                                value: DEAL_TYPE_GROUND_UP
                            },
                            {
                                label: FIELD_NAME_DEAL_TYPE_MULTIFAMILY_BRIDGE,
                                value: DEAL_TYPE_MULTIFAMILY_BRIDGE
                            },
                            {
                                label: FIELD_NAME_DEAL_TYPE_STABILIZED_BRIDGE,
                                value: DEAL_TYPE_STABILIZED_BRIDGE
                            },
                            {
                                label: FIELD_NAME_DEAL_TYPE_NO_DOC_DSCR,
                                value: DEAL_TYPE_NO_DOC_DSCR
                            }
                        ]}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LoanTypeDetails;
