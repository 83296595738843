import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import MainFooter from '../components/main/MainFooter';
import MainHeader from '../components/main/MainHeader';
import authSliceState from '../store/auth';

const useAuth = () => {
    const isUserLoggedIn = localStorage.getItem('isLoggedIn');
    return isUserLoggedIn;
};

const PrivateOutlet = () => {
    const authState = useSelector(authSliceState);
    const isAuth = useAuth();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return authState.token && isAuth ? (
        <div className="main">
            <MainHeader />
            <main>
                <Outlet />
            </main>
            <MainFooter />
        </div>
    ) : (
        <Navigate replace to={'/login'} state={{ from: location }} />
    );
};

export default PrivateOutlet;
