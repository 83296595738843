import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const ASSET_TYPE_SFR = 1;
export const ASSET_TYPE_2_4_UNIT = 2;
export const ASSET_TYPE_MULTI_FAMILY = 3;
export const ASSET_TYPE_MIXED_USE = 4;

export const FIELD_NAME_ASSET_TYPE_SFR = 'SFR';
export const FIELD_NAME_ASSET_TYPE_2_4_UNIT = '2-4 unit';
export const FIELD_NAME_ASSET_TYPE_MULTI_FAMILY = 'Multifamily';
export const FIELD_NAME_ASSET_TYPE_MIXED_USE = 'Mixed-use';

export default class DealAssetType extends BaseValueObject {
    _value: any;
    _name: string;
    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableDealAssetTypes() {
        return [
            ASSET_TYPE_SFR,
            ASSET_TYPE_2_4_UNIT,
            ASSET_TYPE_MULTI_FAMILY,
            ASSET_TYPE_MIXED_USE
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case ASSET_TYPE_SFR:
                this._name = FIELD_NAME_ASSET_TYPE_SFR;
                break;
            case ASSET_TYPE_2_4_UNIT:
                this._name = FIELD_NAME_ASSET_TYPE_2_4_UNIT;
                break;
            case ASSET_TYPE_MULTI_FAMILY:
                this._name = FIELD_NAME_ASSET_TYPE_MULTI_FAMILY;
                break;
            case ASSET_TYPE_MIXED_USE:
                this._name = FIELD_NAME_ASSET_TYPE_MIXED_USE;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableDealAssetTypes().indexOf(this._value) < 0) {
            throw new Exception('Invalid Deal Asset Type', 400);
        }
    }
}
