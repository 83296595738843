import { Space } from 'antd';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Exclamation from '../icons/IconExclamation';
import ReactDateInputs from '@goran.cogic.fws/react-date-inputs';

interface InputFieldProps {
    name: string;
    label?: string;
    control: any;
    errors: Object;
    isRequired?: boolean;
    initialValue?: Date;
    validationMessage?: string;
}

const PartInputDate = ({
    name,
    label,
    control,
    errors,
    isRequired,
    initialValue,
    validationMessage
}: InputFieldProps) => {
    return (
        <div
            className={`form-element form-element--date ${
                name in errors ? 'form-element--error' : ''
            }`}
        >
            {label && (
                <label>
                    {label}
                    {isRequired && <span>{' *'}</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <ReactDateInputs
                        value={initialValue}
                        onChange={field.onChange}
                    />
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartInputDate;
