import Exception from '../../../utils/exceptions/Exception';
import Collection from '../../valueObject/Collection';

export default class ExperienceLevelType {
    static readonly TYPE_0_PROPERTIES = 1;
    static readonly TYPE_0_PROPERTIES_NAME = '0 Properties';
    static readonly TYPE_1_2_PROPERTIES = 2;
    static readonly TYPE_1_2_PROPERTIES_NAME = '1-2 Properties';
    static readonly TYPE_3_4_PROPERTIES = 3;
    static readonly TYPE_3_4_PROPERTIES_NAME = '3-4 Properties';
    static readonly TYPE_5_9_PROPERTIES = 4;
    static readonly TYPE_5_9_PROPERTIES_NAME = '5-9 Properties';
    static readonly TYPE_10_49_PROPERTIES = 5;
    static readonly TYPE_10_49_PROPERTIES_NAME = '10-49 Properties';
    static readonly TYPE_50_PLUS_PROPERTIES = 6;
    static readonly TYPE_50_PLUS_PROPERTIES_NAME = '50+ Properties';

    private _value: number;

    constructor(value: number | string) {
        if (Number.isNaN(+value) && typeof value === 'string') {
            this._value = ExperienceLevelType.getAvailableTypes().get(
                ExperienceLevelType.getAvailableTypeNames().findKey(
                    (item: any) => item === value
                )
            );
        } else if (!Number.isNaN(+value)) {
            this._value = +value;
        }

        this.validateValue();
    }

    get id(): number {
        return this._value;
    }

    get name(): string {
        return ExperienceLevelType.getAvailableTypeNames().get(
            ExperienceLevelType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            ExperienceLevelType.TYPE_0_PROPERTIES,
            ExperienceLevelType.TYPE_1_2_PROPERTIES,
            ExperienceLevelType.TYPE_3_4_PROPERTIES,
            ExperienceLevelType.TYPE_5_9_PROPERTIES,
            ExperienceLevelType.TYPE_10_49_PROPERTIES,
            ExperienceLevelType.TYPE_50_PLUS_PROPERTIES
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            ExperienceLevelType.TYPE_0_PROPERTIES_NAME,
            ExperienceLevelType.TYPE_1_2_PROPERTIES_NAME,
            ExperienceLevelType.TYPE_3_4_PROPERTIES_NAME,
            ExperienceLevelType.TYPE_5_9_PROPERTIES_NAME,
            ExperienceLevelType.TYPE_10_49_PROPERTIES_NAME,
            ExperienceLevelType.TYPE_50_PLUS_PROPERTIES_NAME
        ]);
    }

    private validateValue() {
        if (!ExperienceLevelType.getAvailableTypes().contains(this._value)) {
            throw new Exception('INVALID_REHAB_TYPE', 500);
        }
    }
}
