import React from 'react';
import {
    USER_ROLE_ADMIN,
    USER_ROLE_SUPER_ADMIN
} from '../../domain/credentials/valueObject/UserRoleType';
import PartLoanStatusLabel from '../parts/PartLoanStatusLabel';

interface BlockLoanCardProps {
    loan: any;
    userRole: number | null;
}

const BlockLoanCard = ({ loan, userRole }: BlockLoanCardProps) => {
    let userRoleClass = '';

    if (userRole === USER_ROLE_SUPER_ADMIN) {
        userRoleClass = 'loan-card--alt';
    }

    return (
        <div className={'loan-card ' + userRoleClass}>
            <div className="loan-card__item loan-card__item--id">
                <span className="loan-card__item-title">Loan ID</span>
                <span className="loan-card__item-value">{loan.id}</span>
                {/* Commented out because we don't have statuses right now, until the admins have a way of changing them */}
                {/* {userRole === USER_ROLE_SUPER_ADMIN ? (
                    <PartLoanStatusLabel
                        userRole={userRole}
                        status={loan.status}
                    />
                ) : null} */}
            </div>

            <div className="loan-card__item loan-card__item--address">
                <span className="loan-card__item-title">Property Address</span>
                <span
                    className="loan-card__item-value"
                    title={`${loan.address?.streetName}, ${loan.address?.city}, ${loan.address?.state}, ${loan.address?.zip}`}
                >
                    {loan.address !== null ? (
                        <>
                            <span className="loan-card__item-inline-value">
                                {loan.address?.streetName &&
                                    `${loan.address?.streetName}, `}
                            </span>
                            <span className="loan-card__item-inline-value">
                                {loan.address?.city}, {loan.address?.state},{' '}
                                {loan.address?.zip}
                            </span>
                        </>
                    ) : (
                        '--'
                    )}
                </span>
            </div>
            {userRole === USER_ROLE_ADMIN ||
            userRole === USER_ROLE_SUPER_ADMIN ? (
                <div className="loan-card__item loan-card__item--borrower">
                    <span className="loan-card__item-title">Borrower</span>
                    <span
                        className="loan-card__item-value"
                        title={loan.borrower}
                    >
                        {loan.borrower}
                    </span>
                </div>
            ) : (
                ''
            )}

            {/* {userRole === USER_ROLE_ADMIN ||
            userRole === USER_ROLE_SUPER_ADMIN ? (
                <div className="loan-card__item loan-card__item--lender">
                    <span className="loan-card__item-title">Lender</span>
                    <span className="loan-card__item-value" title={loan.lender}>
                        {loan.lender}
                    </span>
                </div>
            ) : null} */}
            <div className="loan-card__item loan-card__item--asset">
                <span className="loan-card__item-title">Asset Type</span>
                <span className="loan-card__item-value">{loan.assetType}</span>
            </div>

            <div className="loan-card__item loan-card__item--units">
                <span className="loan-card__item-title">Units</span>
                <span className="loan-card__item-value">{loan.unitCount}</span>
            </div>

            <div className="loan-card__item loan-card__item--loan-type">
                <span className="loan-card__item-title">Loan Type</span>
                <span className="loan-card__item-value">{loan.loanType}</span>
            </div>

            <div className="loan-card__item loan-card__item--transaction">
                <span className="loan-card__item-title">Transaction Type</span>
                <span className="loan-card__item-value">
                    {loan.transactionType}
                </span>
            </div>

            <div className="loan-card__item-bundle">
                <PartLoanStatusLabel userRole={userRole} status={loan.status} />
            </div>

            {/* <div className="loan-card__item loan-card__item--status">
                <span className="loan-card__item-value">
                    Pending
                </span>
            </div> */}

            {/* <div className="loan-card__item loan-card__item--amount">
                <span className="loan-card__item-title">Loan Amount</span>
                <span className="loan-card__item-value">{loan.loanAmount}</span>
            </div> */}

            {/* {userRole === USER_ROLE_SUPER_ADMIN ? (
                <div className="loan-card__item loan-card__item--originator">
                    <span className="loan-card__item-title">Originator</span>
                    <span className="loan-card__item-value">
                        {loan.originator}
                    </span>
                </div>
            ) : null} */}

            {/* {userRole === USER_ROLE_SUPER_ADMIN ? (
                <div className="loan-card__item loan-card__item--processor">
                    <span className="loan-card__item-title">Processor</span>
                    <span className="loan-card__item-value">
                        {loan.processor}
                    </span>
                </div>
            ) : null} */}
            {userRole !== USER_ROLE_SUPER_ADMIN ? (
                <div className="loan-card__item-bundle">
                    {/* <PartMesasgeCount messageCount={loan.messageCount} /> */}

                    {/* Commented out because we don't have statuses right now, until the admins have a way of changing them */}
                    {/* <PartLoanStatusLabel
                        userRole={userRole}
                        status={loan.status}
                    /> */}
                </div>
            ) : null}
        </div>
    );
};

export default BlockLoanCard;
