import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { commonSlice } from './common';

const reducer = combineReducers({
    auth: authSlice.reducer,
    common: commonSlice.reducer
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
