import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermRecourseGuarantyStructure extends BaseValueObject {
    _value: any;
    _name: string;

    static FULL_RECOURSE = 1;
    static NON_RECOURSE = 2;

    static FULL_RECOURSE_NAME = 'Recourse';
    static NON_RECOURSE_NAME = 'Non Recourse';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableRecourseGuarantyStructure() {
        return [
            LoanTermRecourseGuarantyStructure.FULL_RECOURSE,
            LoanTermRecourseGuarantyStructure.NON_RECOURSE
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermRecourseGuarantyStructure.FULL_RECOURSE:
                this._name =
                    LoanTermRecourseGuarantyStructure.FULL_RECOURSE_NAME;
                break;
            case LoanTermRecourseGuarantyStructure.NON_RECOURSE:
                this._name =
                    LoanTermRecourseGuarantyStructure.NON_RECOURSE_NAME;
                break;

            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (
            this.getAvailableRecourseGuarantyStructure().indexOf(this._value) <
            0
        ) {
            throw new Exception('Invalid Recourse Guaranty Structure', 400);
        }
    }
}
