export default class Exception {
    _message: string;
    _code: number;
    _data: any;
    constructor(message: string, code: number, data: any = {}) {
        this._message = message;
        this._code = code;
        this._data = data;
    }

    get data() {
        return this._data;
    }
}
