import { ErrorMessage } from '@hookform/error-message';
import { Radio, Space } from 'antd';
import { Control, Controller } from 'react-hook-form';
import Exclamation from '../icons/IconExclamation';
import { useState } from 'react';
import classNames from 'classnames';

interface RadioButtonFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    validationMessage?: string;
    defaultValue?: number;
    options: Array<{ label: string; value: number }>;
}

const PartRadioDotted = ({
    name,
    label,
    control,
    errors,
    isRequired,
    validationMessage,
    options,
    defaultValue
}: RadioButtonFieldProps) => {
    return (
        <div
            className={classNames('form-element form-element--dotted-radio', {
                'form-element--error': name in errors
            })}
        >
            {label && (
                <label>
                    {label}
                    {isRequired && <span>{' *'}</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Radio.Group {...field} defaultValue={defaultValue}>
                        {options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg form-element__error-msg--radio">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartRadioDotted;
