import { API } from '../../utils/http/ApiClient';
import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import { LoanTermEntity } from './LoanTermEntity';

export class LoanTermGateway {
    create(loanTerm: LoanTermEntity) {
        return API.post(
            '/LoanTerms/InsertLoanTerm',
            this._mapFormData(loanTerm)
        );
    }

    getLoanTerm(loanTermId: TypePositiveInteger) {
        return API.get(
            '/LoanTerms/GetLoanTerm',
            new Collection({ id: loanTermId.value })
        );
    }

    private _mapFormData(newLoanTerm: LoanTermEntity): Collection {
        const payload = new Collection({});

        if (newLoanTerm.hasLoanId()) {
            payload.set('loan_id', newLoanTerm.loanId.value);
        }
        if (newLoanTerm.hasLoanType()) {
            payload.set('loan_type', newLoanTerm.loanType.value);
        }
        // Pricing
        if (newLoanTerm.hasInitialLoanAmount()) {
            payload.set(
                'initial_loan_amount',
                newLoanTerm.initialLoanAmount.value
            );
        }
        if (newLoanTerm.hasInitialLoanAmountPurchasePrice()) {
            payload.set(
                'initial_loan_amount_purchase_price',
                newLoanTerm.initialLoanAmountPurchasePrice.value
            );
        }
        if (newLoanTerm.hasConstructionHoldback()) {
            payload.set(
                'construction_hold_back',
                newLoanTerm.constructionHoldback.value.toFixed(2)
            );
        }
        if (newLoanTerm.hasConstructionHoldbackRenovationBudget()) {
            payload.set(
                'construction_hold_back_renovation_budget',
                newLoanTerm.constructionHoldbackRenovationBudget.value.toFixed(
                    2
                )
            );
        }
        if (newLoanTerm.hasTotalLoanAmount()) {
            payload.set('total_loan_amount', newLoanTerm.totalLoanAmount.value);
        }
        if (newLoanTerm.hasInterestRate()) {
            payload.set(
                'interest_rate',
                newLoanTerm.interestRate.value.toFixed(2)
            );
        }

        if (newLoanTerm.hasLoanToValue()) {
            payload.set(
                'loan_to_value',
                newLoanTerm.loanToValue.value.toFixed(2)
            );
        }
        if (newLoanTerm.hasExitFee()) {
            payload.set('exit_fees', newLoanTerm.exitFee.value.toFixed(2));
        }
        // Interest Mechanics
        if (newLoanTerm.hasInterestMechanicTerm()) {
            payload.set(
                'interest_mechanic_term',
                newLoanTerm.interestMechanicTerm.value
            );
        }
        if (newLoanTerm.hasInterestAccrualMethod()) {
            payload.set(
                'interest_accrual_method',
                newLoanTerm.interestAccrualMethod.value
            );
        }
        if (newLoanTerm.hasInterestMechanicsPrePaymentPenalty()) {
            payload.set(
                'prepayment_penalty',
                newLoanTerm.interestMechanicsPrePaymentPenalty.value
            );
        }
        if (newLoanTerm.hasInterestReserve()) {
            payload.set('interest_reserve', newLoanTerm.interestReserve.value);
        }
        if (newLoanTerm.hasLoanPurpose()) {
            payload.set('loan_purpose', newLoanTerm.loanPurpose.value);
        }
        if (newLoanTerm.hasPricingPrePaymentPenalty()) {
            payload.set(
                'prepayment_penalty',
                newLoanTerm.pricingPrePaymentPenalty.value
            );
        }
        if (newLoanTerm.hasRateType()) {
            payload.set('rate_type', newLoanTerm.rateType.value);
        }
        if (newLoanTerm.hasAmortizationType()) {
            payload.set(
                'amortization_type',
                newLoanTerm.amortizationType.value
            );
        }
        if (newLoanTerm.hasRecourseGuarantyStructure()) {
            payload.set(
                'recourse_guaranty_structure',
                newLoanTerm.recourseGuarantyStructure.value
            );
        }

        // Closing Conditions
        if (newLoanTerm.hasCcLtv()) {
            payload.set('ltv', parseFloat(newLoanTerm.ccLtv.value).toFixed(2));
        }
        if (newLoanTerm.hasCcLtarv()) {
            payload.set(
                'ltarv',
                parseFloat(newLoanTerm.ccLtarv.value).toFixed(2)
            );
        }
        if (newLoanTerm.hasCcLtfc()) {
            payload.set(
                'ltfc',
                parseFloat(newLoanTerm.ccLtfc.value).toFixed(2)
            );
        }
        if (newLoanTerm.hasCcMinCreditScore()) {
            payload.set('min_credit_score', newLoanTerm.ccMinCreditScore.value);
        }
        if (newLoanTerm.hasCcTrackRecord()) {
            payload.set('track_record', newLoanTerm.ccTrackRecord.value);
        }
        if (newLoanTerm.hasCcLiquidity()) {
            payload.set('liquidity', newLoanTerm.ccLiquidity.value);
        }
        if (newLoanTerm.hasCcNetWorth()) {
            payload.set('net_worth', newLoanTerm.ccNetWorth.value);
        }
        if (newLoanTerm.hasCcDscr()) {
            payload.set('dscr', newLoanTerm.ccDscr.value.toFixed(2));
        }

        // Fees and Escrows
        if (newLoanTerm.hasFePitiReserve()) {
            payload.set('piti_reserve', newLoanTerm.fePitiReserve.value);
        }
        if (newLoanTerm.hasFeInsuranceEscrowAmount()) {
            payload.set(
                'insurance_escrow_amount',
                newLoanTerm.feInsuranceEscrowAmount.value
            );
        }
        if (newLoanTerm.hasFeTaxEscrowAmount()) {
            payload.set(
                'tax_escrow_amount',
                newLoanTerm.feTaxEscrowAmount.value
            );
        }
        if (newLoanTerm.hasUnderwritingFee()) {
            payload.set('underwriting_fee', newLoanTerm.underwritingFee.value);
        }
        if (newLoanTerm.hasProcessingFee()) {
            payload.set('processing_fee', newLoanTerm.processingFee.value);
        }
        if (newLoanTerm.hasLegalFee()) {
            payload.set('legal_fee', newLoanTerm.legalFee.value);
        }
        if (newLoanTerm.hasOriginationFeePercentage()) {
            payload.set(
                'origination_fee_percentage',
                newLoanTerm.originationFeePercentage.value.toFixed(2)
            );
        }
        if (newLoanTerm.hasBrokerFeePercentage()) {
            payload.set(
                'broker_fee_percentage',
                newLoanTerm.brokerFeePercentage.value.toFixed(2)
            );
        }
        return payload;
    }
}
