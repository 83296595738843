/* eslint-disable no-unused-vars */
export enum NewLoanSteps {
    CONTACT_DETAILS_INFO = 1,
    CONTACT_DETAILS_PROPERTY = 2,
    CREDIT_SCORE = 3,
    RENTAL_PROPERTY_EXPERIENCE = 4,
    TRANSACTION_TYPE = 5,
    TRANSACTION_PURCHASE_PRICE = 6,
    DATE_OF_PURCHASE = 7,
    REHAB_AMOUNT_SPEND = 8,
    LOAN_TYPE = 9,
    ESTIMATED_AS_IS_VALUE = 10,
    EXISTING_DEBT = 11,
    ORIGINAL_PURCHASE_PRICE = 12,
    REHAB_TYPE = 13,
    REHAB_AMOUNT = 14,
    MONTHLY_GROSS_RENTS = 15,
    MONTHLY_GROSS_RENTS_EXTENDED = 16,
    ESTIMATED_AFTER_REPAIR_VALUE = 17,
    SUBMIT_CONFIRMATION = 18
}
