import {
    DEAL_TYPE_FIX_AND_FLIP,
    DEAL_TYPE_GROUND_UP,
    DEAL_TYPE_MULTIFAMILY_BRIDGE,
    DEAL_TYPE_STABILIZED_BRIDGE,
    DEAL_TYPE_NO_DOC_DSCR
} from '../../../domain/loan/valueObject/LoanType';
import { LoanTermFormType1 } from './LoanTermForm1';
import { LoanTermFormType2 } from './LoanTermForm2';
import { LoanTermFormType3 } from './LoanTermForm3';
import { LoanTermFormType4 } from './LoanTermForm4';

export interface LoanTermFormProps {
    nextStep: () => void;
    loan: any;
    isFirstStep?: boolean;
}

const LoanTermTab = (props: LoanTermFormProps) => {
    const { loan } = props;
    const renderTermForm = () => {
        switch (true) {
            case [DEAL_TYPE_FIX_AND_FLIP, DEAL_TYPE_GROUND_UP].includes(
                props.loan.loanType
            ):
                return <LoanTermFormType1 {...props} />;
            case [DEAL_TYPE_MULTIFAMILY_BRIDGE].includes(loan.loanType):
                return <LoanTermFormType2 {...props} />;
            case [DEAL_TYPE_STABILIZED_BRIDGE].includes(loan.loanType):
                return <LoanTermFormType3 {...props} />;
            case [DEAL_TYPE_NO_DOC_DSCR].includes(loan.loanType):
                return <LoanTermFormType4 {...props} />;
            default:
                return null;
        }
    };

    return <div className="loan-terms__tab">{renderTermForm()}</div>;
};

export default LoanTermTab;
