import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import { monthlyGrossRentsExtValidationSchema } from '../../../utils/validationSchemas';
import PartInputField from '../../parts/PartInputField';
import BlockStepsButtons from '../BlockStepsButtons';

interface MonthlyGrossRentsExtendedProps extends NewLoanApplicationStepsProps {}

const MonthlyGrossRentsExtended = ({
    nextStep,
    prevStep,
    newLoanEntity
}: MonthlyGrossRentsExtendedProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(monthlyGrossRentsExtValidationSchema),
        shouldFocusError: false
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.ESTIMATED_AS_IS_VALUE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.MONTHLY_GROSS_RENTS);
    };

    useEffect(() => {
        newLoanEntity.hasTaxes() &&
            setValue('taxes', newLoanEntity.taxes.value);
        newLoanEntity.hasInsurance() &&
            setValue('insurance', newLoanEntity.insurance.value);
        newLoanEntity.hasUtilities() &&
            setValue('utilities', newLoanEntity.utilities.value);
        newLoanEntity.hasOtherExpenses() &&
            setValue('otherExpenses', newLoanEntity.otherExpenses.value);
        newLoanEntity.hasManagementExpenses() &&
            setValue(
                'managementExpenses',
                newLoanEntity.managementExpenses.value
            );
        newLoanEntity.hasrepairsMaintenance() &&
            setValue(
                'repairsMaintenance',
                newLoanEntity.repairsMaintenance.value
            );
        newLoanEntity.hasHoa() && setValue('hoa', newLoanEntity.hoa.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    How&#39;s your rental property expenses?
                </h1>
                <p className="login__subtitle">Annual Operating Expenses</p>
                <div className="login__box">
                    <PartInputField
                        label="Taxes"
                        name="taxes"
                        control={control}
                        errors={errors}
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        label="Insurance"
                        name="insurance"
                        control={control}
                        errors={errors}
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="HOA"
                        name="hoa"
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Utilities"
                        name="utilities"
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Management Expenses"
                        name="managementExpenses"
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Repairs & Maintenance"
                        name="repairsMaintenance"
                        isRequired
                        isDollarAmount={true}
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Other Expenses"
                        name="otherExpenses"
                        isRequired
                        isDollarAmount={true}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default MonthlyGrossRentsExtended;
