// React/Redux/Hooks
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import authSliceState from '../store/auth';
import useDocumentTitle from '../hooks/useDocumentTitle';
import store from '../store/storeIndex';
import commonSliceState, { setActiveLoanTab } from '../store/common';

// Component imports
import BlockLoanList from '../components/blocks/BlockLoanList';
import PartLoadingSpinner from '../components/parts/PartLoadingSpinner';
import PartNoLoansMessage from '../components/parts/PartNoLoansMessage';
import PartLoanSort from '../components/parts/PartLoanSort';
import PartNewLoanButton from '../components/parts/PartNewLoanButton';
import ArrowDown from '../components/icons/IconArrowUp';

// Domain imports
import LoanScrollEntity from '../domain/loanScroll/LoanScrollEntity';
import LoanScrollService from '../domain/loanScroll/LoanScrollService';
import LoanScrollViewMapper from '../domain/loanScroll/LoanScrollViewMapper';
import { USER_ROLE_USER } from '../domain/credentials/valueObject/UserRoleType';
import Collection from '../domain/valueObject/Collection';
import {
    PIPELINE_LOANS_FILTERS,
    MY_LOANS_FILTERS
} from '../domain/loan/valueObject/LoanStatusType';

import { Button, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PartMultiSelectFilter from '../components/parts/PartMultiSelectFilter';
import PartAutoCompleteSearchSelectField from '../components/parts/PartAutoCompleteSearchSelectField';

const MyLoans = () => {
    useDocumentTitle('My Loans');

    const [loans, setLoans] = useState([]);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    //Detecting scroll offset
    const [scrollOffset, setOffset] = useState(0);

    const loanScroll = useRef<LoanScrollEntity | undefined>(undefined);

    const authState = useSelector(authSliceState);
    const commonState = useSelector(commonSliceState);

    //Add here all the possible sorting filters, for status filters buttons
    const statusFilters = [
        { id: 'pipeline', name: 'Pipeline' },
        { id: 'my_loans', name: 'My Loans' }
    ];

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Active status chage for pipeline/active loans filters
    const activeStatusChange = (event: any) => {
        const { classList, id } = event.target;

        const statusFilterButtons = document.getElementsByClassName(
            'my-loans__subheader-tab'
        );
        for (let i = 0; i < statusFilterButtons.length; i++) {
            statusFilterButtons[i].classList.remove(
                'my-loans__subheader-tab--active'
            );
        }

        classList.add('my-loans__subheader-tab--active');

        switch (id) {
            case 'pipeline':
                loanScroll.current?.filterRules?.setLoanStatus(
                    new Collection(PIPELINE_LOANS_FILTERS)
                );
                store.dispatch(setActiveLoanTab('pipeline'));
                break;
            case 'my_loans':
                loanScroll.current?.filterRules?.setLoanStatus(
                    new Collection(MY_LOANS_FILTERS)
                );
                store.dispatch(setActiveLoanTab('my_loans'));
                break;
            default:
                break;
        }
    };
    const initializeScroll = async () => {
        loanScroll.current = await LoanScrollService.getLoanScroll();

        loanScroll.current?.afterChange('UpdatedItems', () => {
            if (loanScroll.current) {
                setLoans(
                    LoanScrollViewMapper.mapLoans(loanScroll.current.items)
                );
            }
        });

        loanScroll.current?.sortingRules?.afterChange(
            'UpdatedSortingRules',
            async () => {
                if (loanScroll.current) {
                    loanScroll.current.resetScroll();
                    await LoanScrollService.loadNextPage(loanScroll.current);
                }
            }
        );

        loanScroll.current?.filterRules?.afterChange(
            'UpdatedFilterRules',
            async () => {
                if (loanScroll.current) {
                    setIsNextPageLoading(true);
                    loanScroll.current.resetScroll();
                    await LoanScrollService.loadNextPage(loanScroll.current);
                    setIsNextPageLoading(false);
                }
            }
        );
    };

    const loadNextPage = async () => {
        loanScroll.current = await LoanScrollService.getLoanScroll();
        if (loanScroll.current?.canLoadMore() && !isNextPageLoading) {
            setIsNextPageLoading(true);
            await LoanScrollService.loadNextPage(loanScroll.current);
            setIsNextPageLoading(false);
        }
    };

    const setupScroll = async () => {
        await initializeScroll();
        initialLoad();
    };

    const initialLoad = () => {
        if (loanScroll.current) {
            // this case happens when we get back to the same page and the scroll already has the first page loaded
            // in this case, we only want to render items which are currently inside the scroll (persisted in RAM)
            if (loanScroll.current.isFirstPageLoaded())
                setLoans(
                    LoanScrollViewMapper.mapLoans(loanScroll.current.items)
                );
            else loadNextPage();
        }
    };

    useEffect(() => {
        setupScroll();
    }, []);

    //Detecting scroll offset
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    // if (!isLoansLoading && loanScroll.current?.totalCount.value === 0) {
    //     return (
    //         <div className="container">
    //             <PartNoLoansMessage />
    //         </div>
    //     );
    // } else {

    return (
        <div className="my-loans">
            <div className="container">
                <div className="my-loans__header">
                    <h2 className="my-loans__title">
                        {authState.userType === USER_ROLE_USER
                            ? 'My Loans '
                            : 'All Loans'}
                        <sup className="my-loans__title-counter">
                            {loanScroll.current?.totalCount.value}
                        </sup>
                    </h2>

                    <div className="my-loans__right-side">
                        <div className="my-loans__right-side-left">
                            <PartAutoCompleteSearchSelectField />
                        </div>
                        <div className="my-loans__right-side-right">
                            <PartMultiSelectFilter
                                loanScroll={loanScroll}
                                userRole={authState.userType}
                            />
                            <PartNewLoanButton authState={authState} />
                        </div>
                    </div>
                </div>
                <div className="my-loans__subheader">
                    <div className="my-loans__subheader-tabs-wrapper">
                        {statusFilters.map((filter, index) => (
                            <button
                                className={
                                    commonState.activeLoanTab === filter.id
                                        ? 'my-loans__subheader-tab my-loans__subheader-tab--active'
                                        : 'my-loans__subheader-tab'
                                }
                                key={index}
                                id={filter.id}
                                onClick={activeStatusChange}
                            >
                                {filter.name}
                            </button>
                        ))}
                    </div>
                    <div className="">
                        <PartLoanSort loanScroll={loanScroll} />
                    </div>
                </div>
                {loans.length > 0 ? (
                    <BlockLoanList
                        loans={loans}
                        userRole={authState.userType}
                    />
                ) : loanScroll.current?.isFirstPageLoaded() &&
                  loanScroll.current.items.isEmpty() ? (
                    <PartNoLoansMessage />
                ) : (
                    <PartLoadingSpinner />
                )}
                {loans.length > 0 &&
                loanScroll.current?.totalCount.value > 20 ? (
                    <div className="load-more-btn__wrapper">
                        <Button
                            disabled={!loanScroll.current?.canLoadMore()}
                            className="btn btn--small blue load-more-btn"
                            onClick={loadNextPage}
                        >
                            {isNextPageLoading ? (
                                <div className="infinite-scroll__icon">
                                    <LoadingOutlined />
                                </div>
                            ) : (
                                'Load More'
                            )}
                        </Button>
                    </div>
                ) : null}
            </div>
            <div
                className={
                    scrollOffset > 1100
                        ? 'back-to-top-btn show'
                        : 'back-to-top-btn'
                }
                onClick={scrollToTop}
            >
                <ArrowDown />
            </div>
        </div>
    );
};

export default MyLoans;
