import Exception from '../../../utils/exceptions/Exception';
import Collection from '../../valueObject/Collection';

export default class RehabType {
    static readonly TYPE_NO_REHAB = 1;
    static readonly TYPE_NO_REHAB_NAME = 'No Rehab';
    static readonly TYPE_LIGHT_REHAB = 2;
    static readonly TYPE_LIGHT_REHAB_NAME = 'Light Rehab';
    static readonly TYPE_HEAVY_REHAB = 3;
    static readonly TYPE_HEAVY_REHAB_NAME = 'Heavy Rehab';

    private _value: number;

    constructor(value: number | string) {
        if (Number.isNaN(+value) && typeof value === 'string') {
            this._value = RehabType.getAvailableTypes().get(
                RehabType.getAvailableTypeNames().findKey(
                    (item: any) => item === value
                )
            );
        } else if (!Number.isNaN(+value)) {
            this._value = +value;
        }

        this.validateValue();
    }

    get id(): number {
        return this._value;
    }

    get name(): string {
        return RehabType.getAvailableTypeNames().get(
            RehabType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            RehabType.TYPE_NO_REHAB,
            RehabType.TYPE_LIGHT_REHAB,
            RehabType.TYPE_HEAVY_REHAB
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            RehabType.TYPE_NO_REHAB_NAME,
            RehabType.TYPE_LIGHT_REHAB_NAME,
            RehabType.TYPE_HEAVY_REHAB_NAME
        ]);
    }

    private validateValue() {
        if (!RehabType.getAvailableTypes().contains(this._value)) {
            throw new Exception('INVALID_REHAB_TYPE', 500);
        }
    }
}
