const ArrowRightLong = () => {
    return (
        <svg width="16.526" height="13.167" viewBox="0 0 16.526 13.167">
            <g
                id="Eye_Show_Visible"
                data-name="Eye, Show, Visible"
                transform="translate(-1.737 -3.416)"
            >
                <path
                    id="Path_1331"
                    data-name="Path 1331"
                    d="M2.6,10.389a.822.822,0,0,1,0-.779C4.175,6.694,7.087,4.166,10,4.166s5.825,2.528,7.4,5.444a.822.822,0,0,1,0,.779c-1.577,2.916-4.489,5.443-7.4,5.443S4.175,13.305,2.6,10.389Z"
                    fill="none"
                    stroke="#6c7c65"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1332"
                    data-name="Path 1332"
                    d="M11.768,8.232a2.5,2.5,0,1,1-3.536,0,2.5,2.5,0,0,1,3.536,0"
                    fill="none"
                    stroke="#6c7c65"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.429"
                />
            </g>
        </svg>
    );
};

export default ArrowRightLong;
