import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import { LoanTermFactory } from './LoanTermFactory';
import { LoanTermGateway } from './LoanTermGateway';

export class LoanTermRepository {
    _gateway: LoanTermGateway;
    _factory: LoanTermFactory;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getLoanTerm(id: TypePositiveInteger) {
        const data = await this._gateway.getLoanTerm(id);
        return this._factory.reconstitute(new Collection(data));
    }
}
