const House = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.618"
            height="15.372"
            viewBox="0 0 15.618 15.372"
        >
            <g id="house-checkmark.1" transform="translate(-2.899 -3.146)">
                <path
                    id="Path_1159"
                    data-name="Path 1159"
                    d="M22.7,8.678V4.492a.746.746,0,0,0-.746-.746H20.569a.746.746,0,0,0-.746.746v1.72"
                    transform="translate(-6.488)"
                    fill="none"
                    stroke="#548943"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                />
                <path
                    id="Path_1160"
                    data-name="Path 1160"
                    d="M3.745,9.82,9.487,4.9a1.492,1.492,0,0,1,1.942,0L17.172,9.82"
                    transform="translate(0 -0.32)"
                    fill="none"
                    stroke="#548943"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                />
                <path
                    id="Path_1161"
                    data-name="Path 1161"
                    d="M5.353,12.015v7a1.491,1.491,0,0,0,1.492,1.492h3.516"
                    transform="translate(-0.649 -3.336)"
                    fill="none"
                    stroke="#548943"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                />
                <path
                    id="Path_1162"
                    data-name="Path 1162"
                    d="M19.607,22.964a3.357,3.357,0,1,1,3.357-3.357,3.357,3.357,0,0,1-3.357,3.357"
                    transform="translate(-5.046 -5.046)"
                    fill="none"
                    stroke="#548943"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                />
                <path
                    id="Path_1163"
                    data-name="Path 1163"
                    d="M22.307,20.767l-1.657,1.657-1-.994"
                    transform="translate(-6.42 -6.868)"
                    fill="none"
                    stroke="#548943"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                />
            </g>
        </svg>
    );
};

export default House;
