const CircleQeustion = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
        >
            <path d="M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm168-72c0-30.9 25.1-56 56-56h56c35.5 0 64 29 64 64.3c0 24-13.4 46.2-34.9 57.2L272 268.3V272v16H240V272 258.5v-9.8l8.7-4.5 45.8-23.4c10.7-5.4 17.5-16.6 17.5-28.7c0-17.8-14.4-32.3-32-32.3H224c-13.3 0-24 10.7-24 24v8H168v-8zm64 136h48v48H232V320z" />
        </svg>
    );
};

export default CircleQeustion;
