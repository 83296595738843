import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermInterestMechanicTerm extends BaseValueObject {
    _value: any;
    _name: string;
    static MONTHS_12 = 1;
    static MONTHS_18 = 2;
    static MONTHS_24 = 3;

    static MONTHS_12_NAME = '12 Months';
    static MONTHS_18_NAME = '18 Months';
    static MONTHS_24_NAME = '24 Months';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableInterestMechanicTerm() {
        return [
            LoanTermInterestMechanicTerm.MONTHS_12,
            LoanTermInterestMechanicTerm.MONTHS_18,
            LoanTermInterestMechanicTerm.MONTHS_24
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermInterestMechanicTerm.MONTHS_12:
                this._name = LoanTermInterestMechanicTerm.MONTHS_12_NAME;
                break;
            case LoanTermInterestMechanicTerm.MONTHS_18:
                this._name = LoanTermInterestMechanicTerm.MONTHS_18_NAME;
                break;
            case LoanTermInterestMechanicTerm.MONTHS_24:
                this._name = LoanTermInterestMechanicTerm.MONTHS_24_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableInterestMechanicTerm().indexOf(this._value) < 0) {
            throw new Exception('Invalid Interest Mechanic Term', 400);
        }
    }
}
