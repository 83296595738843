import { API } from '../../utils/http/ApiClient';
import LoanScrollFilterRulesEntity from '../loanFilterRule/valueObject/LoanScrollFilterRulesEntity';
import LoanSortingRule from '../loanSortingRule/LoanSortingRule';
import LoanSortingRulesEntity from '../loanSortingRule/LoanSortingRulesEntity';
import Collection from '../valueObject/Collection';
import TypeString from '../valueObject/TypeString';

export default class LoanScrollGateway {
    loadNextPage(
        page: number,
        perPage: number,
        sortingRules: LoanSortingRulesEntity | undefined,
        // eslint-disable-next-line no-unused-vars
        filterRules: LoanScrollFilterRulesEntity | undefined
    ) {
        const data = new Collection({});
        data.set('page', page);
        data.set('perPage', perPage);

        if (
            sortingRules instanceof LoanSortingRulesEntity &&
            sortingRules.hasRules()
        ) {
            data.set('sorting_rules', this._mapNextPageSorters(sortingRules));
        }

        if (filterRules instanceof LoanScrollFilterRulesEntity) {
            if (filterRules.hasLoanStatus())
                data.set(
                    'filter_rules',
                    new Collection({ status: filterRules.loanStatus })
                );
        }

        return API.get('Loans/ScrollLoans', data);
    }

    private _mapNextPageSorters(sortingRules: LoanSortingRulesEntity): any {
        return sortingRules.rules
            .map((rule: LoanSortingRule) => {
                return new Collection({
                    field: rule.field.value,
                    direction: rule.direction.value
                });
            })
            .toArray();
    }
}
