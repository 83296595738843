import BaseModel from '../BaseModel';
import Collection from './Collection';

export default abstract class BaseValueObject extends BaseModel {
    protected _value: any;
    _valueNames: Collection = new Collection({});

    constructor(value: any) {
        super();
        this._value = value;
    }

    // If you override one magic method, you must override both because JS will fallback to the child class and throw an exception.
    get value() {
        return this._value;
    }

    set value(value) {
        if (this._value === value) return;
        this._value = value;
        this._executeAfterChangeTriggers();
    }

    setValueName(key: number, name: any) {
        this._valueNames.set(key, name);
        return this;
    }

    getValueName() {
        return this._valueNames.get(this._value);
    }

    sameAs(valueObject: any) {
        return (
            valueObject instanceof BaseValueObject &&
            valueObject.value === this._value
        );
    }
}
