import UserRoleType from '../credentials/valueObject/UserRoleType';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';

export default class UserEntity {
    private _id: TypePositiveInteger;
    private _firstName: TypeString;
    private _lastName: TypeString;
    private _email: TypeString;
    private _phone: TypeString;
    private _type: UserRoleType;

    get id(): TypePositiveInteger {
        return this._id;
    }
    setId(value: TypePositiveInteger) {
        this._id = value;
        return this;
    }

    get firstName(): TypeString {
        return this._firstName;
    }
    setFirstName(value: TypeString) {
        this._firstName = value;
        return this;
    }

    public hasFirstName() {
        return this._firstName instanceof TypeString;
    }

    get lastName(): TypeString {
        return this._lastName;
    }
    setLastName(value: TypeString) {
        this._lastName = value;
        return this;
    }

    public hasLastName() {
        return this._lastName instanceof TypeString;
    }

    get email(): TypeString {
        return this._email;
    }

    setEmail(value: TypeString) {
        this._email = value;
        return this;
    }

    public hasEmail() {
        return this._email instanceof TypeString;
    }

    public get phone(): TypeString {
        return this._phone;
    }
    public setPhone(value: TypeString) {
        this._phone = value;
        return this;
    }

    public hasPhone() {
        return this._phone instanceof TypeString;
    }

    public get type(): UserRoleType {
        return this._type;
    }
    public setType(value: UserRoleType) {
        this._type = value;
        return this;
    }
}
