import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useDocumentTitle from '../hooks/useDocumentTitle';
import authSliceState from '../store/auth';

// Components Imports
import ContactDetailsInfo from '../components/blocks/NewLoanSteps/ContactDetailsInfo';
import ContactDetailsProperty from '../components/blocks/NewLoanSteps/ContactDetailsProperty';
import CreditScore from '../components/blocks/NewLoanSteps/CreditScore';
import DateOfPurchase from '../components/blocks/NewLoanSteps/DateOfPurchase';
import RehabAmountSpend from '../components/blocks/NewLoanSteps/RehabAmountSpend';
import RentalPropertyExperience from '../components/blocks/NewLoanSteps/RentalPropertyExperience';
import TransactionTypeDetails from '../components/blocks/NewLoanSteps/TransactionTypeDetails';
import TransactionPurchasePrice from '../components/blocks/NewLoanSteps/TransactionPurchasePrice';
import LoanTypeDetails from '../components/blocks/NewLoanSteps/LoanTypeDetails';
import EstimatedAsIsValue from '../components/blocks/NewLoanSteps/EstimatedAsIsValue';
import RehabTypeDetails from '../components/blocks/NewLoanSteps/RehabTypeDetails';
import EstimatedAfterRepairValue from '../components/blocks/NewLoanSteps/EstimatedAfterRepairValue';
import ExistingDebt from '../components/blocks/NewLoanSteps/ExistingDebt';
import OrigPurchasePrice from '../components/blocks/NewLoanSteps/OrigPurchasePrice';
import MonthlyGrossRents from '../components/blocks/NewLoanSteps/MonthlyGrossRents';
import MonthlyGrossRentsExtended from '../components/blocks/NewLoanSteps/MonthlyGrossRentsExtended';
import RehabAmount from '../components/blocks/NewLoanSteps/RehabAmount';

// Domain and Utils Imports
import LoanApplicationEntity from '../domain/newLoanApplication/LoanApplicationEntity';
import LoanApplicationService from '../domain/newLoanApplication/LoanApplicationService';
import { NewLoanSteps } from '../utils/enums';
import SubmitConfirmation from '../components/blocks/NewLoanSteps/SubmitConfirmation';

export interface NewLoanApplicationStepsProps {
    // eslint-disable-next-line no-unused-vars
    nextStep: (step: NewLoanSteps) => void;
    // eslint-disable-next-line no-unused-vars
    prevStep: (step: NewLoanSteps) => void;
    newLoanEntity: LoanApplicationEntity;
}

const NewLoanApplication = () => {
    useDocumentTitle('New Loan Application Form');
    const authState = useSelector(authSliceState);
    const [newLoanId, setNewLoanId] = useState('');

    const [currentStep, setCurrentStep] = useState(
        NewLoanSteps.CONTACT_DETAILS_INFO
    );

    const newLoanEntity = useRef<LoanApplicationEntity>(
        new LoanApplicationEntity()
    );

    const nextStep = (step: NewLoanSteps) => {
        setCurrentStep(step);
    };

    const prevStep = (step: NewLoanSteps) => {
        setCurrentStep(step);
    };

    const submitForm = async () => {
        try {
            const { id } = await LoanApplicationService.create(
                newLoanEntity.current
            );
            newLoanEntity.current = new LoanApplicationEntity(); // reset form by creating new instance
            setCurrentStep(NewLoanSteps.SUBMIT_CONFIRMATION);
            setNewLoanId(id.toString());
        } catch (err) {
            console.log(err, 'Error while trying to submit the form.');
        }
    };

    const renderSteps = () => {
        switch (currentStep) {
            case NewLoanSteps.CONTACT_DETAILS_INFO:
                return (
                    <ContactDetailsInfo
                        authState={authState}
                        nextStep={nextStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.CONTACT_DETAILS_PROPERTY:
                return (
                    <ContactDetailsProperty
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.CREDIT_SCORE:
                return (
                    <CreditScore
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.RENTAL_PROPERTY_EXPERIENCE:
                return (
                    <RentalPropertyExperience
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.TRANSACTION_TYPE:
                return (
                    <TransactionTypeDetails
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.TRANSACTION_PURCHASE_PRICE:
                return (
                    <TransactionPurchasePrice
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.DATE_OF_PURCHASE:
                return (
                    <DateOfPurchase
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.REHAB_AMOUNT:
                return (
                    <RehabAmount
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.REHAB_AMOUNT_SPEND:
                return (
                    <RehabAmountSpend
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.EXISTING_DEBT:
                return (
                    <ExistingDebt
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.ORIGINAL_PURCHASE_PRICE:
                return (
                    <OrigPurchasePrice
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.LOAN_TYPE:
                return (
                    <LoanTypeDetails
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.ESTIMATED_AS_IS_VALUE:
                return (
                    <EstimatedAsIsValue
                        nextStep={nextStep}
                        submitForm={submitForm}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.REHAB_TYPE:
                return (
                    <RehabTypeDetails
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.ESTIMATED_AFTER_REPAIR_VALUE:
                return (
                    <EstimatedAfterRepairValue
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.MONTHLY_GROSS_RENTS:
                return (
                    <MonthlyGrossRents
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.MONTHLY_GROSS_RENTS_EXTENDED:
                return (
                    <MonthlyGrossRentsExtended
                        nextStep={nextStep}
                        prevStep={prevStep}
                        newLoanEntity={newLoanEntity.current}
                    />
                );
            case NewLoanSteps.SUBMIT_CONFIRMATION:
                return <SubmitConfirmation newLoanId={newLoanId} />;
            default:
                return <div>Not found</div>;
        }
    };

    return renderSteps();
};

export default NewLoanApplication;
