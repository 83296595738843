import Collection from '../valueObject/Collection';
import CredentialsFactory from './CredentialsFactory';
import CredentialsGateway from './CredentialsGateway';

export default class CredentialsRepository {
    _gateway: CredentialsGateway;
    _factory: CredentialsFactory;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getMe() {
        const data = await this._gateway.getMe();

        return this._factory.reconstitute(new Collection(data));
    }
}
