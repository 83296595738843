import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const LOAN_SORTING_FIELD_CREATED_AT = 1;
export const LOAN_SORTING_FIELD_LOAN_AMOUNT = 2;

export default class LoanSortingField extends BaseValueObject {
    constructor(value: any) {
        super(value);
    }

    _validateValue() {
        if (
            this._value !== LOAN_SORTING_FIELD_CREATED_AT &&
            this._value !== LOAN_SORTING_FIELD_LOAN_AMOUNT
        )
            throw new Exception('Invalid direction value', 400, this._value);
    }

    isCreatedAt(): boolean {
        return this._value === LOAN_SORTING_FIELD_CREATED_AT;
    }

    isLoanAmount(): boolean {
        return this._value === LOAN_SORTING_FIELD_LOAN_AMOUNT;
    }
}
