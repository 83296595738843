import LoanFactory from '../loan/LoanFactory';
import { PIPELINE_LOANS_FILTERS } from '../loan/valueObject/LoanStatusType';
import LoanScrollFilterRulesEntity from '../loanFilterRule/valueObject/LoanScrollFilterRulesEntity';
import LoanSortingRule from '../loanSortingRule/LoanSortingRule';
import LoanSortingRulesEntity from '../loanSortingRule/LoanSortingRulesEntity';
import LoanSortingField, {
    LOAN_SORTING_FIELD_CREATED_AT
} from '../loanSortingRule/valueObject/LoanSortingField';
import Collection from '../valueObject/Collection';
import TypeOrderDirection, {
    ORDER_DIRECTION_DIRECTION_DESCENDING
} from '../valueObject/TypeOrderDirection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import LoanScrollEntity from './LoanScrollEntity';

export default class LoanScrollFactory {
    reconstitute(data: Collection) {
        const items = new Collection([]);

        data.extract('items').forEach((item: any) => {
            items.insert(new LoanFactory().reconstitute(new Collection(item)));
        });

        return new LoanScrollEntity(
            new TypePositiveInteger(data.get('total_item_count')),
            new TypePositiveInteger(data.get('current_item_count')),
            new TypePositiveInteger(data.get('page')),
            new TypePositiveInteger(data.get('per_page')),
            new TypePositiveInteger(data.get('total_page_count')),
            items
        );
    }

    static makeEmpty() {
        const instance = new LoanScrollEntity(
            new TypePositiveInteger(0),
            new TypePositiveInteger(0),
            new TypePositiveInteger(0),
            new TypePositiveInteger(20),
            new TypePositiveInteger(0),
            new Collection([])
        );

        return instance
            .setSortingRules(
                new LoanSortingRulesEntity().addRule(
                    new LoanSortingRule()
                        .setField(
                            new LoanSortingField(LOAN_SORTING_FIELD_CREATED_AT)
                        )
                        .setDirection(
                            new TypeOrderDirection(
                                ORDER_DIRECTION_DIRECTION_DESCENDING
                            )
                        )
                )
            )
            .setFilterRules(
                new LoanScrollFilterRulesEntity().setLoanStatus(
                    new Collection(PIPELINE_LOANS_FILTERS)
                )
            );
    }
}
