import { useEffect, useState } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useViewport } from '../hooks/useViewport';
import LoanTermDesktop from '../components/blocks/LoanTermSteps/LoanTermDesktop';
import ArrowRightLong from '../components/icons/IconArrowRightlong';
import LoanTermTab from '../components/blocks/LoanTermSteps/LoanTermTab';
import LoanTermMobile from '../components/blocks/LoanTermSteps/LoanTermsMobile';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    DEAL_TYPE_FIX_AND_FLIP,
    DEAL_TYPE_GROUND_UP,
    DEAL_TYPE_MULTIFAMILY_BRIDGE,
    DEAL_TYPE_STABILIZED_BRIDGE,
    DEAL_TYPE_NO_DOC_DSCR
} from '../domain/loan/valueObject/LoanType';

const LoanTerms = () => {
    useDocumentTitle('Loan Terms | Loan ${loan}'); // TODO: Add what document title?
    const navigate = useNavigate();
    const location = useLocation();
    const loan = (location.state as any)?.loan;
    const viewport = useViewport();
    const [isMobile, setIsMobile] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    function getLoanTermsTabList(loan: any = {}) {
        switch (loan.loanType) {
            case DEAL_TYPE_FIX_AND_FLIP:
            case DEAL_TYPE_MULTIFAMILY_BRIDGE:
                return [
                    {
                        key: '0',
                        label: 'Loan Terms',
                        children: (
                            <LoanTermTab
                                loan={loan}
                                nextStep={nextStep}
                                isFirstStep
                            />
                        )
                    }
                ];
            case DEAL_TYPE_GROUND_UP:
                return [
                    {
                        key: '0',
                        label: 'Loan Terms',
                        children: (
                            <LoanTermTab
                                loan={loan}
                                nextStep={nextStep}
                                isFirstStep
                            />
                        )
                    }
                ];
            case DEAL_TYPE_STABILIZED_BRIDGE:
                return [
                    {
                        key: '0',
                        label: 'Loan Terms',
                        children: (
                            <LoanTermTab
                                loan={loan}
                                nextStep={nextStep}
                                isFirstStep
                            />
                        )
                    }
                ];
            case DEAL_TYPE_NO_DOC_DSCR:
                return [
                    {
                        key: '0',
                        label: 'Loan Terms',
                        children: (
                            <LoanTermTab
                                loan={loan}
                                nextStep={nextStep}
                                isFirstStep
                            />
                        )
                    }
                ];
        }
        return [];
    }

    const tabList = getLoanTermsTabList(loan);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [currentTabIndex]);

    function nextStep() {
        const nextTab = tabList[currentTabIndex + 1];
        if (!nextTab) {
            navigate(`/my-loans/${loan.id}`);
            return;
        }
        setCurrentTabIndex(currentTabIndex + 1);
    }

    useEffect(() => {
        setIsMobile(viewport.width <= 640);
    }, [viewport]);

    useEffect(() => {
        if (!loan) navigate('/'); // TODO: handle case
    }, []);

    function handleTabChange(key: string) {
        if (!key) return;
        setCurrentTabIndex(parseInt(key));
    }

    return (
        <div className="loan-terms">
            {/* TODO (CS): This link should go back to the Single Loan but check with PM to be sure. */}
            <Link
                to={`/my-loans/${loan?.id}`}
                className="loan-terms__back-link"
            >
                <ArrowRightLong />
                Go Back
            </Link>
            {isMobile ? (
                <div className="loan-terms__mobile">
                    <LoanTermMobile
                        list={tabList}
                        activeTabIndex={currentTabIndex.toString()}
                        handleTabChange={handleTabChange}
                    />
                </div>
            ) : (
                <div className="loan-terms__desktop">
                    <LoanTermDesktop
                        list={tabList}
                        activeTabIndex={currentTabIndex.toString()}
                        handleTabChange={handleTabChange}
                    />
                </div>
            )}
        </div>
    );
};

export default LoanTerms;
