import AddressEntity from '../address/AddressEntity';
import UserEntity from '../user/UserEntity';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';
import DealAssetType from './valueObject/DealAssetType';
import DealTransactionType from './valueObject/DealTransactionType';
import LoanType from './valueObject/LoanType';
import LoanStatusType from './valueObject/LoanStatusType';
import BaseModel from '../BaseModel';
import TypeTimestamp from '../valueObject/TypeTimestamp';
import TypeFloat from '../valueObject/TypeFloat';
import ExperienceLevelType from './valueObject/ExperienceLevelType';
import RehabType from './valueObject/RehabType';
import TypeInteger from '../valueObject/TypeInteger';

export default class LoanEntity extends BaseModel {
    private _loanId: TypePositiveInteger;
    private _propertyAddress: AddressEntity;
    private _borrower: UserEntity;
    private _lender: TypeString;
    private _assetType: DealAssetType;
    private _units: TypePositiveInteger;
    private _loanType: LoanType;
    private _loanAmount: number;
    private _transactionType: DealTransactionType;
    private _processor: UserEntity;
    private _originator: UserEntity;
    private _loanStatus: LoanStatusType;
    private _closeDate: TypeTimestamp;
    private _phone: TypeString;
    private _creditScore: TypePositiveInteger;
    private _experienceLevel: ExperienceLevelType;
    private _estimatedAsIsValue: TypeFloat;
    private _purchasePrice: TypeFloat;
    private _originalPurchaseDate: TypeTimestamp;
    private _originalPurchasePrice: TypeFloat;
    private _rehabAmountSpentToDate: TypeFloat;
    private _existingDebt: TypeFloat;
    private _rehabType: RehabType;
    private _rehabAmount: TypeFloat;
    private _estimatedAfterRepairValue: TypeFloat;
    private _monthlyGrossRents: TypeFloat;
    private _taxes: TypeFloat;
    private _insurance: TypeFloat;
    private _utilities: TypeFloat;
    private _hoa: TypeFloat;
    private _repairsAndMaintenance: TypeFloat;
    private _otherExpenses: TypeFloat;
    private _managementExpenses: TypeFloat;
    private _createdAt: TypeTimestamp;
    private _loanTermId: TypePositiveInteger;

    public get loanId(): TypePositiveInteger {
        return this._loanId;
    }
    public setLoanId(value: TypePositiveInteger) {
        this._loanId = value;
        return this;
    }
    public hasLoanId() {
        return this._loanId instanceof TypePositiveInteger;
    }
    public get loanTermId(): TypePositiveInteger {
        return this._loanTermId;
    }
    public setLoanTermId(value: TypePositiveInteger) {
        this._loanTermId = value;
        return this;
    }
    public hasLoanTermId() {
        return this._loanTermId instanceof TypePositiveInteger;
    }
    public get propertyAddress(): AddressEntity {
        return this._propertyAddress;
    }
    public setPropertyAddress(value: AddressEntity) {
        this._propertyAddress = value;
        return this;
    }

    public hasPropertyAddress() {
        return this._propertyAddress instanceof AddressEntity;
    }
    public get borrower(): UserEntity {
        return this._borrower;
    }
    public setBorrower(value: UserEntity) {
        this._borrower = value;
        return this;
    }

    public hasBorrower() {
        return this._borrower instanceof UserEntity;
    }
    public get lender(): TypeString {
        return this._lender;
    }
    public setLender(value: TypeString) {
        this._lender = value;
        return this;
    }

    public hasLender() {
        return this._lender instanceof TypeString;
    }
    public get assetType(): any {
        return this._assetType;
    }
    public setAssetType(value: DealAssetType) {
        this._assetType = value;
        return this;
    }

    public hasAssetType() {
        return this._assetType instanceof DealAssetType;
    }

    public get units(): TypePositiveInteger {
        return this._units;
    }
    public setUnits(value: TypePositiveInteger) {
        this._units = value;
        return this;
    }

    public hasUnits() {
        return this._units instanceof TypePositiveInteger;
    }

    public get loanType(): LoanType {
        return this._loanType;
    }
    public setLoanType(value: LoanType) {
        this._loanType = value;
        return this;
    }

    public hasLoanType() {
        return this._loanType instanceof LoanType;
    }

    public get transactionType(): DealTransactionType {
        return this._transactionType;
    }
    public setTransactionType(value: DealTransactionType) {
        this._transactionType = value;
        return this;
    }

    public hasTransactionType() {
        return this._transactionType instanceof DealTransactionType;
    }

    public get loanAmount(): number {
        return this._loanAmount;
    }
    public setLoanAmount(value: number) {
        this._loanAmount = value;
        return this;
    }

    public hasLoanAmount() {
        return this._loanAmount !== undefined;
    }

    public get processor(): any {
        return this._processor;
    }
    public setProcessor(value: any) {
        this._processor = value;
        return this;
    }

    public hasProcessor() {
        return this._processor instanceof UserEntity;
    }
    public get originator(): UserEntity {
        return this._originator;
    }
    public setOriginator(value: any) {
        this._originator = value;
        return this;
    }
    public hasOriginator() {
        return this._originator instanceof UserEntity;
    }

    public get loanStatus(): LoanStatusType {
        return this._loanStatus;
    }
    public setLoanStatus(value: LoanStatusType) {
        this._loanStatus = value;
        return this;
    }
    public hasLoanStatus() {
        return this._loanStatus instanceof LoanStatusType;
    }

    public get closeDate(): TypeTimestamp {
        return this._closeDate;
    }
    public setCloseDate(value: TypeTimestamp) {
        this._closeDate = value;
        return this;
    }

    public hasCloseDate() {
        return this._closeDate instanceof TypeTimestamp;
    }

    public get phone(): TypeString {
        return this._phone;
    }

    public setPhone(value: TypeString) {
        this._phone = value;
        return this;
    }

    public hasPhone() {
        return this._phone instanceof TypeString;
    }

    public get creditScore(): TypePositiveInteger {
        return this._creditScore;
    }

    public setCreditScore(value: TypePositiveInteger) {
        this._creditScore = value;
        return this;
    }

    public hasCreditScore(): boolean {
        return this._creditScore instanceof TypePositiveInteger;
    }

    public get experienceLevel(): ExperienceLevelType {
        return this._experienceLevel;
    }

    public setExperienceLevel(value: ExperienceLevelType) {
        this._experienceLevel = value;
        return this;
    }

    public hasExperienceLevel(): boolean {
        return this._experienceLevel instanceof ExperienceLevelType;
    }

    public get estimatedAsIsValue(): TypeFloat {
        return this._estimatedAsIsValue;
    }

    public setEstimatedAsIsValue(value: TypeFloat) {
        this._estimatedAsIsValue = value;
        return this;
    }

    public hasEstimatedAsIsValue(): boolean {
        return this._estimatedAsIsValue instanceof TypeFloat;
    }

    public get purchasePrice(): TypeFloat {
        return this._purchasePrice;
    }

    public setPurchasePrice(value: TypeFloat) {
        this._purchasePrice = value;
        return this;
    }

    public hasPurchasePrice(): boolean {
        return this._purchasePrice instanceof TypeFloat;
    }

    public get originalPurchaseDate(): TypeTimestamp {
        return this._originalPurchaseDate;
    }

    public setOriginalPurchaseDate(value: TypeTimestamp) {
        this._originalPurchaseDate = value;
        return this;
    }

    public hasOriginalPurchaseDate(): boolean {
        return this._originalPurchaseDate instanceof TypeTimestamp;
    }

    public get originalPurchasePrice(): TypeFloat {
        return this._originalPurchasePrice;
    }

    public setOriginalPurchasePrice(value: TypeFloat) {
        this._originalPurchasePrice = value;
        return this;
    }

    public hasOriginalPurchasePrice(): boolean {
        return this._originalPurchasePrice instanceof TypeFloat;
    }

    public get rehabAmountSpentToDate(): TypeFloat {
        return this._rehabAmountSpentToDate;
    }

    public setRehabAmountSpentToDate(value: TypeFloat) {
        this._rehabAmountSpentToDate = value;
        return this;
    }

    public hasRehabAmountSpentToDate(): boolean {
        return this._rehabAmountSpentToDate instanceof TypeFloat;
    }

    public get existingDebt(): TypeFloat {
        return this._existingDebt;
    }

    public setExistingDebt(value: TypeFloat) {
        this._existingDebt = value;
        return this;
    }

    public hasExistingDebt(): boolean {
        return this._existingDebt instanceof TypeFloat;
    }

    public get rehabType(): RehabType {
        return this._rehabType;
    }

    public setRehabType(value: RehabType) {
        this._rehabType = value;
        return this;
    }

    public hasRehabType(): boolean {
        return this._rehabType instanceof RehabType;
    }

    public get rehabAmount(): TypeFloat {
        return this._rehabAmount;
    }

    public setRehabAmount(value: TypeFloat) {
        this._rehabAmount = value;
        return this;
    }

    public hasRehabAmount(): boolean {
        return this._rehabAmount instanceof TypeFloat;
    }

    public get estimatedAfterRepairValue(): TypeFloat {
        return this._estimatedAfterRepairValue;
    }

    public setEstimatedAfterRepairValue(value: TypeFloat) {
        this._estimatedAfterRepairValue = value;
        return this;
    }

    public hasEstimatedAfterRepairValue(): boolean {
        return this._estimatedAfterRepairValue instanceof TypeFloat;
    }

    public get monthlyGrossRents(): TypeFloat {
        return this._monthlyGrossRents;
    }

    public setMonthlyGrossRents(value: TypeFloat) {
        this._monthlyGrossRents = value;
        return this;
    }

    public hasMonthlyGrossRents(): boolean {
        return this._monthlyGrossRents instanceof TypeFloat;
    }

    public get taxes(): TypeFloat {
        return this._taxes;
    }

    public setTaxes(value: TypeFloat) {
        this._taxes = value;
        return this;
    }

    public hasTaxes(): boolean {
        return this._taxes instanceof TypeFloat;
    }

    public get insurance(): TypeFloat {
        return this._insurance;
    }

    public setInsurance(value: TypeFloat) {
        this._insurance = value;
        return this;
    }

    public hasInsurance(): boolean {
        return this._insurance instanceof TypeFloat;
    }

    public get utilities(): TypeFloat {
        return this._utilities;
    }

    public setUtilities(value: TypeFloat) {
        this._utilities = value;
        return this;
    }

    public hasUtilities(): boolean {
        return this._utilities instanceof TypeFloat;
    }

    public get hoa(): TypeFloat {
        return this._hoa;
    }

    public setHoa(value: TypeFloat) {
        this._hoa = value;
        return this;
    }

    public hasHoa(): boolean {
        return this._hoa instanceof TypeFloat;
    }

    public get repairsAndMaintenance(): TypeFloat {
        return this._repairsAndMaintenance;
    }

    public setRepairsAndMaintenance(value: TypeFloat) {
        this._repairsAndMaintenance = value;
        return this;
    }

    public hasRepairsAndMaintenance(): boolean {
        return this._repairsAndMaintenance instanceof TypeFloat;
    }

    public get otherExpenses(): TypeFloat {
        return this._otherExpenses;
    }

    public setOtherExpenses(value: TypeFloat) {
        this._otherExpenses = value;
        return this;
    }

    public hasOtherExpenses(): boolean {
        return this._otherExpenses instanceof TypeFloat;
    }

    public get managementExpenses(): TypeFloat {
        return this._managementExpenses;
    }

    public setManagementExpenses(value: TypeFloat) {
        this._managementExpenses = value;
        return this;
    }

    public hasManagementExpenses(): boolean {
        return this._managementExpenses instanceof TypeFloat;
    }

    public get createdAt(): TypeTimestamp {
        return this._createdAt;
    }
    public setCreatedAt(value: TypeTimestamp) {
        this._createdAt = value;
        return this;
    }

    public hasCreatedAt(): boolean {
        return this._createdAt instanceof TypeTimestamp;
    }
}
