import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class InterestMechanicsPrePaymentPenalty extends BaseValueObject {
    _value: any;
    _name: string;

    static ZERO = 7;
    static THREE_MONTHS = 8;
    static SIX_MONTHS = 9;
    static TWELVE_MONTHS = 10;

    static ZERO_NAME = '0';
    static THREE_MONTHS_NAME = '3 months';
    static SIX_MONTHS_NAME = '6 months';
    static TWELVE_MONTHS_NAME = '12 months';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    static getAvailablePrePaymentPenalty() {
        return [
            InterestMechanicsPrePaymentPenalty.ZERO,
            InterestMechanicsPrePaymentPenalty.THREE_MONTHS,
            InterestMechanicsPrePaymentPenalty.SIX_MONTHS,
            InterestMechanicsPrePaymentPenalty.TWELVE_MONTHS
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case InterestMechanicsPrePaymentPenalty.ZERO:
                this._name = InterestMechanicsPrePaymentPenalty.ZERO_NAME;
                break;
            case InterestMechanicsPrePaymentPenalty.THREE_MONTHS:
                this._name =
                    InterestMechanicsPrePaymentPenalty.THREE_MONTHS_NAME;
                break;
            case InterestMechanicsPrePaymentPenalty.SIX_MONTHS:
                this._name = InterestMechanicsPrePaymentPenalty.SIX_MONTHS_NAME;
                break;
            case InterestMechanicsPrePaymentPenalty.TWELVE_MONTHS:
                this._name =
                    InterestMechanicsPrePaymentPenalty.TWELVE_MONTHS_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (
            InterestMechanicsPrePaymentPenalty.getAvailablePrePaymentPenalty().indexOf(
                this._value
            ) < 0
        ) {
            throw new Exception(
                `Invalid Interest Mechanic Pre-Payment Penalty. Got '${this._value}'`,
                400
            );
        }
    }
}
