import { Button } from 'antd';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';

import ArrowRightLong from '../components/icons/IconArrowRightlong';
import IllustrationMan from '../assets/images/Illustration-man-bc.svg';

const ResetPasswordSuccess = () => {
    useDocumentTitle('Account Created');

    return (
        <div className="container">
            <div className="info-page__wrapper info-page__wrapper--alt">
                <div className="info-page__image reset-pass-success">
                    <img src={IllustrationMan} alt="Man Illustration" />
                    {/* <span className="info-page__image-icon">
                        <IllustrationMan />
                    </span> */}
                </div>
                <h1 className="page-info__title">
                    You have successfully reset
                    <br /> your password
                </h1>
                <Link to={'/login'}>
                    <Button className="btn blue">
                        Login
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default ResetPasswordSuccess;
