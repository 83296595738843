import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './storeIndex';

interface CommonInterface {
    activeLoanTab: 'pipeline' | 'my_loans' | undefined;
}

export const initialState: CommonInterface = {
    activeLoanTab: 'pipeline'
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setActiveLoanTab: (
            state,
            action: PayloadAction<'pipeline' | 'my_loans' | undefined>
        ) => {
            state.activeLoanTab = action.payload;
        }
    }
});

export const { setActiveLoanTab } = commonSlice.actions;

const commonSliceState = (state: RootState) => state.common;

export default commonSliceState;
