import { API, API_CLIENT_CONTENT_TYPE_JSON } from '../../utils/http/ApiClient';
import Collection from '../valueObject/Collection';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';

export default class LoanGateway {
    get(id: TypePositiveInteger) {
        return API.get('Loans/GetLoan', new Collection({ id: id.value }));
    }

    updateStatus(loanId: TypePositiveInteger, status: TypePositiveInteger) {
        return API.post(
            `/Loans/UpdateStatus`,
            new Collection({
                id: loanId,
                status
            }),
            {
                contentType: API_CLIENT_CONTENT_TYPE_JSON
            }
        );
    }
}
