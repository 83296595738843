import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import PartInputField from '../components/parts/PartInputField';
import { forgotPasswordValidationSchema } from '../utils/validationSchemas';
import CredentialsService from '../domain/credentials/CredentialsService';
import useDocumentTitle from '../hooks/useDocumentTitle';
import ArrowRightLong from '../components/icons/IconArrowRightlong';
import NotificationController from '../domain/notification/NotificationController';
import SuccessToastTemplate from '../domain/notification/notificationTemplate/SuccessToastTemplate';
import { useState } from 'react';
import InvalidToastMessage from '../domain/notification/notificationTemplate/InvalidToastMessage';

const ForgotPassword = () => {
    useDocumentTitle('Forgot Password');

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful, isSubmitting, isValid }
    } = useForm<any>({
        resolver: yupResolver(forgotPasswordValidationSchema),
        mode: 'onBlur'
    });

    const [buttonLoading, setButtonLoading] = useState(false);

    const onSubmit = async (data: any) => {
        try {
            setButtonLoading(true);
            await CredentialsService.sendResetPasswordRequest(data.email);
            NotificationController.notify(
                new SuccessToastTemplate(
                    'You have requested a password reset. Please check your email'
                )
            );
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage(
                    'There was an issue while trying to reset your password. Please try again or contact us if the problem persists.',
                    10
                )
            );
        } finally {
            setButtonLoading(false);
        }
    };

    return (
        <div className="forgot-password">
            <div className="container">
                <h1 className="forgot-password__title">
                    Forgot your password?
                </h1>
                <p className="forgot-password__subtitle">
                    No worries. <br />
                    Just enter your email below and we&#39;ll send you a link to
                    reset your password.
                </p>
                <div className="forgot-password__box">
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="Email"
                        name="email"
                    />

                    <Button
                        className="btn blue forgot-password__btn"
                        disabled={isSubmitSuccessful}
                        type="primary"
                        loading={buttonLoading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {isSubmitting && isValid
                            ? 'Submitting...'
                            : 'Request password reset'}
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </Button>
                    <div className="forgot-password__footer">
                        <p>
                            {"Didn't receive an email?"}{' '}
                            <a onClick={handleSubmit(onSubmit)} href="#">
                                Resend request
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <p className="forgot-password__subtitle small">
                You will only receive an email to reset if an account exists in
                our system.
            </p>
        </div>
    );
};

export default ForgotPassword;
