import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PartInputPassword from '../components/parts/PartInputPassword';
import PartInputField from '../components/parts/PartInputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { setupAccountValidationSchema } from '../utils/validationSchemas';
import { Button } from 'antd';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import ArrowRightLong from '../components/icons/IconArrowRightlong';
import useDocumentTitle from '../hooks/useDocumentTitle';
import CredentialsService from '../domain/credentials/CredentialsService';
import Collection from '../domain/valueObject/Collection';
import NotificationController from '../domain/notification/NotificationController';
import InvalidToastMessage from '../domain/notification/notificationTemplate/InvalidToastMessage';

interface FormData {
    email: string;
    password: string;
}

const SetupPassword = () => {
    useDocumentTitle('Create Password');
    const [conditionsMatched, setConditionsMatched] = useState({
        atLeast8Chars: false,
        atLeastNumber: false,
        atLeastCharacter: false
    });
    const [buttonLoading, setButtonLoading] = useState(false);

    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get('id');
    const token = searchParams.get('token');

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitting, isValid }
    } = useForm<any>({
        mode: 'onBlur',
        resolver: yupResolver(setupAccountValidationSchema)
    });

    const handleOnChangePassword = (event: any) => {
        const pwd: string = event.target.value;

        const conditions = { ...conditionsMatched };
        conditions.atLeast8Chars = false;
        conditions.atLeastCharacter = false;
        conditions.atLeastNumber = false;

        if (pwd.length >= 8) conditions.atLeast8Chars = true;
        if (/\d/.test(pwd)) conditions.atLeastNumber = true;
        //Check if special characters where typed
        if (/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pwd))
            conditions.atLeastCharacter = true;

        setConditionsMatched(conditions);
    };

    const onSubmit = async (data: FormData) => {
        try {
            setButtonLoading(true);
            await CredentialsService.setNewPassword(
                new Collection({
                    email: data.email,
                    token: token,
                    user_id: userId,
                    new_password: data.password
                })
            );
            navigate('/account-created', { replace: true });
        } catch (error) {
            NotificationController.notify(
                new InvalidToastMessage(
                    'Oops.. An error occured. Please try again'
                )
            );
        }
        setButtonLoading(false);
    };

    return (
        <div className="container">
            <div className="create-account">
                <h1 className="create-account__title">
                    Create a password to
                    <br /> access your NDL account
                </h1>
                <div className="create-account__req benefits-list">
                    <span className="create-account__req-item">
                        <span className="create-account__req-item-icon"></span>
                        Remain on top of active loans
                    </span>
                    <span className="create-account__req-item">
                        <span className="create-account__req-item-icon"></span>
                        Submit required documents -&nbsp;
                        <NavLink to={'/'}>Coming soon</NavLink>
                    </span>
                    <span className="create-account__req-item">
                        <span className="create-account__req-item-icon"></span>
                        Chat with your loan processor -&nbsp;
                        <NavLink to={'/'}>Coming soon</NavLink>
                    </span>
                </div>
                <div className="create-account__wrapper">
                    <PartInputField
                        control={control}
                        errors={errors}
                        label={'Email'}
                        name="email"
                    />
                    <PartInputPassword
                        control={control}
                        name="password"
                        errors={errors}
                        label="Password"
                        onChangeProps={handleOnChangePassword}
                        hideErrorMessage={!isSubmitted}
                    />
                    <div className="create-account__req">
                        <span className="create-account__req-title">
                            Password requirements:
                        </span>
                        <span className="create-account__req-item">
                            <span
                                className={
                                    conditionsMatched.atLeast8Chars
                                        ? 'create-account__req-item-icon green'
                                        : 'create-account__req-item-icon'
                                }
                            ></span>
                            Be at least 8 characters
                        </span>
                        <span className="create-account__req-item">
                            <span
                                className={
                                    conditionsMatched.atLeastNumber
                                        ? 'create-account__req-item-icon green'
                                        : 'create-account__req-item-icon'
                                }
                            ></span>
                            Have at least 1 number
                        </span>
                        <span className="create-account__req-item">
                            <span
                                className={
                                    conditionsMatched.atLeastCharacter
                                        ? 'create-account__req-item-icon green'
                                        : 'create-account__req-item-icon'
                                }
                            ></span>
                            Have at least 1 symbol
                        </span>
                    </div>
                    <Button
                        className="btn blue ant-btn ant-btn-primary login__btn"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isSubmitting}
                        loading={buttonLoading}
                    >
                        {isSubmitting && isValid
                            ? 'Creating Account...'
                            : 'Create Account'}
                        <span className="btn__icon create-account__btn">
                            <ArrowRightLong />
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SetupPassword;
