import { Collapse } from 'antd';
import { LoanTermsTabs, PanelType } from '../../../interfaces/accordion';

const { Panel } = Collapse;

const LoanTermMobile = ({
    list,
    handleTabChange,
    activeTabIndex
}: LoanTermsTabs) => {
    return (
        <div className="loan-terms__desktop-wrapper">
            <Collapse
                accordion
                activeKey={activeTabIndex}
                onChange={(key) => handleTabChange(key as string)}
            >
                {list.map((tab: PanelType) => (
                    <Panel header={tab.label} key={tab.key}>
                        {tab.children}
                    </Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default LoanTermMobile;
