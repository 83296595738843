import AddressFactory from '../address/AddressFactory';
import UserFactory from '../user/UserFactory';
import Collection from '../valueObject/Collection';
import TypeFloat from '../valueObject/TypeFloat';
import TypeInteger from '../valueObject/TypeInteger';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import TypeString from '../valueObject/TypeString';
import TypeTimestamp from '../valueObject/TypeTimestamp';
import LoanEntity from './LoanEntity';
import DealAssetType from './valueObject/DealAssetType';
import DealTransactionType from './valueObject/DealTransactionType';
import ExperienceLevelType from './valueObject/ExperienceLevelType';
import LoanStatusType from './valueObject/LoanStatusType';
import LoanType from './valueObject/LoanType';
import RehabType from './valueObject/RehabType';

export default class LoanFactory {
    reconstitute(data: Collection) {
        const loan = new LoanEntity();
        if (data.has('id'))
            loan.setLoanId(new TypePositiveInteger(data.get('id')));
        if (data.has('property_address'))
            loan.setPropertyAddress(
                AddressFactory.reconstitute(data.extract('property_address'))
            );
        if (data.has('transaction_type'))
            loan.setTransactionType(
                new DealTransactionType(data.get('transaction_type'))
            );
        if (data.has('loan_type'))
            loan.setLoanType(new LoanType(data.get('loan_type')));
        if (data.has('unit_count'))
            loan.setUnits(new TypePositiveInteger(data.get('unit_count')));
        if (data.has('asset_type'))
            loan.setAssetType(new DealAssetType(data.get('asset_type')));
        if (data.has('lender'))
            loan.setLender(new TypeString(data.get('lender')));
        if (data.has('originator'))
            loan.setOriginator(
                UserFactory.reconstitute(data.extract('originator'))
            );
        if (data.has('processor'))
            loan.setProcessor(
                UserFactory.reconstitute(data.extract('processor'))
            );
        if (data.has('status'))
            loan.setLoanStatus(new LoanStatusType(data.get('status')));
        if (data.has('borrower'))
            loan.setBorrower(
                UserFactory.reconstitute(data.extract('borrower'))
            );
        if (data.has('close_date'))
            loan.setCloseDate(new TypeTimestamp(data.get('close_date')));
        if (data.has('phone')) loan.setPhone(new TypeString(data.get('phone')));
        if (data.has('credit_score'))
            loan.setCreditScore(
                new TypePositiveInteger(data.get('credit_score'))
            );
        if (data.has('experience_level'))
            loan.setExperienceLevel(
                new ExperienceLevelType(data.get('experience_level'))
            );
        if (data.has('estimated_as_is_value'))
            loan.setEstimatedAsIsValue(
                new TypeFloat(data.get('estimated_as_is_value'))
            );
        if (data.has('purchase_price'))
            loan.setPurchasePrice(new TypeFloat(data.get('purchase_price')));
        if (data.has('original_purchase_date'))
            loan.setOriginalPurchaseDate(
                new TypeTimestamp(data.get('original_purchase_date'))
            );
        if (data.has('original_purchase_price'))
            loan.setOriginalPurchasePrice(
                new TypeFloat(data.get('original_purchase_price'))
            );
        if (data.has('rehab_amount_spent_to_date'))
            loan.setRehabAmountSpentToDate(
                new TypeFloat(data.get('rehab_amount_spent_to_date'))
            );
        if (data.has('existing_debt'))
            loan.setExistingDebt(new TypeFloat(data.get('existing_debt')));
        if (data.has('rehab_type'))
            loan.setRehabType(new RehabType(data.get('rehab_type')));
        if (data.has('rehab_amount'))
            loan.setRehabAmount(new TypeFloat(data.get('rehab_amount')));
        if (data.has('estimated_after_repair_value'))
            loan.setEstimatedAfterRepairValue(
                new TypeFloat(data.get('estimated_after_repair_value'))
            );
        if (data.has('monthly_gross_rents'))
            loan.setMonthlyGrossRents(
                new TypeFloat(data.get('monthly_gross_rents'))
            );
        if (data.has('taxes')) loan.setTaxes(new TypeFloat(data.get('taxes')));
        if (data.has('insurance'))
            loan.setInsurance(new TypeFloat(data.get('insurance')));
        if (data.has('annual_utilities'))
            loan.setUtilities(new TypeFloat(data.get('annual_utilities')));
        if (data.has('repairs_and_maintenance'))
            loan.setRepairsAndMaintenance(
                new TypeFloat(data.get('repairs_and_maintenance'))
            );
        if (data.has('other_expenses'))
            loan.setOtherExpenses(new TypeFloat(data.get('other_expenses')));
        if (data.has('management_expenses'))
            loan.setManagementExpenses(
                new TypeFloat(data.get('management_expenses'))
            );
        if (data.has('created_at'))
            loan.setCreatedAt(new TypeTimestamp(data.get('created_at')));
        if (data.has('loan_terms'))
            loan.setLoanTermId(new TypePositiveInteger(data.get('loan_terms')));
        if (data.has('hoa')) loan.setHoa(new TypeFloat(data.get('hoa')));

        return loan;
    }
}
