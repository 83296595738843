import {
    API,
    API_CLIENT_CONTENT_TYPE_FORM_DATA
} from '../../utils/http/ApiClient';
import Collection from '../valueObject/Collection';
import CredentialsEntity from './CredentialsEntity';

export default class CredentialsGateway {
    static login(credentials: CredentialsEntity): Promise<any> {
        const credentialCollection = new Collection({
            credentials: new Collection({
                representer: credentials.representer,
                type: credentials.type,
                payload: credentials.payload
            })
        });
        return API.post('Credentials/Login', credentialCollection, {
            contentType: API_CLIENT_CONTENT_TYPE_FORM_DATA
        });
    }

    static logout(): Promise<any> {
        return API.get('Credentials/Logout');
    }

    static sendResetPasswordRequest(email: string): Promise<any> {
        const emailPayload = new Collection({ email });
        return API.get('Credentials/SendResetPassword', emailPayload);
    }

    static setNewPassword(payload: Collection): Promise<any> {
        return API.put('Credentials/ResetPassword', payload);
    }

    static checkPasswordResetLink(
        id: string | null,
        token: string | null
    ): Promise<any> {
        return API.get(
            'Credentials/CheckResetPasswordLink',
            new Collection({
                id: id,
                token: token
            })
        );
    }

    getMe(): Promise<any> {
        return API.get('Credentials/Me');
    }
}
