import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class PricingPrePaymentPenalty extends BaseValueObject {
    _value: any;
    _name: string;

    static THREE_YEAR_3_2_1 = 1;
    static FOUR_YEAR = 2;
    static FIVE_YEAR = 3;
    static YIELD_MAINTENANCE = 4;
    static THREE_YEAR_3_0_0 = 5;
    static ONE_YEAR = 6;

    static THREE_YEAR_3_2_1_NAME = '3 Year(3-2-1)';
    static FOUR_YEAR_NAME = '4 Year(2-3-2-1)';
    static FIVE_YEAR_NAME = '5 Year(5-4-3-2-1)';
    static YIELD_MAINTENANCE_NAME = 'Yield Maintenance';
    static THREE_YEAR_3_0_0_NAME = '3 Year(3-0-0)';
    static ONE_YEAR_NAME = '1 Year(1-0-0)';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailablePrePaymentPenalty() {
        return [
            PricingPrePaymentPenalty.THREE_YEAR_3_2_1,
            PricingPrePaymentPenalty.FOUR_YEAR,
            PricingPrePaymentPenalty.FIVE_YEAR,
            PricingPrePaymentPenalty.YIELD_MAINTENANCE,
            PricingPrePaymentPenalty.THREE_YEAR_3_0_0,
            PricingPrePaymentPenalty.ONE_YEAR
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case PricingPrePaymentPenalty.THREE_YEAR_3_2_1:
                this._name = PricingPrePaymentPenalty.THREE_YEAR_3_2_1_NAME;
                break;
            case PricingPrePaymentPenalty.FOUR_YEAR:
                this._name = PricingPrePaymentPenalty.FOUR_YEAR_NAME;
                break;
            case PricingPrePaymentPenalty.FIVE_YEAR:
                this._name = PricingPrePaymentPenalty.FIVE_YEAR_NAME;
                break;
            case PricingPrePaymentPenalty.YIELD_MAINTENANCE:
                this._name = PricingPrePaymentPenalty.YIELD_MAINTENANCE_NAME;
                break;
            case PricingPrePaymentPenalty.THREE_YEAR_3_0_0:
                this._name = PricingPrePaymentPenalty.THREE_YEAR_3_0_0_NAME;
                break;
            case PricingPrePaymentPenalty.ONE_YEAR:
                this._name = PricingPrePaymentPenalty.ONE_YEAR_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailablePrePaymentPenalty().indexOf(this._value) < 0) {
            throw new Exception('Invalid Pre-Payment Penalty', 400);
        }
    }
}
