import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlockLoanCard from './BlockLoanCard';

interface BlockLoanListProps {
    loans: Array<Object>;
    userRole: number | null;
}

const BlockLoanList = ({ loans, userRole }: BlockLoanListProps) => {
    const navigate = useNavigate();
    return (
        <>
            {loans.length > 0 ? (
                <>
                    {loans.map((loan: any, index: number) => (
                        <div
                            className="loan-card__wrapper"
                            key={index}
                            onClick={() => navigate(`/my-loans/${loan.id}`)}
                        >
                            <BlockLoanCard loan={loan} userRole={userRole} />
                        </div>
                    ))}
                </>
            ) : null}
        </>
    );
};

export default BlockLoanList;
