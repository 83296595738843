import React, { useEffect, useMemo, useState } from 'react';
import Select, { components, ValueContainerProps } from 'react-select';
import LoanStatusType, {
    DEAL_STATUS_LOST,
    DEAL_STATUS_COMMITTEE,
    DEAL_STATUS_DUE_DILIGENCE,
    DEAL_STATUS_FUNDED,
    DEAL_STATUS_LEGAL,
    DEAL_STATUS_NAME_LOST,
    DEAL_STATUS_NAME_COMMITTEE,
    DEAL_STATUS_NAME_DUE_DILIGENCE,
    DEAL_STATUS_NAME_FUNDED,
    DEAL_STATUS_NAME_LEGAL,
    DEAL_STATUS_NAME_ON_HOLD,
    DEAL_STATUS_NAME_PENDING,
    DEAL_STATUS_NAME_UNDERWRITING,
    DEAL_STATUS_ON_HOLD,
    DEAL_STATUS_PENDING,
    DEAL_STATUS_UNDERWRITING,
    MY_LOANS_FILTERS,
    PIPELINE_LOANS_FILTERS,
    DEAL_STATUS_QUOTES_ORIGINATION,
    DEAL_STATUS_NAME_QUOTES_ORIGINATION
} from '../../domain/loan/valueObject/LoanStatusType';
import LoanScrollEntity from '../../domain/loanScroll/LoanScrollEntity';
import Collection from '../../domain/valueObject/Collection';
import { USER_ROLE_USER } from '../../domain/credentials/valueObject/UserRoleType';
import { AuthInterface } from '../../store/auth';
import { useSelector } from 'react-redux';
import commonSliceState from '../../store/common';

interface PartMultiSelectProps {
    loanScroll: React.RefObject<LoanScrollEntity | undefined>;
    userRole: AuthInterface['userType'];
}
type OptionType = {
    label: string;
    value: number;
};

const ValueContainer: any = ({
    children,
    ...props
}: ValueContainerProps<OptionType>) => {
    // eslint-disable-next-line prefer-const
    let [values, input] = children as any;

    if (Array.isArray(values)) {
        values = (
            <>
                Status{' '}
                <span className="select-control-status-indicator">
                    {values.length}
                </span>
            </>
        );
    }

    return (
        <components.ValueContainer className="select-control" {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

const Option = (props: any) => {
    return (
        <div className="select-status-wrapper">
            <components.Option {...props}>
                <input
                    className="select-checkbox"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <span className="custom-checkbox"></span>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const PartMultiSelectFilter = ({
    loanScroll,
    userRole
}: PartMultiSelectProps) => {
    const filtrationTab = useSelector(commonSliceState).activeLoanTab;
    const [showClearFilterButton, setShowClearFilterButton] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<any>([]);
    const [selectActive, setselectActive] = useState(false);
    const options = useMemo(
        () => getFilteringOptions(filtrationTab, userRole),
        [filtrationTab, userRole]
    );

    const handleClearFilterButtonClick = () => {
        setSelectedFilter([]);
        setShowClearFilterButton(false);
        setselectActive(false);
        loanScroll.current?.filterRules?.setLoanStatus(
            new Collection(
                filtrationTab === 'pipeline'
                    ? PIPELINE_LOANS_FILTERS
                    : MY_LOANS_FILTERS
            )
        );
    };

    useEffect(() => {
        setSelectedFilter([]);
    }, [filtrationTab]);

    const handleSelectChange = (selectedFilters: any) => {
        selectedFilters.length > 0
            ? setShowClearFilterButton(true)
            : setShowClearFilterButton(false);

        setSelectedFilter(selectedFilters);
        const statuses: LoanStatusType[] = selectedFilters.map(
            (filter: any) => new LoanStatusType(filter.value)
        );
        loanScroll.current?.filterRules?.setLoanStatus(
            new Collection(
                statuses.length > 0
                    ? statuses
                    : filtrationTab === 'pipeline'
                    ? PIPELINE_LOANS_FILTERS
                    : MY_LOANS_FILTERS
            )
        );
        setselectActive(true);
    };

    return (
        <>
            {showClearFilterButton && (
                <button
                    className="my-loans__right-side-clear"
                    onClick={handleClearFilterButtonClick}
                >
                    Clear filter
                </button>
            )}

            <Select
                className={
                    selectActive && showClearFilterButton
                        ? `select-wrapper active`
                        : `select-wrapper`
                }
                value={selectedFilter}
                onChange={(e: any) => handleSelectChange(e)}
                options={options}
                isMulti
                isClearable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect
                placeholder="Status"
                components={{
                    ValueContainer,
                    Option,
                    IndicatorSeparator: () => null
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#F5F7FA',
                        primary: 'transparent'
                    }
                })}
            />
        </>
    );
};

export default PartMultiSelectFilter;

function getFilteringOptions(
    tabName: 'pipeline' | 'my_loans' | undefined,
    userRole: AuthInterface['userType']
): Array<OptionType> {
    switch (tabName) {
        case 'my_loans':
            if (userRole === USER_ROLE_USER) {
                return [
                    {
                        label: DEAL_STATUS_NAME_FUNDED,
                        value: DEAL_STATUS_FUNDED
                    }
                ];
            }
            return [
                {
                    label: DEAL_STATUS_NAME_FUNDED,
                    value: DEAL_STATUS_FUNDED
                },
                {
                    label: DEAL_STATUS_NAME_ON_HOLD,
                    value: DEAL_STATUS_ON_HOLD
                },
                {
                    label: DEAL_STATUS_NAME_LOST,
                    value: DEAL_STATUS_LOST
                }
            ];
        default:
            return [
                {
                    label: DEAL_STATUS_NAME_PENDING,
                    value: DEAL_STATUS_PENDING
                },
                {
                    label: DEAL_STATUS_NAME_QUOTES_ORIGINATION,
                    value: DEAL_STATUS_QUOTES_ORIGINATION
                },
                {
                    label: DEAL_STATUS_NAME_DUE_DILIGENCE,
                    value: DEAL_STATUS_DUE_DILIGENCE
                },
                {
                    label: DEAL_STATUS_NAME_UNDERWRITING,
                    value: DEAL_STATUS_UNDERWRITING
                },
                {
                    label: DEAL_STATUS_NAME_COMMITTEE,
                    value: DEAL_STATUS_COMMITTEE
                },
                {
                    label: DEAL_STATUS_NAME_LEGAL,
                    value: DEAL_STATUS_LEGAL
                }
            ];
    }
}
