import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const DEAL_STATUS_PENDING = 1;
export const DEAL_STATUS_QUOTES_ORIGINATION = 2;
export const DEAL_STATUS_DUE_DILIGENCE = 3;
export const DEAL_STATUS_UNDERWRITING = 4;
export const DEAL_STATUS_COMMITTEE = 5;
export const DEAL_STATUS_LEGAL = 6;
export const DEAL_STATUS_FUNDED = 7;
export const DEAL_STATUS_ON_HOLD = 8;
export const DEAL_STATUS_LOST = 9;

export const DEAL_STATUS_NAME_PENDING = 'Pending';
export const DEAL_STATUS_NAME_QUOTES_ORIGINATION = 'Quotes & Origination';
export const DEAL_STATUS_NAME_DUE_DILIGENCE = 'Due diligence';
export const DEAL_STATUS_NAME_UNDERWRITING = 'Underwriting';
export const DEAL_STATUS_NAME_COMMITTEE = 'Committee';
export const DEAL_STATUS_NAME_LEGAL = 'Legal';
export const DEAL_STATUS_NAME_FUNDED = 'Funded';
export const DEAL_STATUS_NAME_ON_HOLD = 'On Hold';
export const DEAL_STATUS_NAME_LOST = 'Lost';

export const PIPELINE_LOANS_FILTERS = [
    DEAL_STATUS_PENDING,
    DEAL_STATUS_QUOTES_ORIGINATION,
    DEAL_STATUS_DUE_DILIGENCE,
    DEAL_STATUS_UNDERWRITING,
    DEAL_STATUS_COMMITTEE,
    DEAL_STATUS_LEGAL
];

export const MY_LOANS_FILTERS = [
    DEAL_STATUS_FUNDED,
    DEAL_STATUS_ON_HOLD,
    DEAL_STATUS_LOST
];

export const phase1LoanStatusValues = [DEAL_STATUS_PENDING];

export const phase2LoanStatusValues = [
    DEAL_STATUS_QUOTES_ORIGINATION,
    DEAL_STATUS_DUE_DILIGENCE,
    DEAL_STATUS_UNDERWRITING,
    DEAL_STATUS_COMMITTEE,
    DEAL_STATUS_LEGAL
];

export const phase3LoanStatusValues = [DEAL_STATUS_FUNDED];

export const phase4LoanStatusValues = [DEAL_STATUS_ON_HOLD, DEAL_STATUS_LOST];

export const loanStatusMapper: Record<number, string> = {
    [DEAL_STATUS_PENDING]: DEAL_STATUS_NAME_PENDING,
    [DEAL_STATUS_QUOTES_ORIGINATION]: DEAL_STATUS_NAME_QUOTES_ORIGINATION,
    [DEAL_STATUS_DUE_DILIGENCE]: DEAL_STATUS_NAME_DUE_DILIGENCE,
    [DEAL_STATUS_UNDERWRITING]: DEAL_STATUS_NAME_UNDERWRITING,
    [DEAL_STATUS_COMMITTEE]: DEAL_STATUS_NAME_COMMITTEE,
    [DEAL_STATUS_LEGAL]: DEAL_STATUS_NAME_LEGAL,
    [DEAL_STATUS_FUNDED]: DEAL_STATUS_NAME_FUNDED,
    [DEAL_STATUS_ON_HOLD]: DEAL_STATUS_NAME_ON_HOLD,
    [DEAL_STATUS_LOST]: DEAL_STATUS_NAME_LOST
};

export default class LoanStatusType extends BaseValueObject {
    _value: any;
    _name: string;
    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableDealTypes() {
        return [
            DEAL_STATUS_PENDING,
            DEAL_STATUS_QUOTES_ORIGINATION,
            DEAL_STATUS_DUE_DILIGENCE,
            DEAL_STATUS_UNDERWRITING,
            DEAL_STATUS_COMMITTEE,
            DEAL_STATUS_LEGAL,
            DEAL_STATUS_FUNDED,
            DEAL_STATUS_ON_HOLD,
            DEAL_STATUS_LOST
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case DEAL_STATUS_PENDING:
                this._name = DEAL_STATUS_NAME_PENDING;
                break;
            case DEAL_STATUS_QUOTES_ORIGINATION:
                this._name = DEAL_STATUS_NAME_QUOTES_ORIGINATION;
                break;
            case DEAL_STATUS_DUE_DILIGENCE:
                this._name = DEAL_STATUS_NAME_DUE_DILIGENCE;
                break;
            case DEAL_STATUS_UNDERWRITING:
                this._name = DEAL_STATUS_NAME_UNDERWRITING;
                break;
            case DEAL_STATUS_COMMITTEE:
                this._name = DEAL_STATUS_NAME_COMMITTEE;
                break;
            case DEAL_STATUS_LEGAL:
                this._name = DEAL_STATUS_NAME_LEGAL;
                break;
            case DEAL_STATUS_FUNDED:
                this._name = DEAL_STATUS_NAME_FUNDED;
                break;
            case DEAL_STATUS_ON_HOLD:
                this._name = DEAL_STATUS_NAME_ON_HOLD;
                break;
            case DEAL_STATUS_LOST:
                this._name = DEAL_STATUS_NAME_LOST;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableDealTypes().indexOf(this._value) < 0) {
            throw new Exception('Invalid Loan Status Type', 400);
        }
    }
}
