import { useState, useEffect } from 'react';

export const useViewport = () => {
    const [viewport, setViewport] = useState<{ width: number; height: number }>(
        { width: 0, height: 0 }
    );
    const handleResize = () => {
        setViewport({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return viewport;
};
