import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import PartDropdown from '../../parts/PartDropdown';
import PartInputField from '../../parts/PartInputField';
import BlockStepsButtons from '../BlockStepsButtons';
import { contactDetailsPropertyValidationSchema } from '../../../utils/validationSchemas';
import PartAutoCompleteSearch from '../../parts/PartAutoCompleteSearch';
import {
    ASSET_TYPE_2_4_UNIT,
    ASSET_TYPE_MIXED_USE,
    ASSET_TYPE_MULTI_FAMILY,
    ASSET_TYPE_SFR,
    FIELD_NAME_ASSET_TYPE_2_4_UNIT,
    FIELD_NAME_ASSET_TYPE_MIXED_USE,
    FIELD_NAME_ASSET_TYPE_MULTI_FAMILY,
    FIELD_NAME_ASSET_TYPE_SFR
} from '../../../domain/loan/valueObject/DealAssetType';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';

export type OptionType = {
    label: string;
    value: number;
};

interface ContactDetailsPropertyProps extends NewLoanApplicationStepsProps {}

const ContactDetailsProperty = ({
    nextStep,
    prevStep,
    newLoanEntity
}: ContactDetailsPropertyProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(contactDetailsPropertyValidationSchema),
        shouldFocusError: false
        // defaultValues: {
        //     address: undefined,
        //     city: 'New York',
        //     // state: 'NY',
        //     postal: '10001',
        //     unitCount: 4,
        //     assetClass: 1
        // }
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.CREDIT_SCORE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.CONTACT_DETAILS_INFO);
    };

    useEffect(() => {
        newLoanEntity.hasPropertyAddress() &&
            setValue('address', newLoanEntity.propertyAddress.value);
        newLoanEntity.hasCity() && setValue('city', newLoanEntity.city.value);
        newLoanEntity.hasState() &&
            setValue('state', newLoanEntity.state.value);
        newLoanEntity.hasPostalCode() &&
            setValue('postal', newLoanEntity.postalCode.value);
        newLoanEntity.hasUnitCount() &&
            setValue('unitCount', newLoanEntity.unitCount.value);
        newLoanEntity.hasAssetClass() &&
            setValue('assetClass', newLoanEntity.assetClass.value);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    Where&#39;s the property located?
                </h1>
                <p className="login__subtitle">Property Details</p>
                <div className="login__box">
                    <PartAutoCompleteSearch
                        label="Property Address"
                        name="address"
                        errors={errors}
                        control={control}
                        setValue={setValue}
                        isRequired
                    />
                    <PartInputField
                        label="City"
                        name="city"
                        control={control}
                        errors={errors}
                        isRequired
                    />
                    <PartInputField
                        control={control}
                        errors={errors}
                        label="State/Region"
                        name="state"
                        isRequired
                    />
                    <div className="form-elements__row">
                        <PartInputField
                            control={control}
                            errors={errors}
                            label="Postal code"
                            name="postal"
                            isRequired
                        />
                        <PartInputField
                            control={control}
                            errors={errors}
                            label="Unit count"
                            name="unitCount"
                            isNumberFormat
                            isRequired
                        />
                    </div>
                    <PartDropdown
                        control={control}
                        errors={errors}
                        label="Asset class"
                        name="assetClass"
                        options={[
                            {
                                label: FIELD_NAME_ASSET_TYPE_SFR,
                                value: ASSET_TYPE_SFR
                            },
                            {
                                label: FIELD_NAME_ASSET_TYPE_2_4_UNIT,
                                value: ASSET_TYPE_2_4_UNIT
                            },
                            {
                                label: FIELD_NAME_ASSET_TYPE_MULTI_FAMILY,
                                value: ASSET_TYPE_MULTI_FAMILY
                            },
                            {
                                label: FIELD_NAME_ASSET_TYPE_MIXED_USE,
                                value: ASSET_TYPE_MIXED_USE
                            }
                        ]}
                        isRequired
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactDetailsProperty;
