import { notification } from 'antd';
import { useState } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByAddress
} from 'react-google-places-autocomplete';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Exclamation from '../icons/IconExclamation';

const PartAutoCompleteSearch = ({
    name,
    label,
    control,
    isRequired,
    errors,
    setValue
}: any) => {
    const [iValue, setIValue] = useState<any | undefined>('');

    // Extract google's API response address_components by specific keys
    const getCountryCodeComponent = (address_components: any, type: string) => {
        let value = '';
        address_components.forEach((comp: any) => {
            if (comp.types.includes(type)) {
                value = comp.long_name;
            }
        });
        return value;
    };

    const handleOnChange = (e: any, field: any) => {
        geocodeByAddress(e.label).then((results: any) => {
            const streetNum = getCountryCodeComponent(
                results[0].address_components,
                'street_number'
            );
            const streetAddress = getCountryCodeComponent(
                results[0].address_components,
                'route'
            );
            const city = getCountryCodeComponent(
                results[0].address_components,
                'locality'
            );
            const state = getCountryCodeComponent(
                results[0].address_components,
                'administrative_area_level_1'
            );
            const zip = getCountryCodeComponent(
                results[0].address_components,
                'postal_code'
            );

            const fullAddress = streetNum
                ? `${streetNum} ${streetAddress}`
                : streetAddress ?? undefined;
            setIValue(fullAddress);

            setValue(fullAddress);
            field.onChange(fullAddress);
            setValue('city', city);
            setValue('state', state);
            setValue('postal', zip);
        });
    };

    return (
        <div
            className={`form-element form-element--address ${
                name in errors ? 'form-element--error' : ''
            }`}
        >
            <label>
                {label}
                {isRequired && <span>{' *'}</span>}
            </label>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['us']
                            }
                        }}
                        selectProps={{
                            ...field,
                            styles: {
                                input: (provided: any) => ({
                                    ...provided,
                                    color: 'black',
                                    width: 350
                                }),
                                // text
                                option: (provided: any) => ({
                                    ...provided,
                                    color: '#03363D',
                                    padding: '12px 36px'
                                }),
                                singleValue: (provided: any) => ({
                                    ...provided,
                                    color: '#03363d'
                                })
                            },
                            instanceId: 'address',
                            inputValue: iValue,
                            placeholder: false,
                            onChange: (e) => handleOnChange(e, field),
                            onBlur: () => setIValue(iValue),
                            onInputChange: (val, e) => {
                                if (e.action === 'input-change') {
                                    setIValue(val);
                                    field.onChange(val);
                                }
                            },
                            openMenuOnClick: false
                        }}
                        onLoadFailed={(error: any) => {
                            notification.error({
                                message: `Request has failed with error message: ${error.message}`
                            });
                        }}
                    />
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>{message}</span>
                    </p>
                )}
            />
        </div>
    );
};

export default PartAutoCompleteSearch;
