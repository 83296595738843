import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';

interface PartSingleLoanItemProps {
    title: string;
    loanData: string | number | undefined | null;
}

const PartSingleLoanItem = ({ title, loanData }: PartSingleLoanItemProps) => {
    const [hasDotOverflow, setHasDotOverflow] = useState(false);

    if (!loanData) return null;
    return (
        <div className="single-loan__lists-summary-item">
            <span className="single-loan__lists-summary-item-title">
                {title}
            </span>
            {!hasDotOverflow ? (
                <span
                    ref={(ref) => {
                        if (!ref) return;
                        const isOverflowing = ref.scrollWidth > ref.clientWidth;
                        if (isOverflowing) {
                            setHasDotOverflow(true);
                        }
                    }}
                    className="single-loan__lists-summary-item-val"
                >
                    {loanData}
                </span>
            ) : (
                <Tooltip title={loanData}>
                    <span
                        ref={(ref) => {
                            if (!ref) return;
                            const isOverflowing =
                                ref.scrollWidth > ref.clientWidth;
                            if (isOverflowing) {
                                setHasDotOverflow(true);
                            }
                        }}
                        className="single-loan__lists-summary-item-val"
                    >
                        {loanData}
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default PartSingleLoanItem;
