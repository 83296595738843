import LoanEntity from './LoanEntity';
import { DEAL_STATUS_PENDING } from './valueObject/LoanStatusType';

const NO_INFO = undefined;

export type SingleLoanDataType = {
    id: number;
    transactionType?: string;
    unitCount: number;
    originator?: string;
    processor?: string;
    borrower?: string;
    borrowerEmail?: string;
    phone?: string;
    status: number;
    lender: string;
    address: {
        streetName: string;
        city: string;
        state: string;
        zip: string;
    } | null;
    monthlyExpenses: string;
    loanType?: number;
    assetType: string;
    loanAmount?: string;
    closeDate?: string;
    creditScore: number;
    experienceLevel?: string;
    estimatedAsIsValue?: string;
    purchasePrice?: string;
    originalPurchaseDate?: string;
    originalPurchasePrice?: string;
    rehabAmountSpentToDate?: string;
    existingDebt?: string;
    rehabType?: string;
    rehabAmount?: string;
    estimatedAfterRepairValue?: string;
    taxes?: string;
    insurance?: string;
    utilities?: string;
    hoa?: string;
    repairsAndMaintenance?: string;
    otherExpenses?: string;
    managementExpenses?: string;
    monthlyGrossRents?: string;
    createdAt?: string;
};
export default class LoanViewMapper {
    static map(loan: LoanEntity) {
        const loanAmountCurrencyFormatter = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
        const address = loan.hasPropertyAddress()
            ? {
                  streetName: loan.propertyAddress.hasStreet()
                      ? loan.propertyAddress.street.value
                      : NO_INFO,
                  city: loan.propertyAddress.hasCity()
                      ? loan.propertyAddress.city.value
                      : NO_INFO,
                  state: loan.propertyAddress.hasState()
                      ? loan.propertyAddress.state.value
                      : NO_INFO,
                  zip: loan.propertyAddress.hasZip()
                      ? loan.propertyAddress.zip.value
                      : NO_INFO
              }
            : null;
        const processor = loan.hasProcessor()
            ? loan.processor.hasFirstName() && loan.processor.hasLastName()
                ? `${loan.processor.firstName.value} ${loan.processor.lastName.value}`
                : NO_INFO
            : NO_INFO;

        const originator = loan.hasOriginator()
            ? loan.originator.hasFirstName() && loan.originator.hasLastName()
                ? `${loan.originator.firstName.value} ${loan.originator.lastName.value}`
                : NO_INFO
            : NO_INFO;

        const borrower = loan.hasBorrower()
            ? loan.borrower.hasFirstName() && loan.borrower.hasLastName()
                ? `${loan.borrower.firstName.value} ${loan.borrower.lastName.value}`
                : NO_INFO
            : NO_INFO;
        const result: SingleLoanDataType = {
            id: loan.loanId.value,
            transactionType: loan.hasTransactionType()
                ? loan.transactionType.name
                : NO_INFO,
            unitCount: loan.hasUnits() ? loan.units.value : NO_INFO,
            originator: originator,
            processor: processor,
            borrower: borrower,
            borrowerEmail: loan.borrower.hasEmail()
                ? loan.borrower.email.value
                : NO_INFO,
            phone: loan.hasPhone()
                ? this._formatPhoneNumber(loan.phone.value)
                : NO_INFO,
            status: loan.hasLoanStatus()
                ? loan.loanStatus.value
                : DEAL_STATUS_PENDING,
            lender: loan.hasLender() ? loan.lender.value : NO_INFO,
            address: address,
            loanType: loan.hasLoanType() ? loan.loanType.value : NO_INFO,
            assetType: loan.hasAssetType() ? loan.assetType.name : NO_INFO,
            loanAmount: loan.hasLoanAmount()
                ? loanAmountCurrencyFormatter.format(loan.loanAmount)
                : NO_INFO,
            closeDate: loan.hasCloseDate()
                ? loan.closeDate.toDateFormat('M j, Y')
                : NO_INFO,
            creditScore: loan.hasCreditScore()
                ? loan.creditScore.value
                : NO_INFO,
            experienceLevel: loan.hasExperienceLevel()
                ? loan.experienceLevel.name
                : NO_INFO,
            estimatedAsIsValue: loan.hasEstimatedAsIsValue()
                ? loanAmountCurrencyFormatter.format(
                      loan.estimatedAsIsValue.value
                  )
                : NO_INFO,
            purchasePrice: loan.hasPurchasePrice()
                ? loanAmountCurrencyFormatter.format(loan.purchasePrice.value)
                : NO_INFO,
            originalPurchaseDate: loan.hasOriginalPurchaseDate()
                ? loan.originalPurchaseDate.toDateFormat('M j, Y')
                : NO_INFO,
            originalPurchasePrice: loan.hasOriginalPurchasePrice()
                ? loanAmountCurrencyFormatter.format(
                      loan.originalPurchasePrice.value
                  )
                : NO_INFO,
            rehabAmountSpentToDate: loan.hasRehabAmountSpentToDate()
                ? loanAmountCurrencyFormatter.format(
                      loan.rehabAmountSpentToDate.value
                  )
                : NO_INFO,
            monthlyExpenses:
                loan.hasTaxes() &&
                loan.hasInsurance() &&
                loan.hasUtilities() &&
                loan.hasManagementExpenses() &&
                loan.hasRepairsAndMaintenance() &&
                loan.hasOtherExpenses()
                    ? loanAmountCurrencyFormatter.format(
                          (loan.hasHoa() ? loan.hoa.value : 0) +
                              loan.taxes.value +
                              loan.insurance.value +
                              loan.utilities.value +
                              loan.managementExpenses.value +
                              loan.repairsAndMaintenance.value +
                              loan.otherExpenses.value
                      )
                    : '',
            existingDebt: loan.hasExistingDebt()
                ? loanAmountCurrencyFormatter.format(loan.existingDebt.value)
                : NO_INFO,
            rehabType: loan.hasRehabType() ? loan.rehabType.name : NO_INFO,
            rehabAmount: loan.hasRehabAmount()
                ? loanAmountCurrencyFormatter.format(loan.rehabAmount.value)
                : NO_INFO,
            estimatedAfterRepairValue: loan.hasEstimatedAfterRepairValue()
                ? loanAmountCurrencyFormatter.format(
                      loan.estimatedAfterRepairValue.value
                  )
                : NO_INFO,
            monthlyGrossRents: loan.hasMonthlyGrossRents()
                ? loanAmountCurrencyFormatter.format(
                      loan.monthlyGrossRents.value
                  )
                : NO_INFO,
            taxes: loan.hasTaxes()
                ? loanAmountCurrencyFormatter.format(loan.taxes.value)
                : NO_INFO,
            insurance: loan.hasInsurance()
                ? loanAmountCurrencyFormatter.format(loan.insurance.value)
                : NO_INFO,
            utilities: loan.hasUtilities()
                ? loanAmountCurrencyFormatter.format(loan.utilities.value)
                : NO_INFO,
            hoa: loan.hasHoa()
                ? loanAmountCurrencyFormatter.format(loan.hoa.value)
                : NO_INFO,
            repairsAndMaintenance: loan.hasRepairsAndMaintenance()
                ? loanAmountCurrencyFormatter.format(
                      loan.repairsAndMaintenance.value
                  )
                : NO_INFO,
            otherExpenses: loan.hasOtherExpenses()
                ? loanAmountCurrencyFormatter.format(loan.otherExpenses.value)
                : NO_INFO,
            managementExpenses: loan.hasManagementExpenses()
                ? loanAmountCurrencyFormatter.format(
                      loan.managementExpenses.value
                  )
                : NO_INFO,
            createdAt: loan.hasCreatedAt()
                ? loan.createdAt.toDateFormat('M j, Y')
                : NO_INFO
        };

        return result;
    }

    private static _formatPhoneNumber(phoneNumber: string) {
        if (phoneNumber === '') return NO_INFO;
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? '+1 ' : '';
            return [
                intlCode,
                '(',
                match[2],
                ') ',
                match[3],
                '-',
                match[4]
            ].join('');
        }
        return undefined;
    }
}
