import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MainFooter from '../components/main/MainFooter';
import MainHeader from '../components/main/MainHeader';

const PublicOutlet = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="main" style={{ backgroundColor: '#F4F8F2' }}>
            <MainHeader />
            <main>
                <Outlet />
            </main>
            <MainFooter />
        </div>
    );
};

export default PublicOutlet;
