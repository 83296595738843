import { RAM_PERSISTENCE } from '../../utils/RAMPersistence';
import LoanScrollEntity from './LoanScrollEntity';

export default class LoanScrollRAMGateway {
    _entityKey = 'LoanScrollEntity';

    persist(persistenceKey: any, loanScroll: LoanScrollEntity) {
        RAM_PERSISTENCE.persist(persistenceKey, loanScroll, this._entityKey);
    }

    clearPersistedData() {
        RAM_PERSISTENCE.clearKey(this._entityKey);
    }

    retrieve(persistenceKey: any) {
        return RAM_PERSISTENCE.retrieve(persistenceKey, this._entityKey);
    }
}
