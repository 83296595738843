import { API } from '../../utils/http/ApiClient';
import Collection from '../valueObject/Collection';
import TypeString from '../valueObject/TypeString';

export default class LoanSearchGateway {
    searchByKeyword(keywords: TypeString) {
        const data = new Collection({});
        data.set('page', 1);
        data.set('per_page', 20);
        data.set('keywords', keywords.value);
        return API.get('Loans/SearchLoans', data);
    }
}
