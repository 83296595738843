import LoanSortingRule from '../loanSortingRule/LoanSortingRule';
import LoanScrollEntity from './LoanScrollEntity';
import LoanScrollFactory from './LoanScrollFactory';
import LoanScrollGateway from './LoanScrollGateway';
import LoanScrollRAMGateway from './LoanScrollRAMGateway';
import LoanScrollRepository from './LoanScrollRepository';

export default class LoanScrollService {
    static getLoanScroll() {
        if (new LoanScrollRAMGateway().retrieve('all') === undefined) {
            const scroll = LoanScrollFactory.makeEmpty();
            const rule = scroll?.sortingRules?.rules.find(
                (rule: LoanSortingRule) => {
                    return rule.field.isCreatedAt();
                }
            );

            if (rule instanceof LoanSortingRule)
                rule.afterChange('changed_created_at_direction', () => {
                    scroll.resetScroll();
                    this.loadNextPage(scroll);
                });
            new LoanScrollRAMGateway().persist('all', scroll);
        }
        return new LoanScrollRAMGateway().retrieve('all');
    }

    static async loadNextPage(loanScroll: LoanScrollEntity) {
        if (!loanScroll.canLoadMore()) return;

        await new LoanScrollRepository(
            new LoanScrollGateway(),
            new LoanScrollFactory()
        ).loadNextPage(loanScroll);
    }
}
