import Collection from '../valueObject/Collection';
import TypeString from '../valueObject/TypeString';
import AddressEntity from './AddressEntity';

export default class AddressFactory {
    static reconstitute(data: Collection) {
        const instance = new AddressEntity();
        if (data.has('city'))
            instance.setCity(new TypeString(data.get('city')));
        if (data.has('state'))
            instance.setState(new TypeString(data.get('state')));
        if (data.has('street_address'))
            instance.setStreet(new TypeString(data.get('street_address')));
        if (data.has('zip')) instance.setZip(new TypeString(data.get('zip')));

        return instance;
    }

    static makeNew(
        city: TypeString,
        state: TypeString,
        street: TypeString,
        zip: TypeString
    ) {
        return new AddressEntity()
            .setCity(city)
            .setState(state)
            .setStreet(street)
            .setZip(zip);
    }
}
