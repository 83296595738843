import TransactionPurchasePrice from '../../components/blocks/NewLoanSteps/TransactionPurchasePrice';
import { API } from '../../utils/http/ApiClient';
import {
    DEAL_TRANSACTION_TYPE_PURCHASE,
    DEAL_TRANSACTION_TYPE_REFINANCE
} from '../loan/valueObject/DealTransactionType';
import { DEAL_TYPE_NO_DOC_DSCR } from '../loan/valueObject/LoanType';
import Collection from '../valueObject/Collection';
import LoanApplicationEntity from './LoanApplicationEntity';

export default class LoanApplicationGateway {
    create(newLoan: LoanApplicationEntity) {
        return API.post('Loans/Create', this._mapFormData(newLoan));
    }

    private _mapFormData(newLoan: LoanApplicationEntity): Collection {
        const data = new Collection({});

        // Branch One - Refinance/Purchase
        if (newLoan.hasTransactionType()) {
            data.set('transaction_type', newLoan.transactionType.value);

            if (
                newLoan.transactionType.value === DEAL_TRANSACTION_TYPE_PURCHASE
            ) {
                if (newLoan.hasPurchasePrice())
                    data.set('purchase_price', newLoan.purchasePrice.value);
            }
            if (
                newLoan.transactionType.value ===
                DEAL_TRANSACTION_TYPE_REFINANCE
            ) {
                if (newLoan.hasOriginalPurchasePrice())
                    data.set(
                        'original_purchase_price',
                        newLoan.originalPurchasePrice.value
                    );
                if (newLoan.hasExistingDebt())
                    data.set('existing_debt', newLoan.existingDebt.value);

                if (newLoan.hasRehabAmountSpendToDate())
                    data.set(
                        'rehab_amount_spent_to_date',
                        newLoan.rehabAmountSpendToDate.value
                    );
                if (newLoan.hasOriginalPurchaseDate())
                    data.set(
                        'original_purchase_date',
                        newLoan.originalPurchaseDate.getTime() / 1000
                    );
            }
        }
        // Branch 2 - Loan Type
        if (newLoan.hasLoanType()) {
            data.set('loan_type', newLoan.loanType?.value);

            if (newLoan.loanType.value === DEAL_TYPE_NO_DOC_DSCR) {
                if (newLoan.hasMonthlyGrossRents())
                    data.set(
                        'monthly_gross_rents',
                        newLoan.monthlyGrossRents?.value
                    );
                if (newLoan.hasTaxes()) data.set('taxes', newLoan.taxes?.value);
                if (newLoan.hasInsurance())
                    data.set('insurance', newLoan.insurance?.value);
                if (newLoan.hasHoa()) data.set('hoa', newLoan.hoa.value);
                if (newLoan.hasUtilities())
                    data.set('annual_utilities', newLoan.utilities?.value);
                if (newLoan.hasOtherExpenses())
                    data.set('other_expenses', newLoan.otherExpenses?.value);
                if (newLoan.hasManagementExpenses())
                    data.set(
                        'management_expenses',
                        newLoan.managementExpenses?.value
                    );
                if (newLoan.hasrepairsMaintenance())
                    data.set(
                        'repairs_and_maintenance',
                        newLoan.repairsMaintenance?.value
                    );
            } else {
                if (newLoan.hasRehabType())
                    data.set('rehab_type', newLoan.rehabType?.id);
                if (newLoan.hasRehabAmount())
                    data.set('rehab_amount', newLoan.rehabAmount?.value);
                if (newLoan.hasEstimatedAfterRepairValue())
                    data.set(
                        'estimated_after_repair_value',
                        newLoan.estimatedAfterRepairValue?.value
                    );
            }
        }

        if (newLoan.hasFirstName())
            data.set('first_name', newLoan.firstName?.value);
        if (newLoan.hasLastName())
            data.set('last_name', newLoan.lastName?.value);
        if (newLoan.hasEmail()) data.set('email', newLoan.email?.value);
        if (newLoan.hasPhone()) data.set('phone', newLoan.phone?.value);
        if (newLoan.hasPropertyAddress())
            data.set('address', newLoan.propertyAddress?.value);
        if (newLoan.hasCity()) data.set('city', newLoan.city?.value);
        if (newLoan.hasState()) data.set('state', newLoan.state?.value);
        if (newLoan.hasPostalCode()) data.set('zip', newLoan.postalCode?.value);
        if (newLoan.hasUnitCount())
            data.set('unit_count', newLoan.unitCount?.value);
        if (newLoan.hasAssetClass())
            data.set('asset_type', newLoan.assetClass?.value);
        if (newLoan.hasCreditScore())
            data.set('credit_score', newLoan.creditScore?.value);
        if (newLoan.hasPropertyCountExperience())
            data.set(
                'experience_level',
                newLoan.propertyCountExperience?.value
            );
        if (newLoan.hasEstimatedAsIsValue())
            data.set(
                'estimated_as_is_value',
                newLoan.estimatedAsIsValue?.value
            );

        return data;
    }
}
