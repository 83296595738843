import CredentialsEntity from './CredentialsEntity';
import CredentialsFactory from './CredentialsFactory';
import CredentialsGateway from './CredentialsGateway';
import CredentialsRepository from './CredentialsRepository';
import MeEntity from './MeEntity';
import Collection from '../valueObject/Collection';

export default class CredentialsService {
    static login(credentials: CredentialsEntity): Promise<any> {
        return CredentialsGateway.login(credentials);
    }

    static logout(): Promise<any> {
        return CredentialsGateway.logout();
    }

    static sendResetPasswordRequest(email: any): Promise<any> {
        return CredentialsGateway.sendResetPasswordRequest(email);
    }

    static setNewPassword(payload: Collection): Promise<any> {
        return CredentialsGateway.setNewPassword(payload);
    }

    static checkPasswordResetLink(
        id: string | null,
        token: string | null
    ): Promise<any> {
        return CredentialsGateway.checkPasswordResetLink(id, token);
    }

    /**
     * @returns Promise <MeEntity>
     */
    static me(): Promise<MeEntity> {
        return new CredentialsRepository(
            new CredentialsGateway(),
            new CredentialsFactory()
        ).getMe();
    }
}
