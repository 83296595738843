import { Link } from 'react-router-dom';

interface Props {
    newLoanId: string;
}

const SubmitConfirmation = ({ newLoanId }: Props) => {
    return (
        <div className="login submit-confirmation">
            <div className="container">
                <div className="submit-confirmation-img">
                    <img src="/assets/NDL.gif" alt="Success image"></img>
                </div>
                <h1 className="login__title">
                    Thanks for submitting your loan intake form!
                </h1>
                <p className="login__subtitle">
                    A NDL originator will be in touch with you shortly to
                    discuss loan options.
                </p>
                <p className="login__subtitle">Loan ID: #{newLoanId}</p>
                <Link
                    to={`/my-loans/${newLoanId}`}
                    className="btn submit-confirmation-btn"
                >
                    View Loan Request
                </Link>
            </div>
        </div>
    );
};

export default SubmitConfirmation;
