import MeEntity from '../domain/credentials/MeEntity';
import {
    setFirstName,
    setLastName,
    setPhone,
    setToken,
    setUserId,
    setUsername,
    setUserType
} from '../store/auth';
import store from '../store/storeIndex';

/**
 * Store user details in local storage after successful login
 * @param {MeEntity} me
 */
export const storeUserInLocalStorage = (me: MeEntity) => {
    localStorage.setItem('token', me.token.value);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('firstName', me.user.firstName.value);
};

/**
 *  Store user details in Redux after successful login
 * @param {MeEntity} me
 */
export const storeUserInRedux = (me: MeEntity) => {
    store.dispatch(setFirstName(me.user.firstName.value));
    store.dispatch(setUserType(me.user.type.value));
    store.dispatch(setToken(me.token.value));
    store.dispatch(setUsername(me.user.email.value));
    store.dispatch(setLastName(me.user.lastName.value));
    store.dispatch(setUserId(me.user.id.value));
    store.dispatch(
        setPhone(
            me.user.hasPhone()
                ? removeBracketsFromString(me.user.phone.value)
                : null
        )
    );
};

/**
 *
 * @param {string} value
 * @returns Formatted string without brackets '()'
 */
export const removeBracketsFromString = (value: string) =>
    value.replace(/[{()}]/g, '');

/**
 * @param {string} value
 * @returns Plain number without any special characters
 */
export const sanitizePositiveInteger = (value: string) => {
    if (typeof value !== 'string') return value;
    return parseInt(value.replace(/[^\d]+/g, ''));
};

/**
 * @param {string} value
 * @returns Plain number without any special characters
 */
export const sanitizePositiveFloat = (value: string) => {
    if (typeof value !== 'string') return value;
    return parseFloat(value.replace(/[^\d]+/g, ''));
};

/**
 * Wraps matching keywords in the input text with html `<b>` tag.
 * @param {string} text text to be processed
 * @param {Array<string>} keywords
 * @returns string
 */
export function getBoldedTextMatchingKeywords(
    text: string,
    keywords: Array<string>
) {
    const sortedKeywords = keywords.sort(
        (keywordA, keywordB) => keywordB.length - keywordA.length
    );
    text = text.replace(/\s+/g, '&nbsp;');
    sortedKeywords.forEach((token: string) => {
        const regExp = new RegExp(token, 'gi');
        text = text.replace(regExp, (match, currentIndex) => {
            const textBeforeIndex = text.slice(0, currentIndex);
            const openTagsCount = textBeforeIndex.match('<b>')?.length ?? 0;
            const closingTagsCount = textBeforeIndex.match('</b>')?.length ?? 0;
            const isInsideBoldTag = openTagsCount > closingTagsCount;
            return isInsideBoldTag ? match : `<b>${match}</b>`;
        });
    });
    return text;
}
