const ArrowDown = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.05"
            height="10.601"
            viewBox="0 0 11.05 10.601"
        >
            <g
                id="_.IconSize"
                data-name=".IconSize"
                transform="translate(-2.809 -2.591)"
            >
                <path
                    id="Path_1912"
                    data-name="Path 1912"
                    d="M5.333,3.334l-2,2,2,2"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.05"
                />
                <path
                    id="Path_1913"
                    data-name="Path 1913"
                    d="M3.333,5.334h6a3.844,3.844,0,0,1,4,3.667h0a3.844,3.844,0,0,1-4,3.667H4"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.05"
                />
            </g>
        </svg>
    );
};

export default ArrowDown;
