import { Select, Space } from 'antd';
import IconArrowDown from '../icons/IconArrowDown';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Exclamation from '../icons/IconExclamation';
import { OptionType } from '../blocks/NewLoanSteps/ContactDetailsProperty';

interface InputFieldProps {
    name: string;
    label: string;
    control: any;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    validationMessage?: string;
    options: OptionType[];
}

const PartDropdown = ({
    name,
    label,
    control,
    errors,
    isRequired,
    validationMessage,
    options
}: InputFieldProps) => {
    return (
        <div
            className={`form-element ${
                name in errors ? 'form-element--error' : ''
            }`}
        >
            <label>
                {label}
                {isRequired && <span>{' *'}</span>}
            </label>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Select
                        {...field}
                        suffixIcon={<IconArrowDown />}
                        className="basic-select my-loans__select"
                        dropdownClassName="my-loans__subheader-tabs-dropdown"
                        onChange={(values) => {
                            field.onChange(values);
                        }}
                        options={options}
                        placeholder="Please Select"
                    />
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartDropdown;
