import React, { useEffect } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';

const PrivacyPolicy = () => {
    useDocumentTitle('Privacy Policy');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            <div className="basic-content">
                <h1>Privacy Policy</h1>
                <span>LAST UPDATED MARCH 2022</span>
                <p>
                    By accessing or using this NDL website, (the ``Website``),
                    you agree to the terms of this Privacy Policy, as described
                    below. If you do not agree to these terms, please do not
                    access or use the Website. NDL reserves the right to change
                    this Privacy Policy from time to time at its sole
                    discretion. Your use of the Website will be subject to the
                    most current version of this Privacy Policy at the time of
                    such use, and to the Website`s Terms of Use.
                </p>
                <p>
                    Agreement to this Policy: By using this Website you consent
                    to this Privacy Policy, including your consent to our use
                    and disclosure of information about you in the manner
                    described herein. If you do not agree with any of its terms,
                    please do not use this Website or submit any personal
                    information.
                </p>
                <p>
                    We take our ethical responsibilities and your privacy
                    extremely seriously. We have a strong commitment both to the
                    ethical handling of your information and to providing
                    excellent service to all our customers and visitors to this
                    Website. This Privacy Policy discloses how we collect,
                    protect, use, and share information gathered about you on
                    our Website. We hope that this disclosure will help increase
                    your confidence in our Website specifically and enhance your
                    experience on the Internet generally. Therefore, in
                    compliance with industry standards and regulations typically
                    enforced by the federal and state governments, we abide the
                    following privacy policy.
                </p>
                <h2>Browsing</h2>
                <p>
                    This Website does not collect personally identifiable
                    information from your computer when you browse this Website
                    and request pages from our servers. This means unless you
                    voluntarily and knowingly provide us with your personally
                    identifiable information, we will not know your name, your
                    email address, or any other personally identifiable
                    information about you. We may, however, use IP addresses,
                    browser types, and access times to analyze trends,
                    administer the site, improve site performance and gather
                    broad demographic information for aggregate use. When you
                    request a page from our Website, our servers may log the
                    information provided in the HTTP request header including
                    the IP number, the time of the request, the URL of your
                    request, and other information that is provided in the HTTP
                    header. We collect the HTTP request header information in
                    order to make our Website function correctly and provide you
                    with the functionality you see on the Website. We also use
                    this information to better understand how visitors use our
                    website and how we can better fine-tune it, its contents,
                    and functionality to best meet your needs.
                </p>
                <h2>Collection of information</h2>
                <p>
                    When you engage in certain activities on the Website, the
                    Website may ask you to provide certain information about
                    yourself by filling-out and submitting an online form. It is
                    completely optional for you to engage in these activities.
                    If you elect to engage in these activities, however, the
                    Website may ask that you provide personal information, such
                    as your first and last name, mailing address (including zip
                    code), e-mail address, company name, telephone and facsimile
                    numbers, and other personal identifying information. We may
                    also request your loan amount and other financial
                    information. Depending upon the activity, some of the
                    information that you will be asked to provide is identified
                    as mandatory and some as voluntary. If you do not provide
                    the mandatory data with respect to a particular activity,
                    you will not be able to engage in that activity.
                </p>
                <p>
                    When you submit information to NDL via the Website, you
                    understand and agree that the information may be transferred
                    across national boundaries and may be stored and processed
                    in any of the countries in which NDL and its affiliates
                    maintain offices, including without limitation, the United
                    States. You also acknowledge that, in certain countries or
                    with respect to certain activities, the collection,
                    transferring, storage, and processing of your information
                    may be undertaken by trusted vendors of NDL.
                </p>
                <p>
                    The servers that NDL uses to operate the Website may collect
                    domain names, IP addresses and e-mail addresses of visitors.
                    This information may be aggregated to measure the number of
                    visits, average time spent on the Website, pages viewed, and
                    similar information. NDL may use this information to measure
                    the use of the Website and to improve the content of the
                    Website or other Websites operated by NDL and/or its
                    affiliates.
                </p>
                <h2>How information is used</h2>
                <p>
                    NDL collects information to record and support your
                    participation in the activities you select and may share
                    with third parties that may be able to provide certain loans
                    products and services. The information that you provide may
                    also be used as part of NDL effort to keep you informed
                    about new products and services, product and services
                    upgrades and modifications, special offers, and other
                    products and services of NDL and selected third parties. NDL
                    reserves the right to compile and use aggregate data, and
                    also to provide such aggregate data to third parties for
                    statistical analysis. Such data will not be linked to any
                    particular individuals or companies. NDL also reserves the
                    right to make any disclosures required by law or any
                    governmental agency.
                </p>
                <p>
                    We may also use the information we collect for other reasons
                    including, but not limited to:
                </p>
                <ul>
                    <li>
                        learning about customer interest in the services we
                        offer and providing them with information
                    </li>
                    <li>enrolling customers who desire our services</li>
                    <li>opening customer files</li>
                    <li>providing customer service</li>
                    <li>learning how to improve our products or services</li>
                    <li>
                        sharing your information and providing opportunities for
                        our affiliates and other companies to inform you about
                        the products or services (including lending) they offer
                        that may interest you
                    </li>
                    <li>
                        sharing aggregated statistical data with our business
                        partners or for public relations. For example, we may
                        disclose that a specific percentage of our users are
                        located in a particular state or region.
                    </li>
                </ul>
                <p>
                    Aside from the ways mentioned above, we may also use your
                    information for other legally permissible purposes. Examples
                    of some additional legally permissible purposes are:
                </p>
                <p>
                    <i>Email:</i> We may use your information to provide email
                    marketing materials. Such email marketing may feature the
                    goods and services of third parties, and may be unrelated to
                    the products or services offered or promoted on the Website.
                </p>
                <p>
                    <i>Direct Mail:</i> We may use your information to
                    advertise, directly or indirectly to using postal mail.
                </p>
                <p>
                    <i>Profiles of Users:</i> We may store your information in a
                    database on secure computers.
                </p>
                <p>
                    <i>Sale, License or Transfer to Third Parties:</i> With your
                    permission, we may sell, license or transfer your
                    information to third parties for the purposes of sending you
                    offers of goods or services via email, postal mail and/or
                    telemarketing. This may include the transfer of your name,
                    address, phone number, email address, IP Address, date/time
                    you visited the Site, and evidence that you have accepted
                    any terms on the Site (including the Terms of Use or this
                    Privacy Policy).
                </p>
                <p>
                    <i>Legal Process:</i> We may disclose your information to
                    respond to subpoenas, court orders and other legal
                    processes.
                </p>
                <p>
                    <i>Investigation:</i> We may use your information if we
                    believe it is necessary in order to investigate, prevent, or
                    take action regarding illegal activities, suspected fraud,
                    or violations of our terms of use.
                </p>
                <p>
                    <i>Summary Data:</i> We may sell or transfer
                    non-individualized information, such as summary or
                    aggregated anonymous information about users of our websites
                    as a whole or as sub-groups of users.
                </p>
                <h2>Use of cookies</h2>
                <p>
                    When you visit the Website you can browse the Website
                    anonymously and access important information without
                    revealing your identity. To improve the Website, we may use
                    `cookies` to track your visit. A cookie is a small amount of
                    data that is transferred to your browser by a website server
                    and can only be read by the server that gave it to you. It
                    functions as your identification card, recording your
                    passwords, purchases, and preferences. A cookie may be used
                    to provide to you tailored information.
                </p>
                <p>
                    Most browsers are initially set to accept cookies. You can
                    set your browser to notify you when you receive a cookie,
                    giving you the chance to decide whether to accept it. For
                    some web pages that require an authorization, cookies are
                    not optional. Users choosing not to accept cookies will
                    probably not be able to access those pages.
                </p>
                <p>
                    While the Website may use cookies to track your visit to the
                    Website and the Website servers automatically log the
                    IP/Internet address of your computer, this information does
                    not identify you personally and you remain anonymous unless
                    you have otherwise provided NDL with personal information.
                </p>
                <h2>Links to other websites</h2>
                <p>
                    This Website contains links to other sites such as
                    affiliates as well as other third parties that are not owned
                    or controlled by us. Please be aware that this Privacy
                    Policy only addresses the use and disclosure of your
                    information collected on this Website.
                </p>
                <p>
                    NDL and the Website connect users with third parties whose
                    respective privacy policies govern your interactions with
                    them. We encourage you to be aware when you leave our site
                    and to read the applicable privacy policies and terms of
                    conditions of each and every website that collects
                    personally identifiable information.
                </p>
                <h2>Choice/opt-out</h2>
                <p>
                    We provide you the opportunity to ``opt-out`` of having your
                    personally identifiable information used for certain
                    purposes.
                </p>
                <p>
                    If you no longer wish to receive emails from us and/or
                    promotional communications, you may opt-out of receiving
                    them by following the instructions included in each
                    communication or by emailing or calling us per the
                    information contained on our Website.
                </p>
                <p>
                    Customer Service Contact Information: <br />
                    <a href="mailto:Processing@nodocloans.com">
                        Processing@nodocloans.com
                    </a>
                </p>

                <h3>How does our site handle do not track signals?</h3>
                <p>
                    We honor do not track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track (DNT)
                    browser mechanism is in place.
                </p>
                <h3>Does our site allow third-party behavioral tracking?</h3>
                <p>
                    It`s also important to note that we allow third-party
                    behavioral tracking.
                </p>
                <h3>Protecting Children`s Privacy Online</h3>
                <p>
                    The Site is not directed to individuals under the age of
                    thirteen (13), and we request that these individuals do not
                    provide Personal Information through the Site. We do not
                    knowingly collect information from children under 13 without
                    parental consent. Visit the Federal Trade Commission website
                    for more information about the Children`s Online Privacy
                    Protection Act (COPPA). If you believe that we have received
                    information from a child or other person who is not of a
                    majority age in their relevant jurisdiction, please contact
                    us at our email or physical mailing address listed above and
                    we will take all reasonable efforts to remove the
                    information.
                </p>
                <h2>Analytics</h2>
                <p>
                    NDL may utilize third-party firms to monitor and analyze the
                    use of our Website.
                </p>
                <p>
                    NDL utilizes Google cookie tracking (part of Google
                    Analytics Advertising Features) to collect data about user
                    demographics and interests and for Google Analytics
                    Advertising. Google cookie tracking is also used to improve
                    targeting for advertising campaigns across Google`s Display
                    and Search Remarketing Ad Network and for Enhanced Link
                    Attribution to better track links on our Website. The data
                    that is collected in this way is not personally
                    identifiable. However, visitors might observe certain ads on
                    other websites because we contract with Google and other
                    similar firms to target our advertising based on information
                    they or we have collected. This includes data that was
                    collected via automated methods (including web beacons and
                    cookies). Web beacons are generally small, transparent
                    digital image files that are used to determine if a user has
                    accessed a specific webpage.
                </p>
                <h2>Behavioral remarketing</h2>
                <p>
                    NDL utilizes remarketing services to place ads on
                    third-party websites. These ads may be targeted to you after
                    you visit our Website. We may work with third-party firms
                    that utilize cookies to help create optimized ads based on
                    your previous visits to our Website. Information about how
                    to customize or opt-out of these services can be found
                    below.
                </p>
                <p>
                    Please print and retain a copy of this Privacy Policy for
                    your records.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
