import { message, notification } from 'antd';
import Exception from '../../utils/exceptions/Exception';
import BaseNotificationTemplate, {
    NOTIFICATION_FORMAT_NOTIFICATION,
    NOTIFICATION_FORMAT_TOAST
} from './notificationTemplate/BaseNotificationTemplate';

export default class NotificationController {
    static notify(template: BaseNotificationTemplate) {
        switch (template.format) {
            case NOTIFICATION_FORMAT_NOTIFICATION:
                notification.open({
                    message: template.content,
                    duration: template.duration,
                    type: template.type,
                    icon: template.icon
                });
                break;
            case NOTIFICATION_FORMAT_TOAST:
                message.open({
                    content: template.content,
                    duration: template.duration,
                    type: template.type,
                    icon: template.icon
                });
                break;
            default:
                throw new Exception('undefined notification format', 500);
        }
    }
}
