import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export const CREDENTIALS_TYPE_USERNAME = 1;
export const CREDENTIALS_TYPE_EMAIL = 2;

export default class CredentialsType extends BaseValueObject {
    constructor(value: number) {
        super(value);
        this._validateValue();
    }

    static getAvailableValues() {
        return [CREDENTIALS_TYPE_USERNAME, CREDENTIALS_TYPE_EMAIL];
    }

    static makeUsername() {
        return new CredentialsType(CREDENTIALS_TYPE_USERNAME);
    }

    static makeEmail() {
        return new CredentialsType(CREDENTIALS_TYPE_EMAIL);
    }

    isUsername() {
        return this._value === CREDENTIALS_TYPE_USERNAME;
    }

    isEmail() {
        return this._value === CREDENTIALS_TYPE_EMAIL;
    }

    _validateValue() {
        if (CredentialsType.getAvailableValues().indexOf(this._value) < 0)
            throw new Exception(
                `Invalid credential type value, got ${this._value}`,
                406
            );
    }
}
