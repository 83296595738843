import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './storeIndex';

export interface AuthInterface {
    token: string | null;
    userId: number | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    userType: number | null;
    phone: string | null;
}
export const initialState: AuthInterface = {
    token: localStorage.getItem('token'),
    userId: null,
    email: null,
    firstName: localStorage.getItem('firstName'),
    lastName: null,
    userType: null,
    phone: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload;
        },
        setUserId: (state, action: PayloadAction<number | null>) => {
            state.userId = action.payload;
        },
        setUsername: (state, action: PayloadAction<string | null>) => {
            state.email = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string | null>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string | null>) => {
            state.lastName = action.payload;
        },
        setUserType: (state, action: PayloadAction<number | null>) => {
            state.userType = action.payload;
        },
        setPhone: (state, action: PayloadAction<string | null>) => {
            state.phone = action.payload;
        },
        // setUser: (state, action: PayloadAction<UserEntity | null>) => {
        //     state.user = action.payload;
        // },
        triggerLogout: (state) => {
            state.token = null;
            state.email = null;
            state.userId = null;
            state.firstName = null;
            state.lastName = null;
            state.userType = null;
            state.phone = null;
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('firstName');
        }
    }
});

export const {
    setToken,
    setUserId,
    setUsername,
    setFirstName,
    setLastName,
    setUserType,
    setPhone,
    triggerLogout
} = authSlice.actions;

const authSliceState = (state: RootState) => state.auth;

export default authSliceState;
