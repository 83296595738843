import { Tabs } from 'antd';
import { LoanTermsTabs } from '../../../interfaces/accordion';

const LoanTermDesktop = ({
    list,
    activeTabIndex,
    handleTabChange
}: LoanTermsTabs) => {
    return (
        <div className="loan-terms__desktop-wrapper">
            <Tabs
                items={list}
                activeKey={activeTabIndex}
                onChange={handleTabChange}
            />
        </div>
    );
};

export default LoanTermDesktop;
