import Exception from '../../utils/exceptions/Exception';
import BaseValueObject from './BaseValueObject';

export default class TypeFloat extends BaseValueObject {
    constructor(value: any) {
        super(parseFloat(value));
        // ToDo: Finish implementation
    }

    _validateValue(value: any) {
        if (parseFloat(value) !== value)
            throw new Exception(`Expected float got ${value}`, 406);
    }
}
