import Exception from '../../utils/exceptions/Exception';
import BaseValueObject from './BaseValueObject';

export const ORDER_DIRECTION_DIRECTION_ASCENDING = 'ASC';
export const ORDER_DIRECTION_DIRECTION_DESCENDING = 'DESC';
export default class TypeOrderDirection extends BaseValueObject {
    constructor(value: string) {
        super(value);
    }
    static makeAscending() {
        return new TypeOrderDirection(ORDER_DIRECTION_DIRECTION_ASCENDING);
    }
    static makeDescending() {
        return new TypeOrderDirection(ORDER_DIRECTION_DIRECTION_DESCENDING);
    }
    _validateValue() {
        if (
            this._value !== ORDER_DIRECTION_DIRECTION_ASCENDING &&
            this._value !== ORDER_DIRECTION_DIRECTION_DESCENDING
        )
            throw new Exception('Invalid direction value', 400, this._value);
    }
}
