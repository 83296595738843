import BaseModel from '../BaseModel';
import Collection from '../valueObject/Collection';
import LoanSortingRule from './LoanSortingRule';
import LoanSortingField from './valueObject/LoanSortingField';

export default class LoanSortingRulesEntity extends BaseModel {
    _rules: Collection = new Collection({});
    addRule(rule: LoanSortingRule) {
        this._rules.set(rule.field.value, rule);
        this._executeAfterChangeTriggers();
        return this;
    }

    removeRule(field: LoanSortingField) {
        this._rules.remove(field.value);
        this._executeAfterChangeTriggers();
    }

    clearRules() {
        this._rules.clear();
        this._executeAfterChangeTriggers();
    }

    get rules() {
        return this._rules;
    }

    hasRules() {
        return !this._rules.isEmpty();
    }
}
