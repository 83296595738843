import { useForm } from 'react-hook-form';
import BlockStepsButtons from '../BlockStepsButtons';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import RehabType from '../../../domain/loan/valueObject/RehabType';
import PartRadioButton from '../../parts/PartRadioButton';
import { NewLoanSteps } from '../../../utils/enums';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

interface RehabTypeDetailsProps extends NewLoanApplicationStepsProps {}

const RehabTypeDetails = ({
    nextStep,
    prevStep,
    newLoanEntity
}: RehabTypeDetailsProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                rehabType: yup.number().required('Please choose one option.')
            })
        )
    });

    const onSubmit = (data: any) => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(data)
        );

        nextStep(NewLoanSteps.ESTIMATED_AFTER_REPAIR_VALUE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.LOAN_TYPE);
    };

    useEffect(() => {
        newLoanEntity.hasRehabType() &&
            setValue('rehabType', newLoanEntity.rehabType.id);
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    What type of renovations are you planning?
                </h1>
                <p className="login__subtitle">Select Rehab Type</p>
                <div className="login__box">
                    <PartRadioButton
                        name="rehabType"
                        control={control}
                        errors={errors}
                        options={[
                            {
                                label: RehabType.TYPE_NO_REHAB_NAME,
                                value: RehabType.TYPE_NO_REHAB
                            },
                            {
                                label: RehabType.TYPE_LIGHT_REHAB_NAME,
                                value: RehabType.TYPE_LIGHT_REHAB
                            },
                            {
                                label: RehabType.TYPE_HEAVY_REHAB_NAME,
                                value: RehabType.TYPE_HEAVY_REHAB
                            }
                        ]}
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RehabTypeDetails;
