import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class PitiReserve extends BaseValueObject {
    _value: any;
    _name: string;

    static NONE = 1;
    static ONE_MONTH = 2;
    static THREE_MONTHS = 3;
    static SIX_MONTHS = 4;

    static NONE_NAME = 'None';
    static ONE_MONTH_NAME = '1 month';
    static THREE_MONTHS_NAME = '3 months';
    static SIX_MONTHS_NAME = '6 months';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailablePrePaymentPenalty() {
        return [
            PitiReserve.NONE,
            PitiReserve.ONE_MONTH,
            PitiReserve.THREE_MONTHS,
            PitiReserve.SIX_MONTHS
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case PitiReserve.NONE:
                this._name = PitiReserve.NONE_NAME;
                break;
            case PitiReserve.ONE_MONTH:
                this._name = PitiReserve.ONE_MONTH_NAME;
                break;
            case PitiReserve.THREE_MONTHS:
                this._name = PitiReserve.THREE_MONTHS_NAME;
                break;
            case PitiReserve.SIX_MONTHS:
                this._name = PitiReserve.SIX_MONTHS_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailablePrePaymentPenalty().indexOf(this._value) < 0) {
            throw new Exception('Invalid PITI Reserve', 400);
        }
    }
}
