import { SingleLoanDataType } from '../loan/LoanViewMapper';
import LoanType, { DEAL_TYPE_NO_DOC_DSCR } from '../loan/valueObject/LoanType';
import { LoanTermEntity } from './LoanTermEntity';
import { LoanTermGateway } from './LoanTermGateway';
import { LoanTermViewData } from './LoanTermViewMapper';

export class LoanTermService {
    create(loanTerm: LoanTermEntity) {
        return new LoanTermGateway().create(loanTerm);
    }

    createLoanTermReportPayload(
        loan: SingleLoanDataType | null,
        loanTerm: LoanTermViewData | null
    ) {
        return {
            propertyAddress: {
                key: 'propertyAddress',
                label: 'Property Address',
                value: `${loan?.address?.streetName}, ${loan?.address?.city}, ${loan?.address?.state}, ${loan?.address?.zip}`
            },
            infoSharedWithUs: mapToTablePairData(
                mapObjectToKeyLabel([], {
                    propertyType: loan?.assetType,
                    loanType: new LoanType(loan?.loanType!).name,
                    estimatedAsIs: loan?.estimatedAsIsValue,
                    loanPurpose: loanTerm?.loanTerms.loanPurpose,
                    acquisitionDate: loan?.originalPurchaseDate,
                    totalAcquisitionCost: loan?.originalPurchasePrice,
                    completedCapitalImprovements: loan?.rehabAmountSpentToDate,
                    monthlyExpenses: loan?.monthlyExpenses,
                    borrowerExperience: loan?.experienceLevel,
                    estimatedCreditScore: loan?.creditScore
                }).slice(1)
            ),
            loanTerms: mapToTablePairData(
                mapObjectToKeyLabel([], loanTerm!.loanTerms)
            ),
            pricing: mapToTablePairData(
                mapObjectToKeyLabel([], loanTerm!.pricing ?? {})
            ),
            fae:
                loan?.loanType === DEAL_TYPE_NO_DOC_DSCR
                    ? mapToTablePairData(
                          mapObjectToKeyLabel(
                              [],
                              loanTerm!.feesAndEscrows ?? {}
                          )
                      )
                    : [],
            cc: mapToTablePairData(
                mapObjectToKeyLabel([], loanTerm!.closingConditions ?? {})
            ),
            im: mapToTablePairData(
                mapObjectToKeyLabel([], loanTerm!.interestMechanics ?? {})
            )
        };
    }
}

function mapToTablePairData(arr: any[]) {
    let final = [] as any[];
    arr.forEach((item, index) => {
        if (index % 2 === 0) {
            final.push([item]);
            return;
        }
        final[final.length - 1].push(item);
    });
    return final;
}

const loanTermReportKeyLabelMap: Record<string, unknown> = {
    propertyAddress: 'Property Address',
    estimatedAsIs: 'Estimated As-Is Value',
    propertyCounty: 'Property County',
    propertyType: 'Property Type',
    loanPurpose: 'Loan Purpose',
    loanType: 'Loan Type',
    acquisitionDate: 'Acquisition Date',
    totalAcquisitionCost: 'Total Acquisition Cost',
    completedCapitalImprovements: 'Completed Capital Improvements',
    monthlyExpenses: 'Monthly Expenses',
    monthlyRent: 'Monthly Rent',
    borrowerExperience: 'Borrower Experience',
    estimatedCreditScore: 'Estimated Credit Score',
    citizenshipStatus: 'Citizenship Status',
    ccLtv: 'LTV',
    ccLtarv: 'LTARV',
    ccLtfc: 'LTFC',
    ccMinCreditScore: 'Min Credit Score',
    ccTrackRecord: 'Track Record',
    ccLiquidity: 'Liquidity',
    ccNetWorth: 'Net Worth',
    ccDscr: 'DSCR',
    underwritingFee: 'Underwriting Fee',
    processingFee: 'Processing Fee',
    legalFee: 'Legal Fee',
    originationFeePercentage: 'Origination Fee',
    brokerFeePercentage: 'Broker Fee',
    fePitiReserve: 'PITI Reserve',
    feInsuranceEscrowAmount: 'Insurance Escrow',
    feInsuranceEscrowText: 'Insurance Escrow',
    feTaxEscrowAmount: 'Tax Escrow',
    feTaxEscrowText: 'Tax Escrow',
    interestMechanicTerm: 'Interest Mechanic Term',
    interestAccrualMethod: 'Interest Accrual Method',
    interestReserve: 'Interest Reserve',
    interestMechanicsPrePaymentPenalty:
        'Interest Mechanics Pre-Payment Penalty',
    interestRate: 'Interest Rate',
    exitFee: 'Exit Fee',
    pricingPrePaymentPenalty: 'Pre-Payment Penalty',
    rateType: 'Rate Type',
    amortizationType: 'Amortization Type',
    initialLoanAmount: 'Initial Loan Amount',
    initialLoanAmountPurchasePrice: 'Initial Loan Amount',
    constructionHoldback: 'Construction Holdback',
    constructionHoldbackRenovationBudget: 'Construction Holdback',
    loanToValue: 'Loan To Value',
    totalLoanAmount: 'Total Loan Amount',
    recourseGuarantyStructure: 'Recourse Guaranty Structure'
};

function mapObjectToKeyLabel(initValue: Array<any>, data: Object) {
    for (const key in data) {
        const value = (data as any)[key];
        if (isObject(value)) {
            mapObjectToKeyLabel(initValue, value);
        }
        if (
            loanTermReportKeyLabelMap[key] &&
            ((typeof value === 'string' && value.length > 0) ||
                typeof value === 'number')
        ) {
            initValue.push({
                key: key,
                label: loanTermReportKeyLabelMap[key],
                value: value
            });
        }
    }
    return initValue;
}

function isObject(val: unknown) {
    if (val === null) {
        return false;
    }
    return typeof val === 'function' || typeof val === 'object';
}
