import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermPurpose extends BaseValueObject {
    _value: any;
    _name: string;
    static PURCHASE = 1;
    static RATE_TERM_REFINANCE = 2;
    static CASH_OUT_REFINANCE = 3;

    static PURCHASE_NAME = 'Purchase';
    static RATE_TERM_REFINANCE_NAME = 'Rate Term Refinance';
    static CASH_OUT_REFINANCE_NAME = 'Cash-Out Refinance';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableLoanTermPurpose() {
        return [
            LoanTermPurpose.PURCHASE,
            LoanTermPurpose.RATE_TERM_REFINANCE,
            LoanTermPurpose.CASH_OUT_REFINANCE
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermPurpose.PURCHASE:
                this._name = LoanTermPurpose.PURCHASE_NAME;
                break;
            case LoanTermPurpose.RATE_TERM_REFINANCE:
                this._name = LoanTermPurpose.RATE_TERM_REFINANCE_NAME;
                break;
            case LoanTermPurpose.CASH_OUT_REFINANCE:
                this._name = LoanTermPurpose.CASH_OUT_REFINANCE_NAME;
                break;
            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableLoanTermPurpose().indexOf(this._value) < 0) {
            throw new Exception('Invalid Loan Purpose', 400);
        }
    }
}
