import Exception from '../../utils/exceptions/Exception';
import TypeFloat from './TypeFloat';

export default class TypePositiveFloat extends TypeFloat {
    constructor(value: number) {
        super(value);
        this._validateValue();
    }

    _validateValue() {
        if (this._value < 0)
            throw new Exception(
                `Expected positive float got ${this._value}`,
                406
            );
    }

    addLeadingZeros(numberOfZeros: number): string {
        let value = this._value.toString();
        const zeros = [Math.pow(10, numberOfZeros - 1)].toString();

        while (value.length < zeros.length) {
            value = '0' + value;
        }

        return value;
    }
}
