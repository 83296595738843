import Exception from '../../../utils/exceptions/Exception';
import BaseValueObject from '../../valueObject/BaseValueObject';

export class LoanTermAmortizationType extends BaseValueObject {
    _value: any;
    _name: string;

    static FULL_AMORTIZING = 1;
    static PARTIAL_INTEREST_ONLY = 2;

    static FULL_AMORTIZING_NAME = 'Fully Amortizing';
    static PARTIAL_INTEREST_ONLY_NAME = 'Partial Interest Only';

    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableAmortizationType() {
        return [
            LoanTermAmortizationType.FULL_AMORTIZING,
            LoanTermAmortizationType.PARTIAL_INTEREST_ONLY
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    get name(): string {
        switch (this._value) {
            case LoanTermAmortizationType.FULL_AMORTIZING:
                this._name = LoanTermAmortizationType.FULL_AMORTIZING_NAME;
                break;
            case LoanTermAmortizationType.PARTIAL_INTEREST_ONLY:
                this._name =
                    LoanTermAmortizationType.PARTIAL_INTEREST_ONLY_NAME;
                break;

            default:
                break;
        }
        return this._name;
    }

    _validateValue() {
        if (this.getAvailableAmortizationType().indexOf(this._value) < 0) {
            throw new Exception('Invalid Amortization Type', 400);
        }
    }
}
