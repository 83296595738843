import BaseValueObject from '../valueObject/BaseValueObject';
import Collection from '../valueObject/Collection';
import TypePositiveFloat from '../valueObject/TypePositiveFloat';
import TypePositiveInteger from '../valueObject/TypePositiveInteger';
import { LoanTermAmortizationType } from './valueObject/LoanTermAmortizationType';
import { LoanTermInterestAccrualMethod } from './valueObject/LoanTermInterestAccrualMethod';
import { LoanTermInterestMechanicTerm } from './valueObject/LoanTermInterestMechanicTerm';
import { LoanTermInterestReserve } from './valueObject/LoanTermInterestReserve';
import { LoanTermPurpose } from './valueObject/LoanTermLoanPurpose';
import { PricingPrePaymentPenalty } from './valueObject/PricingPrePaymentPenalty';
import { LoanTermRateType } from './valueObject/LoanTermRateType';
import { LoanTermRecourseGuarantyStructure } from './valueObject/LoanTermRecourseGuarantyStructure';
import { InterestMechanicsPrePaymentPenalty } from './valueObject/InterestMechanicsPrePaymentPenalty';
import { PitiReserve } from './valueObject/PitiReserve';

export class LoanTermEntity extends BaseValueObject {
    private _loanId: TypePositiveInteger;
    // private _loanType: LoanType['getAvailableDealTypes']
    private _loanType: TypePositiveInteger;
    // Loan Terms
    private _loanPurpose: LoanTermPurpose;
    private _initialLoanAmount: TypePositiveInteger;
    private _initialLoanAmountPurchasePrice: TypePositiveInteger;
    private _constructionHoldback: TypePositiveFloat;
    private _constructionHoldbackRenovationBudget: TypePositiveFloat;
    private _totalLoanAmount: TypePositiveInteger;
    private _recourseGuarantyStructure: LoanTermRecourseGuarantyStructure;
    private _loanToValue: TypePositiveFloat;

    // Combo
    private _legalFee: TypePositiveInteger;
    private _underwritingFee: TypePositiveInteger;
    private _processingFee: TypePositiveInteger;
    private _originationFeePercentage: TypePositiveFloat;
    private _brokerFeePercentage: TypePositiveInteger;
    // Pricing
    private _interestRate: TypePositiveFloat;
    private _exitFee: TypePositiveFloat;
    private _rateType: LoanTermRateType;
    private _amortizationType: LoanTermAmortizationType;
    private _pricingPrePaymentPenalty: PricingPrePaymentPenalty;

    // Interest Mechanics
    private _interestMechanicTerm: LoanTermInterestMechanicTerm;
    private _interestAccrualMethod: LoanTermInterestAccrualMethod;
    private _interestMechanicsPrePaymentPenalty: InterestMechanicsPrePaymentPenalty;
    private _interestReserve: LoanTermInterestReserve;

    // Closing Conditions
    private _ccLtv: TypePositiveFloat;
    private _ccLtarv: TypePositiveFloat;
    private _ccLtfc: TypePositiveFloat;
    private _ccMinCreditScore: TypePositiveInteger;
    private _ccTrackRecord: TypePositiveInteger;
    private _ccLiquidity: TypePositiveInteger;
    private _ccNetWorth: TypePositiveInteger;
    private _ccDscr: TypePositiveFloat;

    // Fees and Escrows
    private _fePitiReserve: PitiReserve;
    private _feInsuranceEscrowAmount: TypePositiveInteger;
    private _feTaxEscrowAmount: TypePositiveInteger;

    constructor() {
        super(new Collection({})); // TODO: check if this is correct
    }

    public get loanId() {
        return this._loanId;
    }

    public setLoanId(value: TypePositiveInteger) {
        this._loanId = value;
        return this;
    }

    public hasLoanId() {
        return this._loanId instanceof TypePositiveInteger;
    }

    public get loanType() {
        return this._loanType;
    }

    public setLoanType(value: TypePositiveInteger) {
        this._loanType = value;
        return this;
    }

    public hasLoanType() {
        return this._loanType instanceof TypePositiveInteger;
    }

    public get initialLoanAmount() {
        return this._initialLoanAmount;
    }

    public setInitialLoanAmount(value: TypePositiveInteger) {
        this._initialLoanAmount = value;
        return this;
    }

    public hasInitialLoanAmount() {
        return this._initialLoanAmount instanceof TypePositiveInteger;
    }

    public get initialLoanAmountPurchasePrice() {
        return this._initialLoanAmountPurchasePrice;
    }

    public setInitialLoanAmountPurchasePrice(value: TypePositiveInteger) {
        this._initialLoanAmountPurchasePrice = value;
        return this;
    }

    public hasInitialLoanAmountPurchasePrice() {
        return (
            this._initialLoanAmountPurchasePrice instanceof TypePositiveInteger
        );
    }

    public get constructionHoldback() {
        return this._constructionHoldback;
    }

    public setConstructionHoldback(value: TypePositiveFloat) {
        this._constructionHoldback = value;
        return this;
    }

    public hasConstructionHoldback() {
        return this._constructionHoldback instanceof TypePositiveFloat;
    }

    public get constructionHoldbackRenovationBudget() {
        return this._constructionHoldbackRenovationBudget;
    }

    public setConstructionHoldbackRenovationBudget(value: TypePositiveFloat) {
        this._constructionHoldbackRenovationBudget = value;
        return this;
    }

    public hasConstructionHoldbackRenovationBudget() {
        return (
            this._constructionHoldbackRenovationBudget instanceof
            TypePositiveFloat
        );
    }

    public get totalLoanAmount() {
        return this._totalLoanAmount;
    }

    public setTotalLoanAmount(value: TypePositiveFloat) {
        this._totalLoanAmount = value;
        return this;
    }

    public hasTotalLoanAmount() {
        return this._totalLoanAmount instanceof TypePositiveInteger;
    }

    public get interestRate() {
        return this._interestRate;
    }

    public setInterestRate(value: TypePositiveFloat) {
        this._interestRate = value;
        return this;
    }

    public hasInterestRate() {
        return this._interestRate instanceof TypePositiveFloat;
    }

    public get loanToValue() {
        return this._loanToValue;
    }

    public setLoanToValue(value: TypePositiveFloat) {
        this._loanToValue = value;
        return this;
    }

    public hasLoanToValue() {
        return this._loanToValue instanceof TypePositiveFloat;
    }

    public get exitFee() {
        return this._exitFee;
    }

    public setExitFee(value: TypePositiveFloat) {
        this._exitFee = value;
        return this;
    }

    public hasExitFee() {
        return this._exitFee instanceof TypePositiveFloat;
    }

    public get interestMechanicTerm() {
        return this._interestMechanicTerm;
    }

    public setInterestMechanicTerm(value: LoanTermInterestMechanicTerm) {
        this._interestMechanicTerm = value;
        return this;
    }

    public hasInterestMechanicTerm() {
        return (
            this._interestMechanicTerm instanceof LoanTermInterestMechanicTerm
        );
    }

    public get interestAccrualMethod() {
        return this._interestAccrualMethod;
    }

    public setInterestAccrualMethod(value: LoanTermInterestAccrualMethod) {
        this._interestAccrualMethod = value;
        return this;
    }

    public hasInterestAccrualMethod() {
        return (
            this._interestAccrualMethod instanceof LoanTermInterestAccrualMethod
        );
    }

    public get interestMechanicsPrePaymentPenalty() {
        return this._interestMechanicsPrePaymentPenalty;
    }

    public setInterestMechanicsPrePaymentPenalty(
        value: InterestMechanicsPrePaymentPenalty
    ) {
        this._interestMechanicsPrePaymentPenalty = value;
        return this;
    }

    public hasInterestMechanicsPrePaymentPenalty() {
        return (
            this._interestMechanicsPrePaymentPenalty instanceof
            InterestMechanicsPrePaymentPenalty
        );
    }

    public get interestReserve() {
        return this._interestReserve;
    }

    public setInterestReserve(value: LoanTermInterestReserve) {
        this._interestReserve = value;
        return this;
    }

    public hasInterestReserve() {
        return this._interestReserve instanceof LoanTermInterestReserve;
    }

    public get loanPurpose() {
        return this._loanPurpose;
    }

    public setLoanPurpose(value: LoanTermPurpose) {
        this._loanPurpose = value;
        return this;
    }

    public hasLoanPurpose() {
        return this._loanPurpose instanceof LoanTermPurpose;
    }

    public get pricingPrePaymentPenalty() {
        return this._pricingPrePaymentPenalty;
    }

    public setPricingPrePaymentPenalty(value: PricingPrePaymentPenalty) {
        this._pricingPrePaymentPenalty = value;
        return this;
    }

    public hasPricingPrePaymentPenalty() {
        return (
            this._pricingPrePaymentPenalty instanceof PricingPrePaymentPenalty
        );
    }

    public get rateType() {
        return this._rateType;
    }

    public setRateType(value: LoanTermRateType) {
        this._rateType = value;
        return this;
    }

    public hasRateType() {
        return this._rateType instanceof LoanTermRateType;
    }

    public get amortizationType() {
        return this._amortizationType;
    }

    public setAmortizationType(value: LoanTermAmortizationType) {
        this._amortizationType = value;
        return this;
    }

    public hasAmortizationType() {
        return this._amortizationType instanceof LoanTermAmortizationType;
    }

    public get recourseGuarantyStructure() {
        return this._recourseGuarantyStructure;
    }

    public setRecourseGuarantyStructure(
        value: LoanTermRecourseGuarantyStructure
    ) {
        this._recourseGuarantyStructure = value;
        return this;
    }

    public hasRecourseGuarantyStructure() {
        return (
            this._recourseGuarantyStructure instanceof
            LoanTermRecourseGuarantyStructure
        );
    }

    // Closing Conditions
    public get ccLtv() {
        return this._ccLtv;
    }

    public setCcLtv(value: TypePositiveFloat) {
        this._ccLtv = value;
        return this;
    }

    public hasCcLtv() {
        return this._ccLtv instanceof TypePositiveFloat;
    }

    public get ccLtarv() {
        return this._ccLtarv;
    }

    public setCcLtarv(value: TypePositiveFloat) {
        this._ccLtarv = value;
        return this;
    }

    public hasCcLtarv() {
        return this._ccLtarv instanceof TypePositiveFloat;
    }

    public get ccLtfc() {
        return this._ccLtfc;
    }

    public setCcLtfc(value: TypePositiveFloat) {
        this._ccLtfc = value;
        return this;
    }

    public hasCcLtfc() {
        return this._ccLtfc instanceof TypePositiveFloat;
    }

    public get ccMinCreditScore() {
        return this._ccMinCreditScore;
    }

    public setCcMinCreditScore(value: TypePositiveInteger) {
        this._ccMinCreditScore = value;
        return this;
    }

    public hasCcMinCreditScore() {
        return this._ccMinCreditScore instanceof TypePositiveInteger;
    }

    // Labeled as 'Verified Properties'
    public get ccTrackRecord() {
        return this._ccTrackRecord;
    }

    public setCcTrackRecord(value: TypePositiveInteger) {
        this._ccTrackRecord = value;
        return this;
    }

    public hasCcTrackRecord() {
        return this._ccTrackRecord instanceof TypePositiveInteger;
    }

    public get ccLiquidity() {
        return this._ccLiquidity;
    }

    public setCcLiquidity(value: TypePositiveInteger) {
        this._ccLiquidity = value;
        return this;
    }

    public hasCcLiquidity() {
        return this._ccLiquidity instanceof TypePositiveInteger;
    }

    public get ccNetWorth() {
        return this._ccNetWorth;
    }

    public setCcNetWorth(value: TypePositiveInteger) {
        this._ccNetWorth = value;
        return this;
    }

    public hasCcNetWorth() {
        return this._ccNetWorth instanceof TypePositiveInteger;
    }

    public get ccDscr() {
        return this._ccDscr;
    }

    public setCcDscr(value: TypePositiveFloat) {
        this._ccDscr = value;
        return this;
    }

    public hasCcDscr() {
        return this._ccDscr instanceof TypePositiveFloat;
    }

    // Fees and escrows
    get fePitiReserve(): PitiReserve {
        return this._fePitiReserve;
    }

    public setFePitiReserve(value: PitiReserve) {
        this._fePitiReserve = value;
        return this;
    }

    public hasFePitiReserve() {
        return this._fePitiReserve instanceof PitiReserve;
    }

    get feInsuranceEscrowAmount(): TypePositiveInteger {
        return this._feInsuranceEscrowAmount;
    }

    public setFeInsuranceEscrowAmount(value: TypePositiveInteger) {
        this._feInsuranceEscrowAmount = value;
        return this;
    }

    hasFeInsuranceEscrowAmount() {
        return this._feInsuranceEscrowAmount instanceof TypePositiveInteger;
    }

    get feTaxEscrowAmount(): TypePositiveInteger {
        return this._feTaxEscrowAmount;
    }

    public setFeTaxEscrowAmount(value: TypePositiveInteger) {
        this._feTaxEscrowAmount = value;
        return this;
    }

    hasFeTaxEscrowAmount() {
        return this._feTaxEscrowAmount instanceof TypePositiveInteger;
    }

    public get underwritingFee() {
        return this._underwritingFee;
    }

    public setUnderwritingFee(value: TypePositiveInteger) {
        this._underwritingFee = value;
        return this;
    }

    public hasUnderwritingFee() {
        return this._underwritingFee instanceof TypePositiveInteger;
    }

    public get processingFee() {
        return this._processingFee;
    }

    public setProcessingFee(value: TypePositiveInteger) {
        this._processingFee = value;
        return this;
    }

    public hasProcessingFee() {
        return this._processingFee instanceof TypePositiveInteger;
    }

    public get legalFee() {
        return this._legalFee;
    }

    public setLegalFee(value: TypePositiveInteger) {
        this._legalFee = value;
        return this;
    }

    public hasLegalFee() {
        return this._legalFee instanceof TypePositiveInteger;
    }

    public get originationFeePercentage() {
        return this._originationFeePercentage;
    }

    public setOriginationFeePercentage(value: TypePositiveFloat) {
        this._originationFeePercentage = value;
        return this;
    }

    public hasOriginationFeePercentage() {
        return this._originationFeePercentage instanceof TypePositiveFloat;
    }

    public get brokerFeePercentage() {
        return this._brokerFeePercentage;
    }

    public setBrokerFeePercentage(value: TypePositiveFloat) {
        this._brokerFeePercentage = value;
        return this;
    }

    public hasBrokerFeePercentage() {
        return this._brokerFeePercentage instanceof TypePositiveFloat;
    }
}
