import UserEntity from '../user/UserEntity';
import TypeString from '../valueObject/TypeString';

export default class MeEntity {
    _token: TypeString;
    _user: UserEntity;

    get token(): TypeString {
        return this._token;
    }

    setToken(value: TypeString) {
        this._token = value;
        return this;
    }

    get user(): UserEntity {
        return this._user;
    }

    hasUser() {
        return this._user !== undefined;
    }

    setUser(user: UserEntity) {
        this._user = user;
        return this;
    }
}
