import { Button } from 'antd';
import ArrowRightLong from '../icons/IconArrowRightlong';

interface BlockStepsButtonsProps {
    nextStep: () => void;
    prevStep: () => void;
    isFinalStep?: boolean;
    isSubmitButtonDisabled?: boolean;
}

const BlockStepsButtons = ({
    prevStep,
    nextStep,
    isFinalStep,
    isSubmitButtonDisabled = false
}: BlockStepsButtonsProps) => {
    return (
        <div className="inline-buttons">
            <Button
                className="btn blue login__btn btn--back"
                type="primary"
                onClick={prevStep}
            >
                <span className="btn__icon">
                    <ArrowRightLong />
                </span>
                Back
            </Button>
            <Button
                className="btn blue login__btn"
                type="primary"
                onClick={nextStep}
                disabled={isSubmitButtonDisabled}
            >
                {isFinalStep ? (
                    'Submit'
                ) : (
                    <>
                        Next
                        <span className="btn__icon">
                            <ArrowRightLong />
                        </span>
                    </>
                )}
            </Button>
        </div>
    );
};

export default BlockStepsButtons;
