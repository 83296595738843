import { useForm } from 'react-hook-form';
import BlockStepsButtons from '../BlockStepsButtons';
import LoanApplicationFactory from '../../../domain/newLoanApplication/LoanApplicationFactory';
import Collection from '../../../domain/valueObject/Collection';
import { NewLoanApplicationStepsProps } from '../../../pages/NewLoanApplication';
import { NewLoanSteps } from '../../../utils/enums';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PartInputDate from '../../parts/PartInputDate';
import { useEffect } from 'react';

interface DateOfPurchaseProps extends NewLoanApplicationStepsProps {}

const DateOfPurchase = ({
    nextStep,
    prevStep,
    newLoanEntity
}: DateOfPurchaseProps) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues
    } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        resolver: yupResolver(
            yup.object().shape({
                originalPurchaseDate: yup
                    .date()
                    .required('Please complete this required field.')
                    .test(
                        'year',
                        'You cannot enter the year in the future.',
                        (val: Date | undefined) => {
                            if (val) {
                                const isFutureDate =
                                    val?.getFullYear() >
                                    new Date().getFullYear();

                                return isFutureDate ? false : true;
                            }
                            return true;
                        }
                    )
            })
        )
    });

    const onSubmit = (data: any) => {
        const timestamp = Date.parse(data.originalPurchaseDate);
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection({
                originalPurchaseDate: timestamp
            })
        );

        nextStep(NewLoanSteps.ORIGINAL_PURCHASE_PRICE);
    };

    const handlePrevStep = () => {
        LoanApplicationFactory.makeFromForm(
            newLoanEntity,
            new Collection(getValues())
        );
        prevStep(NewLoanSteps.TRANSACTION_TYPE);
    };

    useEffect(() => {
        if (newLoanEntity.hasOriginalPurchaseDate()) {
            setValue(
                'originalPurchaseDate',
                new Date(newLoanEntity.originalPurchaseDate.getTime())
            );
        }
    }, []);

    return (
        <div className="login">
            <div className="container">
                <h1 className="login__title">
                    When did you purchase your property?
                </h1>
                <p className="login__subtitle">Date of Original Purchase</p>
                <div className="login__box">
                    <PartInputDate
                        name="originalPurchaseDate"
                        control={control}
                        errors={errors}
                        initialValue={
                            newLoanEntity.hasOriginalPurchaseDate()
                                ? new Date(newLoanEntity.originalPurchaseDate)
                                : undefined
                        }
                        isRequired
                    />
                    <BlockStepsButtons
                        prevStep={handlePrevStep}
                        nextStep={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateOfPurchase;
