import { Input, Space } from 'antd';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Exclamation from '../icons/IconExclamation';
import { PatternFormat, NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import Percentage from '../icons/IconPercentage';
import { useState } from 'react';

interface InputFieldProps {
    name: string;
    label: string;
    control: any;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    capitalizeFirstLetter?: boolean;
    validationMessage?: string;
    defaultValue?: string;
    allowClear?: boolean;
    isPhoneNumberFormat?: boolean;
    isNumberFormat?: boolean;
    isDollarAmount?: boolean;
    isPercentageAmount?: boolean;
    onChange?: (name: string, value: any) => void;
}

const PartInputField = ({
    name,
    label,
    control,
    errors,
    isRequired,
    validationMessage,
    allowClear = false,
    isPhoneNumberFormat = false,
    isNumberFormat = false,
    isDollarAmount = false,
    isPercentageAmount = false,
    onChange
}: InputFieldProps) => {
    return (
        <div
            className={classNames('form-element', {
                'form-element--error': name in errors,
                'form-element--input-icon': isPercentageAmount
            })}
        >
            <label>
                {label}
                {isRequired && <span>{' *'}</span>}
            </label>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return isNumberFormat ? (
                        <NumericFormat
                            {...field}
                            thousandSeparator=","
                            className="ant-input"
                            decimalScale={0}
                            onChange={(event) => {
                                field.onChange(event);
                                onChange?.(
                                    field.name,
                                    event.currentTarget.value
                                );
                            }}
                        />
                    ) : isPhoneNumberFormat ? (
                        <PatternFormat
                            {...field}
                            format="(###)-###-####"
                            className="ant-input"
                            onChange={(event) => {
                                field.onChange(event);
                                onChange?.(
                                    field.name,
                                    event.currentTarget.value
                                );
                            }}
                        />
                    ) : isDollarAmount ? (
                        <NumericFormat
                            {...field}
                            prefix={'$'}
                            thousandSeparator=","
                            decimalScale={0}
                            className="ant-input"
                            onChange={(event) => {
                                field.onChange(event);
                                onChange?.(
                                    field.name,
                                    event.currentTarget.value
                                );
                            }}
                        />
                    ) : isPercentageAmount ? (
                        <>
                            <NumericFormat
                                {...field}
                                thousandSeparator=","
                                className="ant-input"
                                decimalScale={0}
                                onChange={(event) => {
                                    field.onChange(event);
                                    onChange?.(
                                        field.name,
                                        event.currentTarget.value
                                    );
                                }}
                            />
                            <span className="form-element--input-icon--percentage">
                                <Percentage />
                            </span>
                        </>
                    ) : (
                        <Input
                            {...field}
                            allowClear={allowClear}
                            onChange={(event) => {
                                field.onChange(event);
                                onChange?.(
                                    field.name,
                                    event.currentTarget.value
                                );
                            }}
                            // suffix={name in errors && <span>X</span>}
                        />
                    );
                }}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span className="form-element__error-msg-icon">
                            <Exclamation />
                        </span>
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartInputField;
